import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ZoomVideo from "@zoom/videosdk";
import {
  SessionStatus,
  SessionUpdateDocument,
  SessionUpdateSubscription,
  SessionUpdateSubscriptionVariables,
  useGetSessionQuery,
  useJoinSessionAsInterpreterMutation,
  useJoinSessionMutation,
  useLeaveSessionMutation,
} from "../../../lib/apollo/graphql/generated";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../common/provider/AuthProvider";
import { toast } from "react-toastify";
import { SCREENS } from "../../../lib/route/utils/router";

function useSessionContainer() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const { profile } = useAuthContext();

  const sessionId = params?.sessionId;

  const [joined, setJoined] = useState(false);

  const { loading, data, subscribeToMore } = useGetSessionQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      sessionId: sessionId!,
    },
  });

  const [joinSession] = useJoinSessionMutation({
    onCompleted: (data) => {
      if (data?.joinSession) {
        setJoined(true);
      }
    },
  });

  const [joinSessionAsInterpreter] = useJoinSessionAsInterpreterMutation({
    onCompleted: (data) => {
      if (data?.joinSessionAsInterpreter) {
        setJoined(true);
      }
    },
  });

  const [leaveSession] = useLeaveSessionMutation();

  useEffect(() => {
    if (data?.getSession?.id) {
      if (!joined) {
        if (profile?.instructor?.id) {
          joinSession({
            variables: {
              sessionId: data?.getSession?.id,
            },
          });
        } else {
          joinSessionAsInterpreter({
            variables: {
              sessionId: data?.getSession?.id,
            },
          });
        }
      }

      return () => {
        if (data?.getSession?.id) {
          leaveSession({
            variables: {
              sessionId: data?.getSession?.id,
            },
          });
        }
      };
    }
  }, [data?.getSession?.id, joined]);

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      SessionUpdateSubscription,
      SessionUpdateSubscriptionVariables
    >({
      document: SessionUpdateDocument,
      variables: {
        sessionId: sessionId!,
      },
      updateQuery: (prev, { subscriptionData }): any => {
        try {
          const newSession = subscriptionData?.data?.sessionUpdate;

          if (!newSession) return prev;

          if (newSession?.status === SessionStatus.Done) {
            toast("상담이 종료되었습니다.");
            navigate(SCREENS.HOME);
          }

          return {
            getSession: newSession,
          };
        } catch (err) {
          console.log(err);
        }
      },
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return {
    models: {
      joined,
      loading,
      data: data?.getSession,
    },
  };
}

export default useSessionContainer;
