import { ReactChildrenProp } from "global";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAuthContext } from "./AuthProvider";
import InvitationModal from "../components/InvitationModal";
import {
  InvitationStatus,
  InvitationUpdatesDocument,
  InvitationUpdatesSubscription,
  InvitationUpdatesSubscriptionVariables,
  useGetMyPendingInvitationsQuery,
  useInvitationUpdatesSubscription,
  useToggleMyOnlineStatusAsInstructorMutation,
} from "../../../lib/apollo/graphql/generated";
import SessionProvider from "./SessionProvider";

function InvitationProvider({ children }: ReactChildrenProp) {
  const { profile } = useAuthContext();
  const { t } = useTranslation();

  const [invitationId, setInvitationId] = useState<string | null>(null);

  const { loading, data, subscribeToMore } = useGetMyPendingInvitationsQuery();

  const [toggleMyOnlineStatusAsInstructor] =
    useToggleMyOnlineStatusAsInstructorMutation();

  useEffect(() => {
    const unsubscribeOnlineInstructors = subscribeToMore<
      InvitationUpdatesSubscription,
      InvitationUpdatesSubscriptionVariables
    >({
      document: InvitationUpdatesDocument,
      updateQuery: (prev, { subscriptionData }): any => {
        try {
          const newInvitation = subscriptionData?.data?.invitationUpdates;

          if (!newInvitation) return prev;

          const prevInvitations = prev?.getMyPendingInvitations || [];

          let editedInvitations = [...prevInvitations];

          editedInvitations = editedInvitations?.filter(
            (invitation) => invitation?.status === InvitationStatus.Pending
          );

          if (newInvitation?.status === InvitationStatus.Pending) {
            setInvitationId(newInvitation?.id);
          }

          if (newInvitation?.status === InvitationStatus.Cancelled) {
            toast(t("invitation.invitationCancelled"));
            setInvitationId(null);
          }

          return {
            getMyPendingInvitations: editedInvitations,
          };
        } catch (err) {
          console.log(err);
        }
      },
    });

    return () => {
      unsubscribeOnlineInstructors();
      toggleMyOnlineStatusAsInstructor({
        variables: {
          status: false,
        },
      });
    };
  }, []);

  const memoizedChildren = useMemo(() => children, []);

  return (
    <>
      <SessionProvider>{memoizedChildren}</SessionProvider>
      {!!invitationId && (
        <InvitationModal
          invitationId={invitationId}
          onRequestClose={() => setInvitationId(null)}
        />
      )}
    </>
  );
}

export default InvitationProvider;
