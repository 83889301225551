import React from "react";
import Flex from "../../common/components/Flex";
import Text from "../../common/components/Text";
import Button from "../../common/components/Button";
import { useTranslation } from "react-i18next";
import theme from "../../../lib/theme";

interface HomeSectionTitleProps {
  title?: string;
  onRightButtonPress?: () => void;
}

function HomeSectionTitle({
  title,
  onRightButtonPress,
}: HomeSectionTitleProps) {
  const { t } = useTranslation();
  return (
    <Flex
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      paddingBottom={theme.spacing[8]}
      borderBottom={`1px solid black`}
      marginBottom={theme.spacing[16]}
    >
      <Text typography="body1Bold" flex={1}>
        {title}
      </Text>
      <Button
        text={t("common.viewAll")}
        variant="outline"
        bgColor="neutralWhite"
        fontColor="primary1"
        onClick={onRightButtonPress}
      />
    </Flex>
  );
}

export default HomeSectionTitle;
