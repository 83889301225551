import React from "react";
import { useTranslation } from "react-i18next";
import theme from "../../../../lib/theme";
import PersonalProfileForm from "../../../common/components/PersoanlProfileForm";
import Section from "../../../common/components/Section";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import MyPageHeader from "../../common/components/MyPageHeader";
import Layout from "../../../common/components/Layout";

function MyUserProfileContainer() {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  return (
    <Layout>
      <Section
        innerStyle={{
          width: "100%",
          maxWidth: 794,
          paddingTop: theme.spacing[isMobile ? 16 : 48],
          paddingBottom: theme.spacing[isMobile ? 16 : 48],
        }}
      >
        <MyPageHeader title={t("myPage.userProfileTitle")} />
        <PersonalProfileForm />
      </Section>
    </Layout>
  );
}

export default MyUserProfileContainer;
