import React, { useState } from "react";
import Layout from "../../../common/components/Layout";
import Section from "../../../common/components/Section";
import theme from "../../../../lib/theme";
import { useTranslation } from "react-i18next";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";
import MyPageHeader from "../../common/components/MyPageHeader";
import {
  useGetMyBankAccountQuery,
  useUpdateMyBankAccountMutation,
} from "../../../../lib/apollo/graphql/generated";
import Loading from "../../../common/components/Loading";
import Input from "../../../common/components/Input";
import Flex from "../../../common/components/Flex";
import Button from "../../../common/components/Button";

function BankAccountContainer() {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const [state, setState] = useState({
    bankName: "",
    accountNumber: "",
  });

  const { loading, data } = useGetMyBankAccountQuery({
    onCompleted: (data) => {
      if (data?.getMyBankAccount) {
        setState({
          bankName: data?.getMyBankAccount?.bankName || "",
          accountNumber: data?.getMyBankAccount?.accountNumber || "",
        });
      }
    },
  });

  const [updateMyBankAccount, { loading: updateLoading }] =
    useUpdateMyBankAccountMutation();

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  if (loading) return <Loading />;

  return (
    <Layout>
      <Section
        innerStyle={{
          width: "100%",
          maxWidth: 794,
          paddingTop: theme.spacing[isMobile ? 16 : 48],
          paddingBottom: theme.spacing[isMobile ? 16 : 48],
        }}
      >
        <MyPageHeader title={t("myPage.bankAccountTitle")} />

        <Flex flexDirection="column" width="100%" gap={theme.spacing[12]}>
          <Input
            label={t("myPage.bankName")}
            value={state?.bankName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onInputChange(e, "bankName")
            }
          />

          <Input
            label={t("myPage.accountNumber")}
            value={state?.accountNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onInputChange(e, "accountNumber")
            }
          />

          <Button
            disabled={updateLoading}
            size="lg"
            round={false}
            text={updateLoading ? t("common.saving") : t("common.save")}
            onClick={() => {
              updateMyBankAccount({
                variables: {
                  bankAccountInput: {
                    bankName: state?.bankName,
                    accountNumber: state?.accountNumber,
                  },
                },
              });
            }}
          />
        </Flex>
      </Section>
    </Layout>
  );
}

export default BankAccountContainer;
