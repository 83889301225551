import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import chevronUpIcon from "../../../assets/icon/chevron_up.png";
import theme from "../../../lib/theme";
import Avatar from "../../common/components/Avatar";
import Flex from "../../common/components/Flex";
import IconButton from "../../common/components/IconButton";
import Text from "../../common/components/Text";
import SessionTimer from "./SessionTimer";
import {
  SessionStatus,
  SessionUpdateDocument,
  SessionUpdateSubscription,
  SessionUpdateSubscriptionVariables,
  useGetSessionQuery,
} from "../../../lib/apollo/graphql/generated";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../common/provider/AuthProvider";

interface SessionTopBarProps {
  requester: any;
  extensions?: Array<{
    __typename?: "SessionExtension";
    id: string;
    createdAt: any;
    session: { __typename?: "Session"; id: string };
    userPass?: { __typename?: "UserPass"; id: string } | null;
    userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
  } | null> | null;
  isHistory?: boolean;
}

function SessionTopBar({
  requester,
  extensions,
  isHistory = false,
}: SessionTopBarProps) {
  const params = useParams();

  const sessionId = params?.sessionId;

  const { t } = useTranslation();

  const { profile } = useAuthContext();

  const [started, setStarted] = useState(false);

  const { data, subscribeToMore } = useGetSessionQuery({
    variables: {
      sessionId: sessionId!,
    },
  });

  useEffect(() => {
    if (data?.getSession?.status === SessionStatus.Started && !started) {
      setStarted(true);
    }
  }, [data?.getSession?.status, started]);

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      SessionUpdateSubscription,
      SessionUpdateSubscriptionVariables
    >({
      document: SessionUpdateDocument,
      variables: {
        sessionId: sessionId!,
      },
      updateQuery: (prev, { subscriptionData }): any => {
        try {
          const newSession = subscriptionData?.data?.sessionUpdate;

          if (!newSession) return prev;

          const prevSession = prev?.getSession;

          if (newSession.status === SessionStatus.Started) {
            setStarted(true);
          } else {
            setStarted(false);
          }

          return {
            getSession: newSession,
          };
        } catch (err) {
          console.log(err);
        }
      },
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const { avatar, name } = requester;

  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      padding={`${theme.spacing[8]}px ${theme.spacing[16]}px`}
      border={`1px solid black`}
      gap={theme.spacing[12]}
    >
      <Flex alignItems="center" gap={theme.spacing[8]}>
        <Avatar
          uri={avatar?.uri}
          initial={name?.slice(0, 1)}
          size={40}
          showOnline={false}
        />
        <Text typography="body1Bold">
          {name}
          {t(isHistory ? "session.historyTopBarTitle" : "session.topBarTitle")}
        </Text>
      </Flex>
      {!isHistory && (
        <Flex alignItems="center" gap={theme.spacing[10]}>
          <SessionTimer
            started={started}
            extensions={extensions}
            startedAt={data?.getSession?.startAt}
          />
        </Flex>
      )}
    </Flex>
  );
}

export default SessionTopBar;
