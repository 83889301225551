import React from "react";
import { useTranslation } from "react-i18next";
import theme from "../../../lib/theme";
import Avatar from "../../common/components/Avatar";
import Flex from "../../common/components/Flex";
import Text from "../../common/components/Text";

interface SessionUserCardProps {
  sessionUser: any;
}

function SessionUserCard({ sessionUser }: SessionUserCardProps) {
  const { t } = useTranslation();

  const { user } = sessionUser;
  const { interpreter, instructor, avatar, name } = user;

  const isInterpreter = !!interpreter;
  const isInstructor = !!instructor;

  const experiences = isInstructor
    ? instructor?.experiences
    : isInterpreter
    ? interpreter?.experiences
    : [];
  const job = isInstructor
    ? instructor?.job
    : isInterpreter
    ? interpreter?.job
    : "";

  const tag = isInstructor
    ? t("common.instructor")
    : isInterpreter
    ? t("common.interpreter")
    : "";

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={theme.spacing[4]}
      backgroundColor="white"
    >
      <Avatar size={80} uri={avatar?.uri} initial={name?.slice(0, 1)} />
      <Text typography="body1Bold">
        {name} {tag}
      </Text>
      {(isInstructor || isInterpreter) && (
        <Text typography="body3" color="#b1b2b2" textAlign="center">
          {`${job ? t("common." + job) : t("common.interpreter")}\n${
            experiences?.[0]?.place || ""
          }`}
        </Text>
      )}
    </Flex>
  );
}

export default SessionUserCard;
