import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  PhoneNumber: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type AllergySymptom = {
  __typename?: "AllergySymptom";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  language?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type AllergyType = {
  __typename?: "AllergyType";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  language?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type BankAccount = {
  __typename?: "BankAccount";
  accountNumber: Scalars["String"]["output"];
  bankName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  user: User;
};

export type BankAccountInput = {
  accountNumber: Scalars["String"]["input"];
  bankName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Billing = {
  __typename?: "Billing";
  active: Scalars["Boolean"]["output"];
  cardName?: Maybe<Scalars["String"]["output"]>;
  cardNumber?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  customerUid: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  pgProvider?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type BillingInput = {
  customerUid: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum BloodType {
  A = "A",
  Ab = "AB",
  B = "B",
  O = "O",
}

export type ChronicDiseaseType = {
  __typename?: "ChronicDiseaseType";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  language?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Education = {
  __typename?: "Education";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  major?: Maybe<Scalars["String"]["output"]>;
  school?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type EducationInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  major?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<Scalars["String"]["input"]>;
};

export type Experience = {
  __typename?: "Experience";
  createdAt: Scalars["DateTime"]["output"];
  duration?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  job?: Maybe<Scalars["String"]["output"]>;
  place?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ExperienceInput = {
  duration?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  job?: InputMaybe<Scalars["String"]["input"]>;
  place?: InputMaybe<Scalars["String"]["input"]>;
};

export type ExtraPlan = {
  __typename?: "ExtraPlan";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  finalPrice: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  initialPrice: Scalars["Float"]["output"];
  tickets: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ExtraPlanInput = {
  active: Scalars["Boolean"]["input"];
  finalPrice: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  initialPrice: Scalars["Float"]["input"];
  tickets: Scalars["Int"]["input"];
};

export type Faq = {
  __typename?: "FAQ";
  active: Scalars["Boolean"]["output"];
  answer: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  order: Scalars["Int"]["output"];
  question: Scalars["String"]["output"];
};

export type FaqInput = {
  active: Scalars["Boolean"]["input"];
  answer: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  order: Scalars["Int"]["input"];
  question: Scalars["String"]["input"];
};

export type File = {
  __typename?: "File";
  encoding: Scalars["String"]["output"];
  filename: Scalars["String"]["output"];
  mimetype: Scalars["String"]["output"];
};

export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
}

export type Instructor = {
  __typename?: "Instructor";
  createdAt: Scalars["DateTime"]["output"];
  educations?: Maybe<Array<Maybe<Education>>>;
  experiences?: Maybe<Array<Maybe<Experience>>>;
  id: Scalars["ID"]["output"];
  introduction?: Maybe<Scalars["String"]["output"]>;
  job?: Maybe<Scalars["String"]["output"]>;
  online: Scalars["Boolean"]["output"];
  qualificationNumber?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type InstructorConnection = {
  __typename?: "InstructorConnection";
  edges: Array<Maybe<Instructor>>;
  pageInfo: PageInfo;
};

export type InstructorInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  introduction?: InputMaybe<Scalars["String"]["input"]>;
  job?: InputMaybe<Scalars["String"]["input"]>;
  qualificationNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type Interpreter = {
  __typename?: "Interpreter";
  createdAt: Scalars["DateTime"]["output"];
  educations?: Maybe<Array<Maybe<Education>>>;
  experiences?: Maybe<Array<Maybe<Experience>>>;
  id: Scalars["ID"]["output"];
  introduction?: Maybe<Scalars["String"]["output"]>;
  online: Scalars["Boolean"]["output"];
  passportNumber?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  visaNumber?: Maybe<Scalars["String"]["output"]>;
};

export type InterpreterConnection = {
  __typename?: "InterpreterConnection";
  edges: Array<Maybe<Interpreter>>;
  pageInfo: PageInfo;
};

export type InterpreterInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  introduction?: InputMaybe<Scalars["String"]["input"]>;
  passportNumber?: InputMaybe<Scalars["String"]["input"]>;
  visaNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type Invitation = {
  __typename?: "Invitation";
  createdAt: Scalars["DateTime"]["output"];
  expiresAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  receiver: Instructor;
  sender: User;
  session?: Maybe<Session>;
  status: InvitationStatus;
  updatedAt: Scalars["DateTime"]["output"];
  userPass?: Maybe<UserPass>;
  userSubscription?: Maybe<UserSubscription>;
};

export enum InvitationStatus {
  Accepted = "ACCEPTED",
  Cancelled = "CANCELLED",
  Declined = "DECLINED",
  Pending = "PENDING",
}

export type LanguageOption = {
  __typename?: "LanguageOption";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  language: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type LanguageOptionInput = {
  active: Scalars["Boolean"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  language: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
};

export type Management = {
  __typename?: "Management";
  address?: Maybe<Scalars["String"]["output"]>;
  ceoName?: Maybe<Scalars["String"]["output"]>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  copyright?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  escroRegistration?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  kakaoAddress?: Maybe<Scalars["String"]["output"]>;
  paymentInformation?: Maybe<Scalars["String"]["output"]>;
  paymentWarning?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  registrationNumber?: Maybe<Scalars["String"]["output"]>;
  sessionDuration: Scalars["Int"]["output"];
  shopAddress?: Maybe<Scalars["String"]["output"]>;
};

export type ManagementInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ceoName?: InputMaybe<Scalars["String"]["input"]>;
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  copyright?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  escroRegistration?: InputMaybe<Scalars["String"]["input"]>;
  kakaoAddress?: InputMaybe<Scalars["String"]["input"]>;
  paymentInformation?: InputMaybe<Scalars["String"]["input"]>;
  paymentWarning?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationNumber?: InputMaybe<Scalars["String"]["input"]>;
  sessionDuration: Scalars["Int"]["input"];
  shopAddress?: InputMaybe<Scalars["String"]["input"]>;
};

export type Media = {
  __typename?: "Media";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  size: Scalars["Int"]["output"];
  /** This is provided only when thumbnailFile is uploaded */
  thumbnailUri?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  uri: Scalars["String"]["output"];
};

export type MediaInput = {
  file: Scalars["Upload"]["input"];
  thumbnailFile?: InputMaybe<Scalars["Upload"]["input"]>;
};

export type Message = {
  __typename?: "Message";
  body?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  media?: Maybe<Media>;
  session: Session;
  user: User;
};

export type MessageConnection = {
  __typename?: "MessageConnection";
  edges?: Maybe<Array<Maybe<Message>>>;
  pageInfo: PageInfo;
};

export type Mutation = {
  __typename?: "Mutation";
  _empty?: Maybe<Scalars["Boolean"]["output"]>;
  addPaymentsAsAdmin: Scalars["Boolean"]["output"];
  addSubscription: UserSubscription;
  cancelMyPendingInvitation: Invitation;
  changeActiveBilling: Billing;
  changeBillingKeyForSubscription: UserSubscription;
  deleteMyAllergy: Scalars["Boolean"]["output"];
  deleteMyChronicDisease: Scalars["Boolean"]["output"];
  deleteMyInstructorEducation: Array<Maybe<Education>>;
  deleteMyInstructorExperience: Array<Maybe<Experience>>;
  deleteMyInsurance: Scalars["Boolean"]["output"];
  deleteMyInterpreterEducation: Array<Maybe<Education>>;
  deleteMyInterpreterExperience: Array<Maybe<Experience>>;
  deleteMyMedicine: Scalars["Boolean"]["output"];
  deleteMySurgery: Scalars["Boolean"]["output"];
  deleteMyVaccine: Scalars["Boolean"]["output"];
  extendSession: SessionExtension;
  joinSession: Session;
  joinSessionAsInterpreter: Session;
  leaveSession: Session;
  purchaseExtraPlanByBilling: Scalars["Boolean"]["output"];
  purchaseUserPassByBilling: Scalars["Boolean"]["output"];
  receiveUserPass?: Maybe<UserPass>;
  requestCancelSubscription: UserSubscription;
  respondToInvitation: Invitation;
  revokeSubscription: UserSubscription;
  sendInvitation: Invitation;
  /**
   * Sends a new message. Publishes updateChat and newMessage.
   * When sending text, attachment is not required.
   * When sending media, text is not required.
   */
  sendMessage: Message;
  sendUserPass: UserPass;
  toggleInterpreterNeeded: Session;
  toggleMyOnlineStatusAsInstructor: Instructor;
  toggleMyOnlineStatusAsInterpreter: Instructor;
  toggleSessionStatusAsInstructor: Session;
  updateAllergySymptomAsAdmin: AllergySymptom;
  updateAllergyTypeAsAdmin: AllergyType;
  updateChronicDiseaseTypeAsAdmin: ChronicDiseaseType;
  updateExtraPlanAsAdmin: ExtraPlan;
  updateFAQAsAdmin: Faq;
  updateLanguageOptionAsAdmin: LanguageOption;
  updateManagementAsAdmin: Management;
  updateMyAllergy: UserAllergy;
  updateMyBankAccount: BankAccount;
  updateMyChronicDisease: UserChronicDisease;
  updateMyInstructor: Instructor;
  updateMyInstructorEducations: Array<Maybe<Education>>;
  updateMyInstructorExperiences: Array<Maybe<Experience>>;
  updateMyInsurance: UserInsurance;
  updateMyInterpreter: Interpreter;
  updateMyInterpreterEducations: Array<Maybe<Education>>;
  updateMyInterpreterExperiences: Array<Maybe<Experience>>;
  updateMyMedicine: UserMedicine;
  updateMyProfile: Profile;
  updateMySurgery: UserSurgery;
  updateMyUserProfile: User;
  updateMyVaccine: UserVaccine;
  updatePassAsAdmin: Pass;
  updatePaymentUnitPriceAsAdmin: PaymentUnitPrice;
  updateSessionReportAsInstructor: Session;
  updateSubscriptionPlanAsAdmin: SubscriptionPlan;
  /** Update user's customerUid which matches billing key for subscription payment. */
  updateUserBilling: Array<Maybe<Billing>>;
  updateVaccineTypeAsAdmin: VaccineType;
};

export type MutationAddPaymentsAsAdminArgs = {
  paymentInput: PaymentInput;
};

export type MutationAddSubscriptionArgs = {
  billingId: Scalars["ID"]["input"];
  finalPrice: Scalars["Float"]["input"];
  subscriptionPlanId: Scalars["ID"]["input"];
};

export type MutationCancelMyPendingInvitationArgs = {
  invitationId: Scalars["ID"]["input"];
};

export type MutationChangeActiveBillingArgs = {
  billingId: Scalars["ID"]["input"];
};

export type MutationChangeBillingKeyForSubscriptionArgs = {
  newBillingId: Scalars["ID"]["input"];
  subscriptionId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationDeleteMyAllergyArgs = {
  userAllergyId: Scalars["ID"]["input"];
};

export type MutationDeleteMyChronicDiseaseArgs = {
  userChronicDiseaseId: Scalars["ID"]["input"];
};

export type MutationDeleteMyInstructorEducationArgs = {
  educationId: Scalars["ID"]["input"];
};

export type MutationDeleteMyInstructorExperienceArgs = {
  experienceId: Scalars["ID"]["input"];
};

export type MutationDeleteMyInsuranceArgs = {
  userInsuranceId: Scalars["ID"]["input"];
};

export type MutationDeleteMyInterpreterEducationArgs = {
  educationId: Scalars["ID"]["input"];
};

export type MutationDeleteMyInterpreterExperienceArgs = {
  experienceId: Scalars["ID"]["input"];
};

export type MutationDeleteMyMedicineArgs = {
  userMedicineId: Scalars["ID"]["input"];
};

export type MutationDeleteMySurgeryArgs = {
  userSurgeryId: Scalars["ID"]["input"];
};

export type MutationDeleteMyVaccineArgs = {
  userVaccineId: Scalars["ID"]["input"];
};

export type MutationExtendSessionArgs = {
  sessionId: Scalars["ID"]["input"];
  subscriptionId?: InputMaybe<Scalars["ID"]["input"]>;
  userPassId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationJoinSessionArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type MutationJoinSessionAsInterpreterArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type MutationLeaveSessionArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type MutationPurchaseExtraPlanByBillingArgs = {
  billingId: Scalars["ID"]["input"];
  extraPlanId: Scalars["ID"]["input"];
  userExtraPlanId: Scalars["ID"]["input"];
};

export type MutationPurchaseUserPassByBillingArgs = {
  billingId: Scalars["ID"]["input"];
  passId: Scalars["ID"]["input"];
  userPassId: Scalars["ID"]["input"];
};

export type MutationReceiveUserPassArgs = {
  code: Scalars["String"]["input"];
};

export type MutationRequestCancelSubscriptionArgs = {
  subscriptionId: Scalars["ID"]["input"];
};

export type MutationRespondToInvitationArgs = {
  invitationId: Scalars["ID"]["input"];
  status: InvitationStatus;
};

export type MutationRevokeSubscriptionArgs = {
  newBillingId: Scalars["ID"]["input"];
  subscriptionId: Scalars["ID"]["input"];
};

export type MutationSendInvitationArgs = {
  receiverId: Scalars["ID"]["input"];
  userPassId?: InputMaybe<Scalars["ID"]["input"]>;
  userSubscriptionId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationSendMessageArgs = {
  attachment?: InputMaybe<MediaInput>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  sessionId: Scalars["ID"]["input"];
};

export type MutationSendUserPassArgs = {
  name: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
  userPassId: Scalars["ID"]["input"];
};

export type MutationToggleInterpreterNeededArgs = {
  needInterpreter: Scalars["Boolean"]["input"];
  sessionId: Scalars["ID"]["input"];
};

export type MutationToggleMyOnlineStatusAsInstructorArgs = {
  status: Scalars["Boolean"]["input"];
};

export type MutationToggleMyOnlineStatusAsInterpreterArgs = {
  status: Scalars["Boolean"]["input"];
};

export type MutationToggleSessionStatusAsInstructorArgs = {
  sessionId: Scalars["ID"]["input"];
  status: SessionStatus;
};

export type MutationUpdateAllergySymptomAsAdminArgs = {
  typeInput?: InputMaybe<PhrTypeInput>;
};

export type MutationUpdateAllergyTypeAsAdminArgs = {
  typeInput?: InputMaybe<PhrTypeInput>;
};

export type MutationUpdateChronicDiseaseTypeAsAdminArgs = {
  typeInput?: InputMaybe<PhrTypeInput>;
};

export type MutationUpdateExtraPlanAsAdminArgs = {
  extraPlanInput: ExtraPlanInput;
};

export type MutationUpdateFaqAsAdminArgs = {
  faqInput: FaqInput;
};

export type MutationUpdateLanguageOptionAsAdminArgs = {
  languageOptionInput: LanguageOptionInput;
};

export type MutationUpdateManagementAsAdminArgs = {
  managementInput: ManagementInput;
};

export type MutationUpdateMyAllergyArgs = {
  userAllergyInput: UserAllergyInput;
};

export type MutationUpdateMyBankAccountArgs = {
  bankAccountInput: BankAccountInput;
};

export type MutationUpdateMyChronicDiseaseArgs = {
  userChronicDiseaseInput: UserChronicDiseaseInput;
};

export type MutationUpdateMyInstructorArgs = {
  instructorInput: InstructorInput;
};

export type MutationUpdateMyInstructorEducationsArgs = {
  educationInputs: Array<InputMaybe<EducationInput>>;
};

export type MutationUpdateMyInstructorExperiencesArgs = {
  experienceInputs: Array<InputMaybe<ExperienceInput>>;
};

export type MutationUpdateMyInsuranceArgs = {
  userInsuranceInput: UserInsuranceInput;
};

export type MutationUpdateMyInterpreterArgs = {
  interpreterInput: InterpreterInput;
};

export type MutationUpdateMyInterpreterEducationsArgs = {
  educationInputs: Array<InputMaybe<EducationInput>>;
};

export type MutationUpdateMyInterpreterExperiencesArgs = {
  experienceInputs: Array<InputMaybe<ExperienceInput>>;
};

export type MutationUpdateMyMedicineArgs = {
  userMedicineInput: UserMedicineInput;
};

export type MutationUpdateMyProfileArgs = {
  profileInput: ProfileInput;
};

export type MutationUpdateMySurgeryArgs = {
  userSurgeryInput: UserSurgeryInput;
};

export type MutationUpdateMyUserProfileArgs = {
  userInput: UserInput;
};

export type MutationUpdateMyVaccineArgs = {
  userVaccineInput: UserVaccineInput;
};

export type MutationUpdatePassAsAdminArgs = {
  passInput: PassInput;
};

export type MutationUpdatePaymentUnitPriceAsAdminArgs = {
  paymentUnitPriceInput: PaymentUnitPriceInput;
};

export type MutationUpdateSessionReportAsInstructorArgs = {
  report: Scalars["String"]["input"];
  sessionId: Scalars["ID"]["input"];
};

export type MutationUpdateSubscriptionPlanAsAdminArgs = {
  subscriptionPlanInput: SubscriptionPlanInput;
};

export type MutationUpdateUserBillingArgs = {
  billingInput: BillingInput;
};

export type MutationUpdateVaccineTypeAsAdminArgs = {
  typeInput?: InputMaybe<PhrTypeInput>;
};

export type Nationality = {
  __typename?: "Nationality";
  emoji?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  unicode: Scalars["String"]["output"];
};

export type PhrTypeInput = {
  active: Scalars["Boolean"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]["output"]>;
  hasNextPage: Scalars["Boolean"]["output"];
};

export type PaginationInput = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first: Scalars["Int"]["input"];
};

export type Pass = {
  __typename?: "Pass";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  finalPrice: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  initialPrice: Scalars["Float"]["output"];
  language: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  summary?: Maybe<Scalars["String"]["output"]>;
  tickets: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type PassInput = {
  active: Scalars["Boolean"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  finalPrice: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  initialPrice: Scalars["Float"]["input"];
  language: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  summary?: InputMaybe<Scalars["String"]["input"]>;
  tickets: Scalars["Int"]["input"];
};

export type Payment = {
  __typename?: "Payment";
  amount: Scalars["Float"]["output"];
  bankAccount: BankAccount;
  createdAt: Scalars["DateTime"]["output"];
  currency: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  session?: Maybe<Session>;
  user: User;
};

export type PaymentConnection = {
  __typename?: "PaymentConnection";
  edges: Array<Maybe<Payment>>;
  pageInfo: PageInfo;
};

export type PaymentInput = {
  amount: Scalars["Float"]["input"];
  bankAccountId: Scalars["ID"]["input"];
  currency: Scalars["String"]["input"];
  sessionIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  type: PaymentUnitPriceType;
  userId: Scalars["ID"]["input"];
};

export type PaymentUnitPrice = {
  __typename?: "PaymentUnitPrice";
  currency: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  language: Scalars["String"]["output"];
  type: PaymentUnitPriceType;
  unitPrice: Scalars["Float"]["output"];
};

export type PaymentUnitPriceInput = {
  currency: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  language: Scalars["String"]["input"];
  type: PaymentUnitPriceType;
  unitPrice: Scalars["Float"]["input"];
};

export enum PaymentUnitPriceType {
  Instructor = "INSTRUCTOR",
  Interpreter = "INTERPRETER",
}

export type Profile = {
  __typename?: "Profile";
  bloodType?: Maybe<BloodType>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  emergencyCallerAddress?: Maybe<Scalars["String"]["output"]>;
  emergencyCallerBloodType?: Maybe<BloodType>;
  emergencyCallerName?: Maybe<Scalars["String"]["output"]>;
  emergencyCallerNationality?: Maybe<Nationality>;
  emergencyCallerPhone?: Maybe<Scalars["String"]["output"]>;
  emergencyCallerPhoneCode?: Maybe<Scalars["String"]["output"]>;
  emergencyCallerRelation?: Maybe<Scalars["String"]["output"]>;
  height?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  occupation?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  weight?: Maybe<Scalars["Float"]["output"]>;
};

export type ProfileInput = {
  bloodType?: InputMaybe<BloodType>;
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  emergencyCallerAddress?: InputMaybe<Scalars["String"]["input"]>;
  emergencyCallerBloodType?: InputMaybe<BloodType>;
  emergencyCallerName?: InputMaybe<Scalars["String"]["input"]>;
  emergencyCallerNationalityId?: InputMaybe<Scalars["ID"]["input"]>;
  emergencyCallerPhone?: InputMaybe<Scalars["String"]["input"]>;
  emergencyCallerPhoneCode?: InputMaybe<Scalars["String"]["input"]>;
  emergencyCallerRelation?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  occupation?: InputMaybe<Scalars["String"]["input"]>;
  weight?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Query = {
  __typename?: "Query";
  _empty?: Maybe<Scalars["Boolean"]["output"]>;
  getActiveSessions: SessionConnection;
  getAllExtraPlansAsAdmin: Array<Maybe<ExtraPlan>>;
  getAllInstructors: Array<Maybe<Instructor>>;
  getAllInstructorsAsAdmin: InstructorConnection;
  getAllInterpretersAsAdmin: InterpreterConnection;
  getAllPassesAsAdmin: Array<Maybe<Pass>>;
  getAllSubscriptionPlansAsAdmin: Array<Maybe<SubscriptionPlan>>;
  getAllergySymptoms?: Maybe<Array<Maybe<AllergySymptom>>>;
  getAllergySymptomsAsAdmin?: Maybe<Array<Maybe<AllergySymptom>>>;
  getAllergyTypes?: Maybe<Array<Maybe<AllergyType>>>;
  getAllergyTypesAsAdmin?: Maybe<Array<Maybe<AllergyType>>>;
  getChronicDiseaseTypes?: Maybe<Array<Maybe<ChronicDiseaseType>>>;
  getChronicDiseaseTypesAsAdmin?: Maybe<Array<Maybe<ChronicDiseaseType>>>;
  getExtraPlans: Array<Maybe<ExtraPlan>>;
  getFAQs?: Maybe<Array<Maybe<Faq>>>;
  getInstructor: Instructor;
  getInterpreter: Interpreter;
  getInterpreterUnpaidSessionAsAdmin?: Maybe<Array<Maybe<Session>>>;
  getInvitation?: Maybe<Invitation>;
  getLanguageOptions?: Maybe<Array<Maybe<LanguageOption>>>;
  getManagement: Management;
  getMyActiveSession?: Maybe<Session>;
  getMyAllergies: Array<Maybe<UserAllergy>>;
  getMyBankAccount?: Maybe<BankAccount>;
  getMyBillings?: Maybe<Array<Maybe<Billing>>>;
  getMyChronicDiseases: Array<Maybe<UserChronicDisease>>;
  getMyInstructor: Instructor;
  getMyInstructorEducations: Array<Maybe<Education>>;
  getMyInstructorExperiences: Array<Maybe<Experience>>;
  getMyInsurances: Array<Maybe<UserInsurance>>;
  getMyInterpreter: Interpreter;
  getMyInterpreterEducations: Array<Maybe<Education>>;
  getMyInterpreterExperiences: Array<Maybe<Experience>>;
  getMyMedicines: Array<Maybe<UserMedicine>>;
  getMyOwnUserPasses: UserPassConnection;
  getMyPaymentHistory: PaymentConnection;
  getMyPendingInvitations: Array<Maybe<Invitation>>;
  getMyProfile: Profile;
  getMyPurchasedUserPasses: UserPassConnection;
  getMySessions: SessionConnection;
  getMySubscriptions: Array<Maybe<UserSubscription>>;
  getMySurgerys: Array<Maybe<UserSurgery>>;
  getMyUserProfile: User;
  getMyVaccines: Array<Maybe<UserVaccine>>;
  getNationalities: Array<Maybe<Nationality>>;
  getOfflineInstructors: Array<Maybe<Instructor>>;
  getOnlineInstructors: Array<Maybe<Instructor>>;
  getOnlineInterpreters: Array<Maybe<Interpreter>>;
  getPass: Pass;
  getPassesByLanguage: Array<Maybe<Pass>>;
  getPaymentUnitPricesAsAdmin?: Maybe<Array<Maybe<PaymentUnitPrice>>>;
  getSession: Session;
  /**
   * Get a list of messages in a given chat. Publishes updateChat to notify users
   * that lastSeenAt timestamp is updated.
   *
   * All notifications attached to this chat will be marked as checked.
   *
   * Supports pagination.
   */
  getSessionMessages: MessageConnection;
  getSubscriptionPlan: SubscriptionPlan;
  getSubscriptionPlansByLanguage: Array<Maybe<SubscriptionPlan>>;
  getUnpaidSessionsAsAdmin?: Maybe<Array<Maybe<Session>>>;
  getUser: User;
  getUserAllergies: Array<Maybe<UserAllergy>>;
  getUserBankAccountAsAdmin?: Maybe<BankAccount>;
  getUserChronicDiseases: Array<Maybe<UserChronicDisease>>;
  getUserExtraPlan: UserExtraPlan;
  getUserExtraPlansAsAdmin: UserExtraPlanConnection;
  getUserInsurances: Array<Maybe<UserInsurance>>;
  getUserMedicines: Array<Maybe<UserMedicine>>;
  getUserPass: UserPass;
  getUserPassesAsAdmin: UserPassConnection;
  getUserSubscriptionsAsAdmin: UserSubscriptionConnection;
  getUserSurgerys: Array<Maybe<UserSurgery>>;
  getUserVaccines: Array<Maybe<UserVaccine>>;
  getUsersAsAdmin: UserConnection;
  getVaccineTypes?: Maybe<Array<Maybe<VaccineType>>>;
  getVaccineTypesAsAdmin?: Maybe<Array<Maybe<ChronicDiseaseType>>>;
  searchNationality: Array<Maybe<Nationality>>;
};

export type QueryGetActiveSessionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetAllInstructorsAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetAllInterpretersAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetAllergySymptomsArgs = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetAllergyTypesArgs = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetChronicDiseaseTypesArgs = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetFaQsArgs = {
  activeOnly: Scalars["Boolean"]["input"];
};

export type QueryGetInstructorArgs = {
  instructorId: Scalars["ID"]["input"];
};

export type QueryGetInterpreterArgs = {
  interpreterId: Scalars["ID"]["input"];
};

export type QueryGetInvitationArgs = {
  invitationId: Scalars["ID"]["input"];
};

export type QueryGetLanguageOptionsArgs = {
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetMyOwnUserPassesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<Array<InputMaybe<UserPassStatus>>>;
};

export type QueryGetMyPaymentHistoryArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  type: PaymentUnitPriceType;
};

export type QueryGetMyPurchasedUserPassesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetMySessionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetPassArgs = {
  passId: Scalars["ID"]["input"];
};

export type QueryGetPassesByLanguageArgs = {
  language: Scalars["String"]["input"];
};

export type QueryGetSessionArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type QueryGetSessionMessagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sessionId: Scalars["ID"]["input"];
};

export type QueryGetSubscriptionPlanArgs = {
  planId: Scalars["ID"]["input"];
};

export type QueryGetSubscriptionPlansByLanguageArgs = {
  language: Scalars["String"]["input"];
};

export type QueryGetUserArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserAllergiesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserBankAccountAsAdminArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserChronicDiseasesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserExtraPlanArgs = {
  userExtraPlanId: Scalars["ID"]["input"];
};

export type QueryGetUserExtraPlansAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetUserInsurancesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserMedicinesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserPassArgs = {
  userPassId: Scalars["ID"]["input"];
};

export type QueryGetUserPassesAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetUserSubscriptionsAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetUserSurgerysArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserVaccinesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUsersAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetVaccineTypesArgs = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchNationalityArgs = {
  query: Scalars["String"]["input"];
};

export type Session = {
  __typename?: "Session";
  createdAt: Scalars["DateTime"]["output"];
  endAt?: Maybe<Scalars["DateTime"]["output"]>;
  extensions?: Maybe<Array<Maybe<SessionExtension>>>;
  id: Scalars["ID"]["output"];
  instructor?: Maybe<Instructor>;
  interpreter?: Maybe<Interpreter>;
  /** If access token returns null, the access to this room cannot be granted */
  jwt?: Maybe<Scalars["String"]["output"]>;
  needInterpreter?: Maybe<Scalars["Boolean"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
  report?: Maybe<Scalars["String"]["output"]>;
  requester: User;
  startAt?: Maybe<Scalars["DateTime"]["output"]>;
  status?: Maybe<SessionStatus>;
  updatedAt: Scalars["DateTime"]["output"];
  userPass?: Maybe<UserPass>;
  userSubscription?: Maybe<UserSubscription>;
  users?: Maybe<Array<Maybe<SessionUser>>>;
};

export type SessionConnection = {
  __typename?: "SessionConnection";
  edges?: Maybe<Array<Maybe<Session>>>;
  pageInfo: PageInfo;
};

export type SessionExtension = {
  __typename?: "SessionExtension";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  session: Session;
  userPass?: Maybe<UserPass>;
  userSubscription?: Maybe<UserSubscription>;
};

export enum SessionStatus {
  Done = "DONE",
  Pending = "PENDING",
  Started = "STARTED",
}

export type SessionUser = {
  __typename?: "SessionUser";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  joinAt: Scalars["DateTime"]["output"];
  leaveAt?: Maybe<Scalars["DateTime"]["output"]>;
  session: Session;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type Subscription = {
  __typename?: "Subscription";
  _empty?: Maybe<Scalars["String"]["output"]>;
  invitationUpdates: Invitation;
  /** Published whenever a new message is sent in a chat. */
  newMessage?: Maybe<Message>;
  sessionUpdate: Session;
  updatedOnlineInstructor: Instructor;
  updatedOnlineInterpreter: Interpreter;
};

export type SubscriptionNewMessageArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type SubscriptionSessionUpdateArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type SubscriptionPlan = {
  __typename?: "SubscriptionPlan";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  duration: Scalars["Int"]["output"];
  finalPrice: Scalars["Float"]["output"];
  freeTrial: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  initialPrice: Scalars["Float"]["output"];
  language: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  summary?: Maybe<Scalars["String"]["output"]>;
  tickets: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type SubscriptionPlanInput = {
  active: Scalars["Boolean"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  duration: Scalars["Int"]["input"];
  finalPrice: Scalars["Float"]["input"];
  freeTrial: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  initialPrice: Scalars["Float"]["input"];
  language: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  summary?: InputMaybe<Scalars["String"]["input"]>;
  tickets: Scalars["Int"]["input"];
};

export enum SubscriptionStatus {
  Active = "ACTIVE",
  Cancelled = "CANCELLED",
  CancelRequested = "CANCEL_REQUESTED",
  Expired = "EXPIRED",
  Paused = "PAUSED",
}

export type User = {
  __typename?: "User";
  avatar?: Maybe<Media>;
  billings?: Maybe<Array<Maybe<Billing>>>;
  createdAt: Scalars["DateTime"]["output"];
  dateOfBirth?: Maybe<Scalars["Date"]["output"]>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Gender>;
  id: Scalars["ID"]["output"];
  instructor?: Maybe<Instructor>;
  interpreter?: Maybe<Interpreter>;
  isSubscriber?: Maybe<Scalars["Boolean"]["output"]>;
  languageOptions?: Maybe<Array<Maybe<LanguageOption>>>;
  name?: Maybe<Scalars["String"]["output"]>;
  nationality?: Maybe<Nationality>;
  phone?: Maybe<Scalars["String"]["output"]>;
  phoneCode?: Maybe<Scalars["String"]["output"]>;
  profile?: Maybe<Profile>;
  provider?: Maybe<Scalars["String"]["output"]>;
  resignReason?: Maybe<Scalars["String"]["output"]>;
  type: UserType;
  updatedAt: Scalars["DateTime"]["output"];
  usuableTickets: Scalars["Int"]["output"];
};

export type UserAllergy = {
  __typename?: "UserAllergy";
  createdAt: Scalars["DateTime"]["output"];
  firstAid?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  summary?: Maybe<Scalars["String"]["output"]>;
  symptoms?: Maybe<Array<Maybe<AllergySymptom>>>;
  type: AllergyType;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserAllergyInput = {
  firstAid?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  symptomIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  typeId: Scalars["ID"]["input"];
};

export type UserChronicDisease = {
  __typename?: "UserChronicDisease";
  createdAt: Scalars["DateTime"]["output"];
  etc?: Maybe<Scalars["String"]["output"]>;
  firstAid?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  medicine?: Maybe<Scalars["String"]["output"]>;
  type: ChronicDiseaseType;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserChronicDiseaseInput = {
  etc?: InputMaybe<Scalars["String"]["input"]>;
  firstAid?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  medicine?: InputMaybe<Scalars["String"]["input"]>;
  typeId: Scalars["ID"]["input"];
};

export type UserConnection = {
  __typename?: "UserConnection";
  edges?: Maybe<Array<Maybe<User>>>;
  pageInfo: PageInfo;
};

export type UserExtraPlan = {
  __typename?: "UserExtraPlan";
  createdAt: Scalars["DateTime"]["output"];
  extraPlan: ExtraPlan;
  id: Scalars["ID"]["output"];
  purchaser: User;
  status: UserExtraPlanStatus;
  updatedAt: Scalars["DateTime"]["output"];
};

export type UserExtraPlanConnection = {
  __typename?: "UserExtraPlanConnection";
  edges?: Maybe<Array<Maybe<UserExtraPlan>>>;
  pageInfo: PageInfo;
};

export enum UserExtraPlanStatus {
  Cancelled = "CANCELLED",
  Success = "SUCCESS",
}

export type UserInput = {
  avatarInput?: InputMaybe<MediaInput>;
  dateOfBirth?: InputMaybe<Scalars["Date"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  languageOptionIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nationalityId?: InputMaybe<Scalars["ID"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  phoneCode?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
};

export type UserInsurance = {
  __typename?: "UserInsurance";
  companyNumber?: Maybe<Scalars["String"]["output"]>;
  coverage?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  groupId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  memberId?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserInsuranceInput = {
  companyNumber?: InputMaybe<Scalars["String"]["input"]>;
  coverage?: InputMaybe<Scalars["String"]["input"]>;
  groupId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  memberId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserMedicine = {
  __typename?: "UserMedicine";
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  dosage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  medicine?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserMedicineInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  dosage?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  medicine?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserPass = {
  __typename?: "UserPass";
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  pass: Pass;
  purchaser: User;
  sent: Scalars["Boolean"]["output"];
  status: UserPassStatus;
  updatedAt: Scalars["DateTime"]["output"];
  user?: Maybe<User>;
};

export type UserPassConnection = {
  __typename?: "UserPassConnection";
  edges?: Maybe<Array<Maybe<UserPass>>>;
  pageInfo: PageInfo;
};

export enum UserPassStatus {
  Cancelled = "CANCELLED",
  Purchased = "PURCHASED",
  Received = "RECEIVED",
  Used = "USED",
}

export type UserSubscription = {
  __typename?: "UserSubscription";
  billing?: Maybe<Billing>;
  createdAt: Scalars["DateTime"]["output"];
  from: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  nextPayAt?: Maybe<Scalars["DateTime"]["output"]>;
  status: SubscriptionStatus;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  to?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserSubscriptionConnection = {
  __typename?: "UserSubscriptionConnection";
  edges?: Maybe<Array<Maybe<UserSubscription>>>;
  pageInfo: PageInfo;
};

export type UserSurgery = {
  __typename?: "UserSurgery";
  createdAt: Scalars["DateTime"]["output"];
  department?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  media?: Maybe<Media>;
  part?: Maybe<Scalars["String"]["output"]>;
  surgery?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserSurgeryInput = {
  department?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  mediaInput?: InputMaybe<MediaInput>;
  part?: InputMaybe<Scalars["String"]["input"]>;
  surgery?: InputMaybe<Scalars["String"]["input"]>;
};

export enum UserType {
  Instructor = "INSTRUCTOR",
  Interpreter = "INTERPRETER",
  Personal = "PERSONAL",
}

export type UserVaccine = {
  __typename?: "UserVaccine";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  lastVaccinatedAt?: Maybe<Scalars["String"]["output"]>;
  level?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  vaccineType: VaccineType;
};

export type UserVaccineInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  lastVaccinatedAt?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  vaccineTypeId: Scalars["ID"]["input"];
};

export type VaccineType = {
  __typename?: "VaccineType";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  language?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type GetMyBankAccountQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyBankAccountQuery = {
  __typename?: "Query";
  getMyBankAccount?: {
    __typename?: "BankAccount";
    id: string;
    bankName: string;
    accountNumber: string;
    user: { __typename?: "User"; id: string };
  } | null;
};

export type UpdateMyBankAccountMutationVariables = Exact<{
  bankAccountInput: BankAccountInput;
}>;

export type UpdateMyBankAccountMutation = {
  __typename?: "Mutation";
  updateMyBankAccount: {
    __typename?: "BankAccount";
    id: string;
    bankName: string;
    accountNumber: string;
    user: { __typename?: "User"; id: string };
  };
};

export type InvitationUpdatesSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type InvitationUpdatesSubscription = {
  __typename?: "Subscription";
  invitationUpdates: {
    __typename?: "Invitation";
    id: string;
    status: InvitationStatus;
    createdAt: any;
    updatedAt: any;
    expiresAt: any;
    sender: { __typename?: "User"; id: string; name?: string | null };
    receiver: { __typename?: "Instructor"; id: string };
    session?: {
      __typename?: "Session";
      id: string;
      jwt?: string | null;
      startAt?: any | null;
      endAt?: any | null;
      report?: string | null;
      status?: SessionStatus | null;
    } | null;
  };
};

export type GetMyPendingInvitationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMyPendingInvitationsQuery = {
  __typename?: "Query";
  getMyPendingInvitations: Array<{
    __typename?: "Invitation";
    id: string;
    status: InvitationStatus;
    createdAt: any;
    updatedAt: any;
    expiresAt: any;
    sender: { __typename?: "User"; id: string; name?: string | null };
    receiver: { __typename?: "Instructor"; id: string };
    session?: {
      __typename?: "Session";
      id: string;
      jwt?: string | null;
      startAt?: any | null;
      endAt?: any | null;
      report?: string | null;
      status?: SessionStatus | null;
    } | null;
  } | null>;
};

export type GetInvitationQueryVariables = Exact<{
  invitationId: Scalars["ID"]["input"];
}>;

export type GetInvitationQuery = {
  __typename?: "Query";
  getInvitation?: {
    __typename?: "Invitation";
    id: string;
    status: InvitationStatus;
    createdAt: any;
    updatedAt: any;
    expiresAt: any;
    sender: { __typename?: "User"; id: string; name?: string | null };
    receiver: { __typename?: "Instructor"; id: string };
    session?: {
      __typename?: "Session";
      id: string;
      jwt?: string | null;
      startAt?: any | null;
      endAt?: any | null;
      report?: string | null;
      status?: SessionStatus | null;
    } | null;
  } | null;
};

export type RespondToInvitationMutationVariables = Exact<{
  invitationId: Scalars["ID"]["input"];
  status: InvitationStatus;
}>;

export type RespondToInvitationMutation = {
  __typename?: "Mutation";
  respondToInvitation: {
    __typename?: "Invitation";
    id: string;
    status: InvitationStatus;
    createdAt: any;
    updatedAt: any;
    expiresAt: any;
    sender: { __typename?: "User"; id: string; name?: string | null };
    receiver: { __typename?: "Instructor"; id: string };
    session?: {
      __typename?: "Session";
      id: string;
      jwt?: string | null;
      startAt?: any | null;
      endAt?: any | null;
      report?: string | null;
      status?: SessionStatus | null;
    } | null;
  };
};

export type GetLanguageOptionsQueryVariables = Exact<{
  language?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetLanguageOptionsQuery = {
  __typename?: "Query";
  getLanguageOptions?: Array<{
    __typename?: "LanguageOption";
    id: string;
    language: string;
    name: string;
    active: boolean;
    order: number;
    createdAt: any;
    updatedAt: any;
  } | null> | null;
};

export type GetManagementQueryVariables = Exact<{ [key: string]: never }>;

export type GetManagementQuery = {
  __typename?: "Query";
  getManagement: {
    __typename?: "Management";
    id: string;
    companyName?: string | null;
    address?: string | null;
    copyright?: string | null;
    email?: string | null;
    ceoName?: string | null;
    registrationNumber?: string | null;
    escroRegistration?: string | null;
    phone?: string | null;
    kakaoAddress?: string | null;
    sessionDuration: number;
    shopAddress?: string | null;
    paymentWarning?: string | null;
    paymentInformation?: string | null;
  };
};

export type GetNationalitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetNationalitiesQuery = {
  __typename?: "Query";
  getNationalities: Array<{
    __typename?: "Nationality";
    id: string;
    name: string;
    emoji?: string | null;
    unicode: string;
    imageUrl?: string | null;
  } | null>;
};

export type GetMyPaymentHistoryQueryVariables = Exact<{
  type: PaymentUnitPriceType;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetMyPaymentHistoryQuery = {
  __typename?: "Query";
  getMyPaymentHistory: {
    __typename?: "PaymentConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges: Array<{
      __typename?: "Payment";
      id: string;
      amount: number;
      currency: string;
      createdAt: any;
      session?: { __typename?: "Session"; id: string } | null;
      user: { __typename?: "User"; id: string };
      bankAccount: {
        __typename?: "BankAccount";
        id: string;
        bankName: string;
        accountNumber: string;
      };
    } | null>;
  };
};

export type GetMySessionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetMySessionsQuery = {
  __typename?: "Query";
  getMySessions: {
    __typename?: "SessionConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges?: Array<{
      __typename?: "Session";
      id: string;
      jwt?: string | null;
      password?: string | null;
      startAt?: any | null;
      endAt?: any | null;
      report?: string | null;
      status?: SessionStatus | null;
      needInterpreter?: boolean | null;
      createdAt: any;
      updatedAt: any;
      requester: {
        __typename?: "User";
        id: string;
        name?: string | null;
        usuableTickets: number;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
      instructor?: {
        __typename?: "Instructor";
        id: string;
        user: {
          __typename?: "User";
          id: string;
          name?: string | null;
          avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        };
      } | null;
      userPass?: {
        __typename?: "UserPass";
        id: string;
        pass: { __typename?: "Pass"; id: string; name: string };
      } | null;
      userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
      users?: Array<{
        __typename?: "SessionUser";
        id: string;
        joinAt: any;
        leaveAt?: any | null;
        user: {
          __typename?: "User";
          id: string;
          name?: string | null;
          avatar?: { __typename?: "Media"; id: string; uri: string } | null;
          instructor?: {
            __typename?: "Instructor";
            id: string;
            job?: string | null;
            experiences?: Array<{
              __typename?: "Experience";
              id: string;
              place?: string | null;
              job?: string | null;
            } | null> | null;
          } | null;
          interpreter?: {
            __typename?: "Interpreter";
            id: string;
            experiences?: Array<{
              __typename?: "Experience";
              id: string;
              place?: string | null;
              job?: string | null;
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null> | null;
  };
};

export type GetSessionQueryVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
}>;

export type GetSessionQuery = {
  __typename?: "Query";
  getSession: {
    __typename?: "Session";
    id: string;
    jwt?: string | null;
    password?: string | null;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    status?: SessionStatus | null;
    needInterpreter?: boolean | null;
    createdAt: any;
    updatedAt: any;
    requester: {
      __typename?: "User";
      id: string;
      name?: string | null;
      usuableTickets: number;
    };
    instructor?: {
      __typename?: "Instructor";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    users?: Array<{
      __typename?: "SessionUser";
      id: string;
      joinAt: any;
      leaveAt?: any | null;
      active: boolean;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        instructor?: {
          __typename?: "Instructor";
          id: string;
          job?: string | null;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
        interpreter?: {
          __typename?: "Interpreter";
          id: string;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
      };
    } | null> | null;
    extensions?: Array<{
      __typename?: "SessionExtension";
      id: string;
      createdAt: any;
      session: { __typename?: "Session"; id: string };
      userPass?: { __typename?: "UserPass"; id: string } | null;
      userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
    } | null> | null;
  };
};

export type GetMyActiveSessionQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyActiveSessionQuery = {
  __typename?: "Query";
  getMyActiveSession?: {
    __typename?: "Session";
    id: string;
    jwt?: string | null;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    status?: SessionStatus | null;
    createdAt: any;
    updatedAt: any;
    requester: {
      __typename?: "User";
      id: string;
      name?: string | null;
      usuableTickets: number;
    };
    instructor?: {
      __typename?: "Instructor";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    users?: Array<{
      __typename?: "SessionUser";
      id: string;
      joinAt: any;
      leaveAt?: any | null;
      active: boolean;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        instructor?: {
          __typename?: "Instructor";
          id: string;
          job?: string | null;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
        interpreter?: {
          __typename?: "Interpreter";
          id: string;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
      };
    } | null> | null;
    extensions?: Array<{
      __typename?: "SessionExtension";
      id: string;
      createdAt: any;
      session: { __typename?: "Session"; id: string };
      userPass?: { __typename?: "UserPass"; id: string } | null;
      userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
    } | null> | null;
  } | null;
};

export type GetActiveSessionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetActiveSessionsQuery = {
  __typename?: "Query";
  getActiveSessions: {
    __typename?: "SessionConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges?: Array<{
      __typename?: "Session";
      id: string;
      password?: string | null;
      startAt?: any | null;
      endAt?: any | null;
      report?: string | null;
      needInterpreter?: boolean | null;
      status?: SessionStatus | null;
      jwt?: string | null;
      createdAt: any;
      updatedAt: any;
      requester: {
        __typename?: "User";
        id: string;
        languageOptions?: Array<{
          __typename?: "LanguageOption";
          id: string;
          name: string;
        } | null> | null;
      };
      instructor?: {
        __typename?: "Instructor";
        id: string;
        introduction?: string | null;
        job?: string | null;
        qualificationNumber?: string | null;
        user: {
          __typename?: "User";
          id: string;
          name?: string | null;
          avatar?: { __typename?: "Media"; id: string; uri: string } | null;
          languageOptions?: Array<{
            __typename?: "LanguageOption";
            id: string;
            name: string;
          } | null> | null;
        };
        educations?: Array<{
          __typename?: "Education";
          id: string;
          school?: string | null;
          major?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
        experiences?: Array<{
          __typename?: "Experience";
          id: string;
          place?: string | null;
          job?: string | null;
          duration?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
      } | null;
      extensions?: Array<{
        __typename?: "SessionExtension";
        id: string;
        createdAt: any;
        session: { __typename?: "Session"; id: string };
        userPass?: { __typename?: "UserPass"; id: string } | null;
        userSubscription?: {
          __typename?: "UserSubscription";
          id: string;
        } | null;
      } | null> | null;
    } | null> | null;
  };
};

export type JoinSessionMutationVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
}>;

export type JoinSessionMutation = {
  __typename?: "Mutation";
  joinSession: {
    __typename?: "Session";
    id: string;
    jwt?: string | null;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    status?: SessionStatus | null;
    createdAt: any;
    updatedAt: any;
    requester: {
      __typename?: "User";
      id: string;
      name?: string | null;
      usuableTickets: number;
    };
    instructor?: {
      __typename?: "Instructor";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    users?: Array<{
      __typename?: "SessionUser";
      id: string;
      joinAt: any;
      leaveAt?: any | null;
      active: boolean;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        instructor?: {
          __typename?: "Instructor";
          id: string;
          job?: string | null;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
        interpreter?: {
          __typename?: "Interpreter";
          id: string;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
      };
    } | null> | null;
    extensions?: Array<{
      __typename?: "SessionExtension";
      id: string;
      createdAt: any;
      session: { __typename?: "Session"; id: string };
      userPass?: { __typename?: "UserPass"; id: string } | null;
      userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
    } | null> | null;
  };
};

export type JoinSessionAsInterpreterMutationVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
}>;

export type JoinSessionAsInterpreterMutation = {
  __typename?: "Mutation";
  joinSessionAsInterpreter: {
    __typename?: "Session";
    id: string;
    jwt?: string | null;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    status?: SessionStatus | null;
    createdAt: any;
    updatedAt: any;
    requester: {
      __typename?: "User";
      id: string;
      name?: string | null;
      usuableTickets: number;
    };
    instructor?: {
      __typename?: "Instructor";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    users?: Array<{
      __typename?: "SessionUser";
      id: string;
      joinAt: any;
      leaveAt?: any | null;
      active: boolean;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        instructor?: {
          __typename?: "Instructor";
          id: string;
          job?: string | null;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
        interpreter?: {
          __typename?: "Interpreter";
          id: string;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
      };
    } | null> | null;
    extensions?: Array<{
      __typename?: "SessionExtension";
      id: string;
      createdAt: any;
      session: { __typename?: "Session"; id: string };
      userPass?: { __typename?: "UserPass"; id: string } | null;
      userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
    } | null> | null;
  };
};

export type LeaveSessionMutationVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
}>;

export type LeaveSessionMutation = {
  __typename?: "Mutation";
  leaveSession: {
    __typename?: "Session";
    id: string;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    status?: SessionStatus | null;
    createdAt: any;
    updatedAt: any;
  };
};

export type ToggleSessionStatusAsInstructorMutationVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
  status: SessionStatus;
}>;

export type ToggleSessionStatusAsInstructorMutation = {
  __typename?: "Mutation";
  toggleSessionStatusAsInstructor: {
    __typename?: "Session";
    id: string;
    endAt?: any | null;
    status?: SessionStatus | null;
  };
};

export type ToggleInterpreterNeededMutationVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
  needInterpreter: Scalars["Boolean"]["input"];
}>;

export type ToggleInterpreterNeededMutation = {
  __typename?: "Mutation";
  toggleInterpreterNeeded: {
    __typename?: "Session";
    id: string;
    needInterpreter?: boolean | null;
    status?: SessionStatus | null;
    createdAt: any;
    updatedAt: any;
  };
};

export type SessionUpdateSubscriptionVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
}>;

export type SessionUpdateSubscription = {
  __typename?: "Subscription";
  sessionUpdate: {
    __typename?: "Session";
    id: string;
    needInterpreter?: boolean | null;
    jwt?: string | null;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    status?: SessionStatus | null;
    createdAt: any;
    updatedAt: any;
    requester: {
      __typename?: "User";
      id: string;
      name?: string | null;
      usuableTickets: number;
    };
    instructor?: {
      __typename?: "Instructor";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    users?: Array<{
      __typename?: "SessionUser";
      id: string;
      joinAt: any;
      leaveAt?: any | null;
      active: boolean;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        instructor?: {
          __typename?: "Instructor";
          id: string;
          job?: string | null;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
        interpreter?: {
          __typename?: "Interpreter";
          id: string;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
      };
    } | null> | null;
    extensions?: Array<{
      __typename?: "SessionExtension";
      id: string;
      createdAt: any;
      session: { __typename?: "Session"; id: string };
      userPass?: { __typename?: "UserPass"; id: string } | null;
      userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
    } | null> | null;
  };
};

export type GetSessionMessagesQueryVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetSessionMessagesQuery = {
  __typename?: "Query";
  getSessionMessages: {
    __typename?: "MessageConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges?: Array<{
      __typename?: "Message";
      id: string;
      body?: string | null;
      createdAt: any;
      session: {
        __typename?: "Session";
        id: string;
        status?: SessionStatus | null;
      };
      user: { __typename?: "User"; id: string; name?: string | null };
      media?: { __typename?: "Media"; id: string; uri: string } | null;
    } | null> | null;
  };
};

export type NewMessageSubscriptionVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
}>;

export type NewMessageSubscription = {
  __typename?: "Subscription";
  newMessage?: {
    __typename?: "Message";
    id: string;
    body?: string | null;
    createdAt: any;
    session: {
      __typename?: "Session";
      id: string;
      status?: SessionStatus | null;
    };
    user: { __typename?: "User"; id: string; name?: string | null };
    media?: { __typename?: "Media"; id: string; uri: string } | null;
  } | null;
};

export type SendMessageMutationVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
  body?: InputMaybe<Scalars["String"]["input"]>;
  attachment?: InputMaybe<MediaInput>;
}>;

export type SendMessageMutation = {
  __typename?: "Mutation";
  sendMessage: {
    __typename?: "Message";
    id: string;
    body?: string | null;
    createdAt: any;
    session: {
      __typename?: "Session";
      id: string;
      status?: SessionStatus | null;
    };
    user: { __typename?: "User"; id: string; name?: string | null };
    media?: { __typename?: "Media"; id: string; uri: string } | null;
  };
};

export type UpdateSessionReportAsInstructorMutationVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
  report: Scalars["String"]["input"];
}>;

export type UpdateSessionReportAsInstructorMutation = {
  __typename?: "Mutation";
  updateSessionReportAsInstructor: {
    __typename?: "Session";
    id: string;
    report?: string | null;
  };
};

export type GetMyUserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyUserProfileQuery = {
  __typename?: "Query";
  getMyUserProfile: {
    __typename?: "User";
    id: string;
    type: UserType;
    email?: string | null;
    name?: string | null;
    gender?: Gender | null;
    phoneCode?: string | null;
    phone?: string | null;
    dateOfBirth?: any | null;
    provider?: string | null;
    resignReason?: string | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    nationality?: {
      __typename?: "Nationality";
      id: string;
      name: string;
    } | null;
    profile?: { __typename?: "Profile"; id: string } | null;
    instructor?: { __typename?: "Instructor"; id: string } | null;
    interpreter?: { __typename?: "Interpreter"; id: string } | null;
    languageOptions?: Array<{
      __typename?: "LanguageOption";
      id: string;
      name: string;
    } | null> | null;
  };
};

export type GetMyInstructorQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyInstructorQuery = {
  __typename?: "Query";
  getMyInstructor: {
    __typename?: "Instructor";
    id: string;
    introduction?: string | null;
    job?: string | null;
    qualificationNumber?: string | null;
    online: boolean;
    createdAt: any;
    updatedAt: any;
    user: {
      __typename?: "User";
      id: string;
      type: UserType;
      email?: string | null;
      name?: string | null;
      gender?: Gender | null;
      phoneCode?: string | null;
      phone?: string | null;
      dateOfBirth?: any | null;
      provider?: string | null;
      avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    };
    educations?: Array<{
      __typename?: "Education";
      id: string;
      school?: string | null;
      major?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    experiences?: Array<{
      __typename?: "Experience";
      id: string;
      place?: string | null;
      job?: string | null;
      duration?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
  };
};

export type GetMyInterpreterQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyInterpreterQuery = {
  __typename?: "Query";
  getMyInterpreter: {
    __typename?: "Interpreter";
    id: string;
    introduction?: string | null;
    visaNumber?: string | null;
    passportNumber?: string | null;
    online: boolean;
    createdAt: any;
    updatedAt: any;
    user: {
      __typename?: "User";
      id: string;
      type: UserType;
      email?: string | null;
      name?: string | null;
      gender?: Gender | null;
      phoneCode?: string | null;
      phone?: string | null;
      dateOfBirth?: any | null;
      provider?: string | null;
      avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    };
    educations?: Array<{
      __typename?: "Education";
      id: string;
      school?: string | null;
      major?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    experiences?: Array<{
      __typename?: "Experience";
      id: string;
      place?: string | null;
      job?: string | null;
      duration?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
  };
};

export type UpdateMyUserProfileMutationVariables = Exact<{
  userInput: UserInput;
}>;

export type UpdateMyUserProfileMutation = {
  __typename?: "Mutation";
  updateMyUserProfile: {
    __typename?: "User";
    id: string;
    type: UserType;
    email?: string | null;
    name?: string | null;
    gender?: Gender | null;
    phoneCode?: string | null;
    phone?: string | null;
    dateOfBirth?: any | null;
    provider?: string | null;
    resignReason?: string | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    nationality?: {
      __typename?: "Nationality";
      id: string;
      name: string;
    } | null;
    profile?: { __typename?: "Profile"; id: string } | null;
    instructor?: { __typename?: "Instructor"; id: string } | null;
    interpreter?: { __typename?: "Interpreter"; id: string } | null;
    languageOptions?: Array<{
      __typename?: "LanguageOption";
      id: string;
      name: string;
    } | null> | null;
  };
};

export type UpdateMyInstructorMutationVariables = Exact<{
  instructorInput: InstructorInput;
}>;

export type UpdateMyInstructorMutation = {
  __typename?: "Mutation";
  updateMyInstructor: {
    __typename?: "Instructor";
    id: string;
    introduction?: string | null;
    job?: string | null;
    qualificationNumber?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    educations?: Array<{
      __typename?: "Education";
      id: string;
      school?: string | null;
      major?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    experiences?: Array<{
      __typename?: "Experience";
      id: string;
      place?: string | null;
      job?: string | null;
      duration?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
  };
};

export type UpdateMyInstructorEducationsMutationVariables = Exact<{
  educationInputs:
    | Array<InputMaybe<EducationInput>>
    | InputMaybe<EducationInput>;
}>;

export type UpdateMyInstructorEducationsMutation = {
  __typename?: "Mutation";
  updateMyInstructorEducations: Array<{
    __typename?: "Education";
    id: string;
    school?: string | null;
    major?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null>;
};

export type DeleteMyInstructorEducationMutationVariables = Exact<{
  educationId: Scalars["ID"]["input"];
}>;

export type DeleteMyInstructorEducationMutation = {
  __typename?: "Mutation";
  deleteMyInstructorEducation: Array<{
    __typename?: "Education";
    id: string;
    school?: string | null;
    major?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null>;
};

export type UpdateMyInstructorExperiencesMutationVariables = Exact<{
  experienceInputs:
    | Array<InputMaybe<ExperienceInput>>
    | InputMaybe<ExperienceInput>;
}>;

export type UpdateMyInstructorExperiencesMutation = {
  __typename?: "Mutation";
  updateMyInstructorExperiences: Array<{
    __typename?: "Experience";
    id: string;
    place?: string | null;
    job?: string | null;
    duration?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null>;
};

export type DeleteMyInstructorExperienceMutationVariables = Exact<{
  experienceId: Scalars["ID"]["input"];
}>;

export type DeleteMyInstructorExperienceMutation = {
  __typename?: "Mutation";
  deleteMyInstructorExperience: Array<{
    __typename?: "Experience";
    id: string;
    place?: string | null;
    job?: string | null;
    duration?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null>;
};

export type UpdateMyInterpreterMutationVariables = Exact<{
  interpreterInput: InterpreterInput;
}>;

export type UpdateMyInterpreterMutation = {
  __typename?: "Mutation";
  updateMyInterpreter: {
    __typename?: "Interpreter";
    id: string;
    introduction?: string | null;
    visaNumber?: string | null;
    passportNumber?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    educations?: Array<{
      __typename?: "Education";
      id: string;
      school?: string | null;
      major?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    experiences?: Array<{
      __typename?: "Experience";
      id: string;
      place?: string | null;
      job?: string | null;
      duration?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
  };
};

export type UpdateMyInterpreterEducationsMutationVariables = Exact<{
  educationInputs:
    | Array<InputMaybe<EducationInput>>
    | InputMaybe<EducationInput>;
}>;

export type UpdateMyInterpreterEducationsMutation = {
  __typename?: "Mutation";
  updateMyInterpreterEducations: Array<{
    __typename?: "Education";
    id: string;
    school?: string | null;
    major?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null>;
};

export type DeleteMyInterpreterEducationMutationVariables = Exact<{
  educationId: Scalars["ID"]["input"];
}>;

export type DeleteMyInterpreterEducationMutation = {
  __typename?: "Mutation";
  deleteMyInterpreterEducation: Array<{
    __typename?: "Education";
    id: string;
    school?: string | null;
    major?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null>;
};

export type UpdateMyInterpreterExperiencesMutationVariables = Exact<{
  experienceInputs:
    | Array<InputMaybe<ExperienceInput>>
    | InputMaybe<ExperienceInput>;
}>;

export type UpdateMyInterpreterExperiencesMutation = {
  __typename?: "Mutation";
  updateMyInterpreterExperiences: Array<{
    __typename?: "Experience";
    id: string;
    place?: string | null;
    job?: string | null;
    duration?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null>;
};

export type DeleteMyInterpreterExperienceMutationVariables = Exact<{
  experienceId: Scalars["ID"]["input"];
}>;

export type DeleteMyInterpreterExperienceMutation = {
  __typename?: "Mutation";
  deleteMyInterpreterExperience: Array<{
    __typename?: "Experience";
    id: string;
    place?: string | null;
    job?: string | null;
    duration?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null>;
};

export type ToggleMyOnlineStatusAsInstructorMutationVariables = Exact<{
  status: Scalars["Boolean"]["input"];
}>;

export type ToggleMyOnlineStatusAsInstructorMutation = {
  __typename?: "Mutation";
  toggleMyOnlineStatusAsInstructor: {
    __typename?: "Instructor";
    id: string;
    online: boolean;
  };
};

export const GetMyBankAccountDocument = gql`
  query GetMyBankAccount {
    getMyBankAccount {
      id
      user {
        id
      }
      bankName
      accountNumber
    }
  }
`;

/**
 * __useGetMyBankAccountQuery__
 *
 * To run a query within a React component, call `useGetMyBankAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyBankAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyBankAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyBankAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyBankAccountQuery,
    GetMyBankAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyBankAccountQuery, GetMyBankAccountQueryVariables>(
    GetMyBankAccountDocument,
    options
  );
}
export function useGetMyBankAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyBankAccountQuery,
    GetMyBankAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyBankAccountQuery,
    GetMyBankAccountQueryVariables
  >(GetMyBankAccountDocument, options);
}
export function useGetMyBankAccountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyBankAccountQuery,
    GetMyBankAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyBankAccountQuery,
    GetMyBankAccountQueryVariables
  >(GetMyBankAccountDocument, options);
}
export type GetMyBankAccountQueryHookResult = ReturnType<
  typeof useGetMyBankAccountQuery
>;
export type GetMyBankAccountLazyQueryHookResult = ReturnType<
  typeof useGetMyBankAccountLazyQuery
>;
export type GetMyBankAccountSuspenseQueryHookResult = ReturnType<
  typeof useGetMyBankAccountSuspenseQuery
>;
export type GetMyBankAccountQueryResult = Apollo.QueryResult<
  GetMyBankAccountQuery,
  GetMyBankAccountQueryVariables
>;
export const UpdateMyBankAccountDocument = gql`
  mutation UpdateMyBankAccount($bankAccountInput: BankAccountInput!) {
    updateMyBankAccount(bankAccountInput: $bankAccountInput) {
      id
      user {
        id
      }
      bankName
      accountNumber
    }
  }
`;
export type UpdateMyBankAccountMutationFn = Apollo.MutationFunction<
  UpdateMyBankAccountMutation,
  UpdateMyBankAccountMutationVariables
>;

/**
 * __useUpdateMyBankAccountMutation__
 *
 * To run a mutation, you first call `useUpdateMyBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyBankAccountMutation, { data, loading, error }] = useUpdateMyBankAccountMutation({
 *   variables: {
 *      bankAccountInput: // value for 'bankAccountInput'
 *   },
 * });
 */
export function useUpdateMyBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyBankAccountMutation,
    UpdateMyBankAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyBankAccountMutation,
    UpdateMyBankAccountMutationVariables
  >(UpdateMyBankAccountDocument, options);
}
export type UpdateMyBankAccountMutationHookResult = ReturnType<
  typeof useUpdateMyBankAccountMutation
>;
export type UpdateMyBankAccountMutationResult =
  Apollo.MutationResult<UpdateMyBankAccountMutation>;
export type UpdateMyBankAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyBankAccountMutation,
  UpdateMyBankAccountMutationVariables
>;
export const InvitationUpdatesDocument = gql`
  subscription InvitationUpdates {
    invitationUpdates {
      id
      sender {
        id
        name
      }
      receiver {
        id
      }
      session {
        id
        jwt
        startAt
        endAt
        report
        status
      }
      status
      createdAt
      updatedAt
      expiresAt
    }
  }
`;

/**
 * __useInvitationUpdatesSubscription__
 *
 * To run a query within a React component, call `useInvitationUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInvitationUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationUpdatesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useInvitationUpdatesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    InvitationUpdatesSubscription,
    InvitationUpdatesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    InvitationUpdatesSubscription,
    InvitationUpdatesSubscriptionVariables
  >(InvitationUpdatesDocument, options);
}
export type InvitationUpdatesSubscriptionHookResult = ReturnType<
  typeof useInvitationUpdatesSubscription
>;
export type InvitationUpdatesSubscriptionResult =
  Apollo.SubscriptionResult<InvitationUpdatesSubscription>;
export const GetMyPendingInvitationsDocument = gql`
  query GetMyPendingInvitations {
    getMyPendingInvitations {
      id
      sender {
        id
        name
      }
      receiver {
        id
      }
      session {
        id
        jwt
        startAt
        endAt
        report
        status
      }
      status
      createdAt
      updatedAt
      expiresAt
    }
  }
`;

/**
 * __useGetMyPendingInvitationsQuery__
 *
 * To run a query within a React component, call `useGetMyPendingInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPendingInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPendingInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPendingInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyPendingInvitationsQuery,
    GetMyPendingInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyPendingInvitationsQuery,
    GetMyPendingInvitationsQueryVariables
  >(GetMyPendingInvitationsDocument, options);
}
export function useGetMyPendingInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyPendingInvitationsQuery,
    GetMyPendingInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyPendingInvitationsQuery,
    GetMyPendingInvitationsQueryVariables
  >(GetMyPendingInvitationsDocument, options);
}
export function useGetMyPendingInvitationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyPendingInvitationsQuery,
    GetMyPendingInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyPendingInvitationsQuery,
    GetMyPendingInvitationsQueryVariables
  >(GetMyPendingInvitationsDocument, options);
}
export type GetMyPendingInvitationsQueryHookResult = ReturnType<
  typeof useGetMyPendingInvitationsQuery
>;
export type GetMyPendingInvitationsLazyQueryHookResult = ReturnType<
  typeof useGetMyPendingInvitationsLazyQuery
>;
export type GetMyPendingInvitationsSuspenseQueryHookResult = ReturnType<
  typeof useGetMyPendingInvitationsSuspenseQuery
>;
export type GetMyPendingInvitationsQueryResult = Apollo.QueryResult<
  GetMyPendingInvitationsQuery,
  GetMyPendingInvitationsQueryVariables
>;
export const GetInvitationDocument = gql`
  query GetInvitation($invitationId: ID!) {
    getInvitation(invitationId: $invitationId) {
      id
      sender {
        id
        name
      }
      receiver {
        id
      }
      session {
        id
        jwt
        startAt
        endAt
        report
        status
      }
      status
      createdAt
      updatedAt
      expiresAt
    }
  }
`;

/**
 * __useGetInvitationQuery__
 *
 * To run a query within a React component, call `useGetInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationQuery({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useGetInvitationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvitationQuery,
    GetInvitationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvitationQuery, GetInvitationQueryVariables>(
    GetInvitationDocument,
    options
  );
}
export function useGetInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvitationQuery,
    GetInvitationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvitationQuery, GetInvitationQueryVariables>(
    GetInvitationDocument,
    options
  );
}
export function useGetInvitationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInvitationQuery,
    GetInvitationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetInvitationQuery,
    GetInvitationQueryVariables
  >(GetInvitationDocument, options);
}
export type GetInvitationQueryHookResult = ReturnType<
  typeof useGetInvitationQuery
>;
export type GetInvitationLazyQueryHookResult = ReturnType<
  typeof useGetInvitationLazyQuery
>;
export type GetInvitationSuspenseQueryHookResult = ReturnType<
  typeof useGetInvitationSuspenseQuery
>;
export type GetInvitationQueryResult = Apollo.QueryResult<
  GetInvitationQuery,
  GetInvitationQueryVariables
>;
export const RespondToInvitationDocument = gql`
  mutation RespondToInvitation($invitationId: ID!, $status: InvitationStatus!) {
    respondToInvitation(invitationId: $invitationId, status: $status) {
      id
      sender {
        id
        name
      }
      receiver {
        id
      }
      session {
        id
        jwt
        startAt
        endAt
        report
        status
      }
      status
      createdAt
      updatedAt
      expiresAt
    }
  }
`;
export type RespondToInvitationMutationFn = Apollo.MutationFunction<
  RespondToInvitationMutation,
  RespondToInvitationMutationVariables
>;

/**
 * __useRespondToInvitationMutation__
 *
 * To run a mutation, you first call `useRespondToInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondToInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondToInvitationMutation, { data, loading, error }] = useRespondToInvitationMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useRespondToInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RespondToInvitationMutation,
    RespondToInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RespondToInvitationMutation,
    RespondToInvitationMutationVariables
  >(RespondToInvitationDocument, options);
}
export type RespondToInvitationMutationHookResult = ReturnType<
  typeof useRespondToInvitationMutation
>;
export type RespondToInvitationMutationResult =
  Apollo.MutationResult<RespondToInvitationMutation>;
export type RespondToInvitationMutationOptions = Apollo.BaseMutationOptions<
  RespondToInvitationMutation,
  RespondToInvitationMutationVariables
>;
export const GetLanguageOptionsDocument = gql`
  query GetLanguageOptions($language: String) {
    getLanguageOptions(language: $language) {
      id
      language
      name
      active
      order
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetLanguageOptionsQuery__
 *
 * To run a query within a React component, call `useGetLanguageOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguageOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguageOptionsQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetLanguageOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >(GetLanguageOptionsDocument, options);
}
export function useGetLanguageOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >(GetLanguageOptionsDocument, options);
}
export function useGetLanguageOptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >(GetLanguageOptionsDocument, options);
}
export type GetLanguageOptionsQueryHookResult = ReturnType<
  typeof useGetLanguageOptionsQuery
>;
export type GetLanguageOptionsLazyQueryHookResult = ReturnType<
  typeof useGetLanguageOptionsLazyQuery
>;
export type GetLanguageOptionsSuspenseQueryHookResult = ReturnType<
  typeof useGetLanguageOptionsSuspenseQuery
>;
export type GetLanguageOptionsQueryResult = Apollo.QueryResult<
  GetLanguageOptionsQuery,
  GetLanguageOptionsQueryVariables
>;
export const GetManagementDocument = gql`
  query GetManagement {
    getManagement {
      id
      companyName
      address
      copyright
      email
      ceoName
      registrationNumber
      escroRegistration
      phone
      kakaoAddress
      sessionDuration
      shopAddress
      paymentWarning
      paymentInformation
    }
  }
`;

/**
 * __useGetManagementQuery__
 *
 * To run a query within a React component, call `useGetManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagementQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManagementQuery,
    GetManagementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManagementQuery, GetManagementQueryVariables>(
    GetManagementDocument,
    options
  );
}
export function useGetManagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementQuery,
    GetManagementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManagementQuery, GetManagementQueryVariables>(
    GetManagementDocument,
    options
  );
}
export function useGetManagementSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetManagementQuery,
    GetManagementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetManagementQuery,
    GetManagementQueryVariables
  >(GetManagementDocument, options);
}
export type GetManagementQueryHookResult = ReturnType<
  typeof useGetManagementQuery
>;
export type GetManagementLazyQueryHookResult = ReturnType<
  typeof useGetManagementLazyQuery
>;
export type GetManagementSuspenseQueryHookResult = ReturnType<
  typeof useGetManagementSuspenseQuery
>;
export type GetManagementQueryResult = Apollo.QueryResult<
  GetManagementQuery,
  GetManagementQueryVariables
>;
export const GetNationalitiesDocument = gql`
  query GetNationalities {
    getNationalities {
      id
      name
      emoji
      unicode
      imageUrl
    }
  }
`;

/**
 * __useGetNationalitiesQuery__
 *
 * To run a query within a React component, call `useGetNationalitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNationalitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNationalitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNationalitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNationalitiesQuery,
    GetNationalitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNationalitiesQuery, GetNationalitiesQueryVariables>(
    GetNationalitiesDocument,
    options
  );
}
export function useGetNationalitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNationalitiesQuery,
    GetNationalitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNationalitiesQuery,
    GetNationalitiesQueryVariables
  >(GetNationalitiesDocument, options);
}
export function useGetNationalitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetNationalitiesQuery,
    GetNationalitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetNationalitiesQuery,
    GetNationalitiesQueryVariables
  >(GetNationalitiesDocument, options);
}
export type GetNationalitiesQueryHookResult = ReturnType<
  typeof useGetNationalitiesQuery
>;
export type GetNationalitiesLazyQueryHookResult = ReturnType<
  typeof useGetNationalitiesLazyQuery
>;
export type GetNationalitiesSuspenseQueryHookResult = ReturnType<
  typeof useGetNationalitiesSuspenseQuery
>;
export type GetNationalitiesQueryResult = Apollo.QueryResult<
  GetNationalitiesQuery,
  GetNationalitiesQueryVariables
>;
export const GetMyPaymentHistoryDocument = gql`
  query GetMyPaymentHistory(
    $type: PaymentUnitPriceType!
    $first: Int
    $after: String
  ) {
    getMyPaymentHistory(type: $type, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        session {
          id
        }
        user {
          id
        }
        bankAccount {
          id
          bankName
          accountNumber
        }
        amount
        currency
        createdAt
      }
    }
  }
`;

/**
 * __useGetMyPaymentHistoryQuery__
 *
 * To run a query within a React component, call `useGetMyPaymentHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPaymentHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPaymentHistoryQuery({
 *   variables: {
 *      type: // value for 'type'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetMyPaymentHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyPaymentHistoryQuery,
    GetMyPaymentHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyPaymentHistoryQuery,
    GetMyPaymentHistoryQueryVariables
  >(GetMyPaymentHistoryDocument, options);
}
export function useGetMyPaymentHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyPaymentHistoryQuery,
    GetMyPaymentHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyPaymentHistoryQuery,
    GetMyPaymentHistoryQueryVariables
  >(GetMyPaymentHistoryDocument, options);
}
export function useGetMyPaymentHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyPaymentHistoryQuery,
    GetMyPaymentHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyPaymentHistoryQuery,
    GetMyPaymentHistoryQueryVariables
  >(GetMyPaymentHistoryDocument, options);
}
export type GetMyPaymentHistoryQueryHookResult = ReturnType<
  typeof useGetMyPaymentHistoryQuery
>;
export type GetMyPaymentHistoryLazyQueryHookResult = ReturnType<
  typeof useGetMyPaymentHistoryLazyQuery
>;
export type GetMyPaymentHistorySuspenseQueryHookResult = ReturnType<
  typeof useGetMyPaymentHistorySuspenseQuery
>;
export type GetMyPaymentHistoryQueryResult = Apollo.QueryResult<
  GetMyPaymentHistoryQuery,
  GetMyPaymentHistoryQueryVariables
>;
export const GetMySessionsDocument = gql`
  query GetMySessions($first: Int, $after: String) {
    getMySessions(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        requester {
          id
          name
          usuableTickets
          avatar {
            id
            uri
          }
        }
        instructor {
          id
          user {
            id
            name
            avatar {
              id
              uri
            }
          }
        }
        userPass {
          id
          pass {
            id
            name
          }
        }
        userSubscription {
          id
        }
        jwt
        password
        startAt
        endAt
        report
        status
        needInterpreter
        users {
          id
          user {
            id
            name
            avatar {
              id
              uri
            }
            instructor {
              id
              job
              experiences {
                id
                place
                job
              }
            }
            interpreter {
              id
              experiences {
                id
                place
                job
              }
            }
          }
          joinAt
          leaveAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetMySessionsQuery__
 *
 * To run a query within a React component, call `useGetMySessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySessionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetMySessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMySessionsQuery,
    GetMySessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMySessionsQuery, GetMySessionsQueryVariables>(
    GetMySessionsDocument,
    options
  );
}
export function useGetMySessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMySessionsQuery,
    GetMySessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMySessionsQuery, GetMySessionsQueryVariables>(
    GetMySessionsDocument,
    options
  );
}
export function useGetMySessionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMySessionsQuery,
    GetMySessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMySessionsQuery,
    GetMySessionsQueryVariables
  >(GetMySessionsDocument, options);
}
export type GetMySessionsQueryHookResult = ReturnType<
  typeof useGetMySessionsQuery
>;
export type GetMySessionsLazyQueryHookResult = ReturnType<
  typeof useGetMySessionsLazyQuery
>;
export type GetMySessionsSuspenseQueryHookResult = ReturnType<
  typeof useGetMySessionsSuspenseQuery
>;
export type GetMySessionsQueryResult = Apollo.QueryResult<
  GetMySessionsQuery,
  GetMySessionsQueryVariables
>;
export const GetSessionDocument = gql`
  query GetSession($sessionId: ID!) {
    getSession(sessionId: $sessionId) {
      id
      requester {
        id
        name
        usuableTickets
      }
      instructor {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
        }
      }
      jwt
      password
      startAt
      endAt
      report
      status
      needInterpreter
      users {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
          instructor {
            id
            job
            experiences {
              id
              place
              job
            }
          }
          interpreter {
            id
            experiences {
              id
              place
              job
            }
          }
        }
        joinAt
        leaveAt
        active
      }
      extensions {
        id
        session {
          id
        }
        userPass {
          id
        }
        userSubscription {
          id
        }
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetSessionQuery__
 *
 * To run a query within a React component, call `useGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSessionQuery,
    GetSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSessionQuery, GetSessionQueryVariables>(
    GetSessionDocument,
    options
  );
}
export function useGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSessionQuery,
    GetSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSessionQuery, GetSessionQueryVariables>(
    GetSessionDocument,
    options
  );
}
export function useGetSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSessionQuery,
    GetSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSessionQuery, GetSessionQueryVariables>(
    GetSessionDocument,
    options
  );
}
export type GetSessionQueryHookResult = ReturnType<typeof useGetSessionQuery>;
export type GetSessionLazyQueryHookResult = ReturnType<
  typeof useGetSessionLazyQuery
>;
export type GetSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetSessionSuspenseQuery
>;
export type GetSessionQueryResult = Apollo.QueryResult<
  GetSessionQuery,
  GetSessionQueryVariables
>;
export const GetMyActiveSessionDocument = gql`
  query GetMyActiveSession {
    getMyActiveSession {
      id
      requester {
        id
        name
        usuableTickets
      }
      instructor {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
        }
      }
      jwt
      startAt
      endAt
      report
      status
      users {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
          instructor {
            id
            job
            experiences {
              id
              place
              job
            }
          }
          interpreter {
            id
            experiences {
              id
              place
              job
            }
          }
        }
        joinAt
        leaveAt
        active
      }
      extensions {
        id
        session {
          id
        }
        userPass {
          id
        }
        userSubscription {
          id
        }
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMyActiveSessionQuery__
 *
 * To run a query within a React component, call `useGetMyActiveSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyActiveSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyActiveSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyActiveSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyActiveSessionQuery,
    GetMyActiveSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyActiveSessionQuery,
    GetMyActiveSessionQueryVariables
  >(GetMyActiveSessionDocument, options);
}
export function useGetMyActiveSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyActiveSessionQuery,
    GetMyActiveSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyActiveSessionQuery,
    GetMyActiveSessionQueryVariables
  >(GetMyActiveSessionDocument, options);
}
export function useGetMyActiveSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyActiveSessionQuery,
    GetMyActiveSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyActiveSessionQuery,
    GetMyActiveSessionQueryVariables
  >(GetMyActiveSessionDocument, options);
}
export type GetMyActiveSessionQueryHookResult = ReturnType<
  typeof useGetMyActiveSessionQuery
>;
export type GetMyActiveSessionLazyQueryHookResult = ReturnType<
  typeof useGetMyActiveSessionLazyQuery
>;
export type GetMyActiveSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetMyActiveSessionSuspenseQuery
>;
export type GetMyActiveSessionQueryResult = Apollo.QueryResult<
  GetMyActiveSessionQuery,
  GetMyActiveSessionQueryVariables
>;
export const GetActiveSessionsDocument = gql`
  query GetActiveSessions($first: Int, $after: String) {
    getActiveSessions(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        requester {
          id
          languageOptions {
            id
            name
          }
        }
        instructor {
          id
          user {
            id
            name
            avatar {
              id
              uri
            }
            languageOptions {
              id
              name
            }
          }
          introduction
          job
          qualificationNumber
          educations {
            id
            school
            major
            createdAt
            updatedAt
          }
          experiences {
            id
            place
            job
            duration
            createdAt
            updatedAt
          }
        }
        password
        startAt
        endAt
        report
        needInterpreter
        status
        jwt
        extensions {
          id
          session {
            id
          }
          userPass {
            id
          }
          userSubscription {
            id
          }
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetActiveSessionsQuery__
 *
 * To run a query within a React component, call `useGetActiveSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveSessionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetActiveSessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveSessionsQuery,
    GetActiveSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveSessionsQuery,
    GetActiveSessionsQueryVariables
  >(GetActiveSessionsDocument, options);
}
export function useGetActiveSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveSessionsQuery,
    GetActiveSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveSessionsQuery,
    GetActiveSessionsQueryVariables
  >(GetActiveSessionsDocument, options);
}
export function useGetActiveSessionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetActiveSessionsQuery,
    GetActiveSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetActiveSessionsQuery,
    GetActiveSessionsQueryVariables
  >(GetActiveSessionsDocument, options);
}
export type GetActiveSessionsQueryHookResult = ReturnType<
  typeof useGetActiveSessionsQuery
>;
export type GetActiveSessionsLazyQueryHookResult = ReturnType<
  typeof useGetActiveSessionsLazyQuery
>;
export type GetActiveSessionsSuspenseQueryHookResult = ReturnType<
  typeof useGetActiveSessionsSuspenseQuery
>;
export type GetActiveSessionsQueryResult = Apollo.QueryResult<
  GetActiveSessionsQuery,
  GetActiveSessionsQueryVariables
>;
export const JoinSessionDocument = gql`
  mutation JoinSession($sessionId: ID!) {
    joinSession(sessionId: $sessionId) {
      id
      requester {
        id
        name
        usuableTickets
      }
      instructor {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
        }
      }
      jwt
      startAt
      endAt
      report
      status
      users {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
          instructor {
            id
            job
            experiences {
              id
              place
              job
            }
          }
          interpreter {
            id
            experiences {
              id
              place
              job
            }
          }
        }
        joinAt
        leaveAt
        active
      }
      extensions {
        id
        session {
          id
        }
        userPass {
          id
        }
        userSubscription {
          id
        }
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export type JoinSessionMutationFn = Apollo.MutationFunction<
  JoinSessionMutation,
  JoinSessionMutationVariables
>;

/**
 * __useJoinSessionMutation__
 *
 * To run a mutation, you first call `useJoinSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinSessionMutation, { data, loading, error }] = useJoinSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useJoinSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinSessionMutation,
    JoinSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JoinSessionMutation, JoinSessionMutationVariables>(
    JoinSessionDocument,
    options
  );
}
export type JoinSessionMutationHookResult = ReturnType<
  typeof useJoinSessionMutation
>;
export type JoinSessionMutationResult =
  Apollo.MutationResult<JoinSessionMutation>;
export type JoinSessionMutationOptions = Apollo.BaseMutationOptions<
  JoinSessionMutation,
  JoinSessionMutationVariables
>;
export const JoinSessionAsInterpreterDocument = gql`
  mutation JoinSessionAsInterpreter($sessionId: ID!) {
    joinSessionAsInterpreter(sessionId: $sessionId) {
      id
      requester {
        id
        name
        usuableTickets
      }
      instructor {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
        }
      }
      jwt
      startAt
      endAt
      report
      status
      users {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
          instructor {
            id
            job
            experiences {
              id
              place
              job
            }
          }
          interpreter {
            id
            experiences {
              id
              place
              job
            }
          }
        }
        joinAt
        leaveAt
        active
      }
      extensions {
        id
        session {
          id
        }
        userPass {
          id
        }
        userSubscription {
          id
        }
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export type JoinSessionAsInterpreterMutationFn = Apollo.MutationFunction<
  JoinSessionAsInterpreterMutation,
  JoinSessionAsInterpreterMutationVariables
>;

/**
 * __useJoinSessionAsInterpreterMutation__
 *
 * To run a mutation, you first call `useJoinSessionAsInterpreterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinSessionAsInterpreterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinSessionAsInterpreterMutation, { data, loading, error }] = useJoinSessionAsInterpreterMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useJoinSessionAsInterpreterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinSessionAsInterpreterMutation,
    JoinSessionAsInterpreterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinSessionAsInterpreterMutation,
    JoinSessionAsInterpreterMutationVariables
  >(JoinSessionAsInterpreterDocument, options);
}
export type JoinSessionAsInterpreterMutationHookResult = ReturnType<
  typeof useJoinSessionAsInterpreterMutation
>;
export type JoinSessionAsInterpreterMutationResult =
  Apollo.MutationResult<JoinSessionAsInterpreterMutation>;
export type JoinSessionAsInterpreterMutationOptions =
  Apollo.BaseMutationOptions<
    JoinSessionAsInterpreterMutation,
    JoinSessionAsInterpreterMutationVariables
  >;
export const LeaveSessionDocument = gql`
  mutation LeaveSession($sessionId: ID!) {
    leaveSession(sessionId: $sessionId) {
      id
      startAt
      endAt
      report
      status
      createdAt
      updatedAt
    }
  }
`;
export type LeaveSessionMutationFn = Apollo.MutationFunction<
  LeaveSessionMutation,
  LeaveSessionMutationVariables
>;

/**
 * __useLeaveSessionMutation__
 *
 * To run a mutation, you first call `useLeaveSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveSessionMutation, { data, loading, error }] = useLeaveSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useLeaveSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveSessionMutation,
    LeaveSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LeaveSessionMutation,
    LeaveSessionMutationVariables
  >(LeaveSessionDocument, options);
}
export type LeaveSessionMutationHookResult = ReturnType<
  typeof useLeaveSessionMutation
>;
export type LeaveSessionMutationResult =
  Apollo.MutationResult<LeaveSessionMutation>;
export type LeaveSessionMutationOptions = Apollo.BaseMutationOptions<
  LeaveSessionMutation,
  LeaveSessionMutationVariables
>;
export const ToggleSessionStatusAsInstructorDocument = gql`
  mutation ToggleSessionStatusAsInstructor(
    $sessionId: ID!
    $status: SessionStatus!
  ) {
    toggleSessionStatusAsInstructor(sessionId: $sessionId, status: $status) {
      id
      endAt
      status
    }
  }
`;
export type ToggleSessionStatusAsInstructorMutationFn = Apollo.MutationFunction<
  ToggleSessionStatusAsInstructorMutation,
  ToggleSessionStatusAsInstructorMutationVariables
>;

/**
 * __useToggleSessionStatusAsInstructorMutation__
 *
 * To run a mutation, you first call `useToggleSessionStatusAsInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleSessionStatusAsInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleSessionStatusAsInstructorMutation, { data, loading, error }] = useToggleSessionStatusAsInstructorMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleSessionStatusAsInstructorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleSessionStatusAsInstructorMutation,
    ToggleSessionStatusAsInstructorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleSessionStatusAsInstructorMutation,
    ToggleSessionStatusAsInstructorMutationVariables
  >(ToggleSessionStatusAsInstructorDocument, options);
}
export type ToggleSessionStatusAsInstructorMutationHookResult = ReturnType<
  typeof useToggleSessionStatusAsInstructorMutation
>;
export type ToggleSessionStatusAsInstructorMutationResult =
  Apollo.MutationResult<ToggleSessionStatusAsInstructorMutation>;
export type ToggleSessionStatusAsInstructorMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleSessionStatusAsInstructorMutation,
    ToggleSessionStatusAsInstructorMutationVariables
  >;
export const ToggleInterpreterNeededDocument = gql`
  mutation ToggleInterpreterNeeded(
    $sessionId: ID!
    $needInterpreter: Boolean!
  ) {
    toggleInterpreterNeeded(
      sessionId: $sessionId
      needInterpreter: $needInterpreter
    ) {
      id
      needInterpreter
      status
      createdAt
      updatedAt
    }
  }
`;
export type ToggleInterpreterNeededMutationFn = Apollo.MutationFunction<
  ToggleInterpreterNeededMutation,
  ToggleInterpreterNeededMutationVariables
>;

/**
 * __useToggleInterpreterNeededMutation__
 *
 * To run a mutation, you first call `useToggleInterpreterNeededMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleInterpreterNeededMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleInterpreterNeededMutation, { data, loading, error }] = useToggleInterpreterNeededMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      needInterpreter: // value for 'needInterpreter'
 *   },
 * });
 */
export function useToggleInterpreterNeededMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleInterpreterNeededMutation,
    ToggleInterpreterNeededMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleInterpreterNeededMutation,
    ToggleInterpreterNeededMutationVariables
  >(ToggleInterpreterNeededDocument, options);
}
export type ToggleInterpreterNeededMutationHookResult = ReturnType<
  typeof useToggleInterpreterNeededMutation
>;
export type ToggleInterpreterNeededMutationResult =
  Apollo.MutationResult<ToggleInterpreterNeededMutation>;
export type ToggleInterpreterNeededMutationOptions = Apollo.BaseMutationOptions<
  ToggleInterpreterNeededMutation,
  ToggleInterpreterNeededMutationVariables
>;
export const SessionUpdateDocument = gql`
  subscription SessionUpdate($sessionId: ID!) {
    sessionUpdate(sessionId: $sessionId) {
      id
      requester {
        id
        name
        usuableTickets
      }
      instructor {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
        }
      }
      needInterpreter
      jwt
      startAt
      endAt
      report
      status
      users {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
          instructor {
            id
            job
            experiences {
              id
              place
              job
            }
          }
          interpreter {
            id
            experiences {
              id
              place
              job
            }
          }
        }
        joinAt
        leaveAt
        active
      }
      extensions {
        id
        session {
          id
        }
        userPass {
          id
        }
        userSubscription {
          id
        }
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useSessionUpdateSubscription__
 *
 * To run a query within a React component, call `useSessionUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionUpdateSubscription({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useSessionUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SessionUpdateSubscription,
    SessionUpdateSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SessionUpdateSubscription,
    SessionUpdateSubscriptionVariables
  >(SessionUpdateDocument, options);
}
export type SessionUpdateSubscriptionHookResult = ReturnType<
  typeof useSessionUpdateSubscription
>;
export type SessionUpdateSubscriptionResult =
  Apollo.SubscriptionResult<SessionUpdateSubscription>;
export const GetSessionMessagesDocument = gql`
  query GetSessionMessages($sessionId: ID!, $after: String, $first: Int) {
    getSessionMessages(sessionId: $sessionId, after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        session {
          id
          status
        }
        user {
          id
          name
        }
        body
        media {
          id
          uri
        }
        createdAt
      }
    }
  }
`;

/**
 * __useGetSessionMessagesQuery__
 *
 * To run a query within a React component, call `useGetSessionMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionMessagesQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetSessionMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSessionMessagesQuery,
    GetSessionMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSessionMessagesQuery,
    GetSessionMessagesQueryVariables
  >(GetSessionMessagesDocument, options);
}
export function useGetSessionMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSessionMessagesQuery,
    GetSessionMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSessionMessagesQuery,
    GetSessionMessagesQueryVariables
  >(GetSessionMessagesDocument, options);
}
export function useGetSessionMessagesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSessionMessagesQuery,
    GetSessionMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSessionMessagesQuery,
    GetSessionMessagesQueryVariables
  >(GetSessionMessagesDocument, options);
}
export type GetSessionMessagesQueryHookResult = ReturnType<
  typeof useGetSessionMessagesQuery
>;
export type GetSessionMessagesLazyQueryHookResult = ReturnType<
  typeof useGetSessionMessagesLazyQuery
>;
export type GetSessionMessagesSuspenseQueryHookResult = ReturnType<
  typeof useGetSessionMessagesSuspenseQuery
>;
export type GetSessionMessagesQueryResult = Apollo.QueryResult<
  GetSessionMessagesQuery,
  GetSessionMessagesQueryVariables
>;
export const NewMessageDocument = gql`
  subscription NewMessage($sessionId: ID!) {
    newMessage(sessionId: $sessionId) {
      id
      session {
        id
        status
      }
      user {
        id
        name
      }
      body
      media {
        id
        uri
      }
      createdAt
    }
  }
`;

/**
 * __useNewMessageSubscription__
 *
 * To run a query within a React component, call `useNewMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMessageSubscription({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useNewMessageSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewMessageSubscription,
    NewMessageSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewMessageSubscription,
    NewMessageSubscriptionVariables
  >(NewMessageDocument, options);
}
export type NewMessageSubscriptionHookResult = ReturnType<
  typeof useNewMessageSubscription
>;
export type NewMessageSubscriptionResult =
  Apollo.SubscriptionResult<NewMessageSubscription>;
export const SendMessageDocument = gql`
  mutation SendMessage(
    $sessionId: ID!
    $body: String
    $attachment: MediaInput
  ) {
    sendMessage(sessionId: $sessionId, body: $body, attachment: $attachment) {
      id
      session {
        id
        status
      }
      user {
        id
        name
      }
      body
      media {
        id
        uri
      }
      createdAt
    }
  }
`;
export type SendMessageMutationFn = Apollo.MutationFunction<
  SendMessageMutation,
  SendMessageMutationVariables
>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      body: // value for 'body'
 *      attachment: // value for 'attachment'
 *   },
 * });
 */
export function useSendMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendMessageMutation,
    SendMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(
    SendMessageDocument,
    options
  );
}
export type SendMessageMutationHookResult = ReturnType<
  typeof useSendMessageMutation
>;
export type SendMessageMutationResult =
  Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<
  SendMessageMutation,
  SendMessageMutationVariables
>;
export const UpdateSessionReportAsInstructorDocument = gql`
  mutation UpdateSessionReportAsInstructor($sessionId: ID!, $report: String!) {
    updateSessionReportAsInstructor(sessionId: $sessionId, report: $report) {
      id
      report
    }
  }
`;
export type UpdateSessionReportAsInstructorMutationFn = Apollo.MutationFunction<
  UpdateSessionReportAsInstructorMutation,
  UpdateSessionReportAsInstructorMutationVariables
>;

/**
 * __useUpdateSessionReportAsInstructorMutation__
 *
 * To run a mutation, you first call `useUpdateSessionReportAsInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionReportAsInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionReportAsInstructorMutation, { data, loading, error }] = useUpdateSessionReportAsInstructorMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      report: // value for 'report'
 *   },
 * });
 */
export function useUpdateSessionReportAsInstructorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSessionReportAsInstructorMutation,
    UpdateSessionReportAsInstructorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSessionReportAsInstructorMutation,
    UpdateSessionReportAsInstructorMutationVariables
  >(UpdateSessionReportAsInstructorDocument, options);
}
export type UpdateSessionReportAsInstructorMutationHookResult = ReturnType<
  typeof useUpdateSessionReportAsInstructorMutation
>;
export type UpdateSessionReportAsInstructorMutationResult =
  Apollo.MutationResult<UpdateSessionReportAsInstructorMutation>;
export type UpdateSessionReportAsInstructorMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateSessionReportAsInstructorMutation,
    UpdateSessionReportAsInstructorMutationVariables
  >;
export const GetMyUserProfileDocument = gql`
  query GetMyUserProfile {
    getMyUserProfile {
      id
      type
      email
      name
      gender
      phoneCode
      phone
      dateOfBirth
      provider
      avatar {
        id
        uri
      }
      resignReason
      nationality {
        id
        name
      }
      profile {
        id
      }
      instructor {
        id
      }
      interpreter {
        id
      }
      languageOptions {
        id
        name
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

/**
 * __useGetMyUserProfileQuery__
 *
 * To run a query within a React component, call `useGetMyUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyUserProfileQuery, GetMyUserProfileQueryVariables>(
    GetMyUserProfileDocument,
    options
  );
}
export function useGetMyUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >(GetMyUserProfileDocument, options);
}
export function useGetMyUserProfileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >(GetMyUserProfileDocument, options);
}
export type GetMyUserProfileQueryHookResult = ReturnType<
  typeof useGetMyUserProfileQuery
>;
export type GetMyUserProfileLazyQueryHookResult = ReturnType<
  typeof useGetMyUserProfileLazyQuery
>;
export type GetMyUserProfileSuspenseQueryHookResult = ReturnType<
  typeof useGetMyUserProfileSuspenseQuery
>;
export type GetMyUserProfileQueryResult = Apollo.QueryResult<
  GetMyUserProfileQuery,
  GetMyUserProfileQueryVariables
>;
export const GetMyInstructorDocument = gql`
  query GetMyInstructor {
    getMyInstructor {
      id
      user {
        id
        type
        email
        name
        gender
        phoneCode
        phone
        dateOfBirth
        provider
        avatar {
          id
          uri
        }
      }
      introduction
      job
      qualificationNumber
      educations {
        id
        school
        major
        createdAt
        updatedAt
      }
      experiences {
        id
        place
        job
        duration
        createdAt
        updatedAt
      }
      online
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMyInstructorQuery__
 *
 * To run a query within a React component, call `useGetMyInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyInstructorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyInstructorQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyInstructorQuery,
    GetMyInstructorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyInstructorQuery, GetMyInstructorQueryVariables>(
    GetMyInstructorDocument,
    options
  );
}
export function useGetMyInstructorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyInstructorQuery,
    GetMyInstructorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyInstructorQuery,
    GetMyInstructorQueryVariables
  >(GetMyInstructorDocument, options);
}
export function useGetMyInstructorSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyInstructorQuery,
    GetMyInstructorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyInstructorQuery,
    GetMyInstructorQueryVariables
  >(GetMyInstructorDocument, options);
}
export type GetMyInstructorQueryHookResult = ReturnType<
  typeof useGetMyInstructorQuery
>;
export type GetMyInstructorLazyQueryHookResult = ReturnType<
  typeof useGetMyInstructorLazyQuery
>;
export type GetMyInstructorSuspenseQueryHookResult = ReturnType<
  typeof useGetMyInstructorSuspenseQuery
>;
export type GetMyInstructorQueryResult = Apollo.QueryResult<
  GetMyInstructorQuery,
  GetMyInstructorQueryVariables
>;
export const GetMyInterpreterDocument = gql`
  query GetMyInterpreter {
    getMyInterpreter {
      id
      user {
        id
        type
        email
        name
        gender
        phoneCode
        phone
        dateOfBirth
        provider
        avatar {
          id
          uri
        }
      }
      introduction
      visaNumber
      passportNumber
      educations {
        id
        school
        major
        createdAt
        updatedAt
      }
      experiences {
        id
        place
        job
        duration
        createdAt
        updatedAt
      }
      online
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMyInterpreterQuery__
 *
 * To run a query within a React component, call `useGetMyInterpreterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyInterpreterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyInterpreterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyInterpreterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyInterpreterQuery,
    GetMyInterpreterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyInterpreterQuery, GetMyInterpreterQueryVariables>(
    GetMyInterpreterDocument,
    options
  );
}
export function useGetMyInterpreterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyInterpreterQuery,
    GetMyInterpreterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyInterpreterQuery,
    GetMyInterpreterQueryVariables
  >(GetMyInterpreterDocument, options);
}
export function useGetMyInterpreterSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyInterpreterQuery,
    GetMyInterpreterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyInterpreterQuery,
    GetMyInterpreterQueryVariables
  >(GetMyInterpreterDocument, options);
}
export type GetMyInterpreterQueryHookResult = ReturnType<
  typeof useGetMyInterpreterQuery
>;
export type GetMyInterpreterLazyQueryHookResult = ReturnType<
  typeof useGetMyInterpreterLazyQuery
>;
export type GetMyInterpreterSuspenseQueryHookResult = ReturnType<
  typeof useGetMyInterpreterSuspenseQuery
>;
export type GetMyInterpreterQueryResult = Apollo.QueryResult<
  GetMyInterpreterQuery,
  GetMyInterpreterQueryVariables
>;
export const UpdateMyUserProfileDocument = gql`
  mutation UpdateMyUserProfile($userInput: UserInput!) {
    updateMyUserProfile(userInput: $userInput) {
      id
      type
      email
      name
      gender
      phoneCode
      phone
      dateOfBirth
      provider
      avatar {
        id
        uri
      }
      resignReason
      nationality {
        id
        name
      }
      profile {
        id
      }
      instructor {
        id
      }
      interpreter {
        id
      }
      languageOptions {
        id
        name
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export type UpdateMyUserProfileMutationFn = Apollo.MutationFunction<
  UpdateMyUserProfileMutation,
  UpdateMyUserProfileMutationVariables
>;

/**
 * __useUpdateMyUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateMyUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyUserProfileMutation, { data, loading, error }] = useUpdateMyUserProfileMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useUpdateMyUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyUserProfileMutation,
    UpdateMyUserProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyUserProfileMutation,
    UpdateMyUserProfileMutationVariables
  >(UpdateMyUserProfileDocument, options);
}
export type UpdateMyUserProfileMutationHookResult = ReturnType<
  typeof useUpdateMyUserProfileMutation
>;
export type UpdateMyUserProfileMutationResult =
  Apollo.MutationResult<UpdateMyUserProfileMutation>;
export type UpdateMyUserProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyUserProfileMutation,
  UpdateMyUserProfileMutationVariables
>;
export const UpdateMyInstructorDocument = gql`
  mutation UpdateMyInstructor($instructorInput: InstructorInput!) {
    updateMyInstructor(instructorInput: $instructorInput) {
      id
      user {
        id
      }
      introduction
      job
      qualificationNumber
      educations {
        id
        school
        major
        createdAt
        updatedAt
      }
      experiences {
        id
        place
        job
        duration
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMyInstructorMutationFn = Apollo.MutationFunction<
  UpdateMyInstructorMutation,
  UpdateMyInstructorMutationVariables
>;

/**
 * __useUpdateMyInstructorMutation__
 *
 * To run a mutation, you first call `useUpdateMyInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyInstructorMutation, { data, loading, error }] = useUpdateMyInstructorMutation({
 *   variables: {
 *      instructorInput: // value for 'instructorInput'
 *   },
 * });
 */
export function useUpdateMyInstructorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyInstructorMutation,
    UpdateMyInstructorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyInstructorMutation,
    UpdateMyInstructorMutationVariables
  >(UpdateMyInstructorDocument, options);
}
export type UpdateMyInstructorMutationHookResult = ReturnType<
  typeof useUpdateMyInstructorMutation
>;
export type UpdateMyInstructorMutationResult =
  Apollo.MutationResult<UpdateMyInstructorMutation>;
export type UpdateMyInstructorMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyInstructorMutation,
  UpdateMyInstructorMutationVariables
>;
export const UpdateMyInstructorEducationsDocument = gql`
  mutation UpdateMyInstructorEducations($educationInputs: [EducationInput]!) {
    updateMyInstructorEducations(educationInputs: $educationInputs) {
      id
      school
      major
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMyInstructorEducationsMutationFn = Apollo.MutationFunction<
  UpdateMyInstructorEducationsMutation,
  UpdateMyInstructorEducationsMutationVariables
>;

/**
 * __useUpdateMyInstructorEducationsMutation__
 *
 * To run a mutation, you first call `useUpdateMyInstructorEducationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyInstructorEducationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyInstructorEducationsMutation, { data, loading, error }] = useUpdateMyInstructorEducationsMutation({
 *   variables: {
 *      educationInputs: // value for 'educationInputs'
 *   },
 * });
 */
export function useUpdateMyInstructorEducationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyInstructorEducationsMutation,
    UpdateMyInstructorEducationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyInstructorEducationsMutation,
    UpdateMyInstructorEducationsMutationVariables
  >(UpdateMyInstructorEducationsDocument, options);
}
export type UpdateMyInstructorEducationsMutationHookResult = ReturnType<
  typeof useUpdateMyInstructorEducationsMutation
>;
export type UpdateMyInstructorEducationsMutationResult =
  Apollo.MutationResult<UpdateMyInstructorEducationsMutation>;
export type UpdateMyInstructorEducationsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMyInstructorEducationsMutation,
    UpdateMyInstructorEducationsMutationVariables
  >;
export const DeleteMyInstructorEducationDocument = gql`
  mutation DeleteMyInstructorEducation($educationId: ID!) {
    deleteMyInstructorEducation(educationId: $educationId) {
      id
      school
      major
      createdAt
      updatedAt
    }
  }
`;
export type DeleteMyInstructorEducationMutationFn = Apollo.MutationFunction<
  DeleteMyInstructorEducationMutation,
  DeleteMyInstructorEducationMutationVariables
>;

/**
 * __useDeleteMyInstructorEducationMutation__
 *
 * To run a mutation, you first call `useDeleteMyInstructorEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyInstructorEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyInstructorEducationMutation, { data, loading, error }] = useDeleteMyInstructorEducationMutation({
 *   variables: {
 *      educationId: // value for 'educationId'
 *   },
 * });
 */
export function useDeleteMyInstructorEducationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMyInstructorEducationMutation,
    DeleteMyInstructorEducationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMyInstructorEducationMutation,
    DeleteMyInstructorEducationMutationVariables
  >(DeleteMyInstructorEducationDocument, options);
}
export type DeleteMyInstructorEducationMutationHookResult = ReturnType<
  typeof useDeleteMyInstructorEducationMutation
>;
export type DeleteMyInstructorEducationMutationResult =
  Apollo.MutationResult<DeleteMyInstructorEducationMutation>;
export type DeleteMyInstructorEducationMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteMyInstructorEducationMutation,
    DeleteMyInstructorEducationMutationVariables
  >;
export const UpdateMyInstructorExperiencesDocument = gql`
  mutation UpdateMyInstructorExperiences(
    $experienceInputs: [ExperienceInput]!
  ) {
    updateMyInstructorExperiences(experienceInputs: $experienceInputs) {
      id
      place
      job
      duration
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMyInstructorExperiencesMutationFn = Apollo.MutationFunction<
  UpdateMyInstructorExperiencesMutation,
  UpdateMyInstructorExperiencesMutationVariables
>;

/**
 * __useUpdateMyInstructorExperiencesMutation__
 *
 * To run a mutation, you first call `useUpdateMyInstructorExperiencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyInstructorExperiencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyInstructorExperiencesMutation, { data, loading, error }] = useUpdateMyInstructorExperiencesMutation({
 *   variables: {
 *      experienceInputs: // value for 'experienceInputs'
 *   },
 * });
 */
export function useUpdateMyInstructorExperiencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyInstructorExperiencesMutation,
    UpdateMyInstructorExperiencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyInstructorExperiencesMutation,
    UpdateMyInstructorExperiencesMutationVariables
  >(UpdateMyInstructorExperiencesDocument, options);
}
export type UpdateMyInstructorExperiencesMutationHookResult = ReturnType<
  typeof useUpdateMyInstructorExperiencesMutation
>;
export type UpdateMyInstructorExperiencesMutationResult =
  Apollo.MutationResult<UpdateMyInstructorExperiencesMutation>;
export type UpdateMyInstructorExperiencesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMyInstructorExperiencesMutation,
    UpdateMyInstructorExperiencesMutationVariables
  >;
export const DeleteMyInstructorExperienceDocument = gql`
  mutation DeleteMyInstructorExperience($experienceId: ID!) {
    deleteMyInstructorExperience(experienceId: $experienceId) {
      id
      place
      job
      duration
      createdAt
      updatedAt
    }
  }
`;
export type DeleteMyInstructorExperienceMutationFn = Apollo.MutationFunction<
  DeleteMyInstructorExperienceMutation,
  DeleteMyInstructorExperienceMutationVariables
>;

/**
 * __useDeleteMyInstructorExperienceMutation__
 *
 * To run a mutation, you first call `useDeleteMyInstructorExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyInstructorExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyInstructorExperienceMutation, { data, loading, error }] = useDeleteMyInstructorExperienceMutation({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useDeleteMyInstructorExperienceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMyInstructorExperienceMutation,
    DeleteMyInstructorExperienceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMyInstructorExperienceMutation,
    DeleteMyInstructorExperienceMutationVariables
  >(DeleteMyInstructorExperienceDocument, options);
}
export type DeleteMyInstructorExperienceMutationHookResult = ReturnType<
  typeof useDeleteMyInstructorExperienceMutation
>;
export type DeleteMyInstructorExperienceMutationResult =
  Apollo.MutationResult<DeleteMyInstructorExperienceMutation>;
export type DeleteMyInstructorExperienceMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteMyInstructorExperienceMutation,
    DeleteMyInstructorExperienceMutationVariables
  >;
export const UpdateMyInterpreterDocument = gql`
  mutation UpdateMyInterpreter($interpreterInput: InterpreterInput!) {
    updateMyInterpreter(interpreterInput: $interpreterInput) {
      id
      user {
        id
      }
      introduction
      visaNumber
      passportNumber
      educations {
        id
        school
        major
        createdAt
        updatedAt
      }
      experiences {
        id
        place
        job
        duration
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMyInterpreterMutationFn = Apollo.MutationFunction<
  UpdateMyInterpreterMutation,
  UpdateMyInterpreterMutationVariables
>;

/**
 * __useUpdateMyInterpreterMutation__
 *
 * To run a mutation, you first call `useUpdateMyInterpreterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyInterpreterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyInterpreterMutation, { data, loading, error }] = useUpdateMyInterpreterMutation({
 *   variables: {
 *      interpreterInput: // value for 'interpreterInput'
 *   },
 * });
 */
export function useUpdateMyInterpreterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyInterpreterMutation,
    UpdateMyInterpreterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyInterpreterMutation,
    UpdateMyInterpreterMutationVariables
  >(UpdateMyInterpreterDocument, options);
}
export type UpdateMyInterpreterMutationHookResult = ReturnType<
  typeof useUpdateMyInterpreterMutation
>;
export type UpdateMyInterpreterMutationResult =
  Apollo.MutationResult<UpdateMyInterpreterMutation>;
export type UpdateMyInterpreterMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyInterpreterMutation,
  UpdateMyInterpreterMutationVariables
>;
export const UpdateMyInterpreterEducationsDocument = gql`
  mutation UpdateMyInterpreterEducations($educationInputs: [EducationInput]!) {
    updateMyInterpreterEducations(educationInputs: $educationInputs) {
      id
      school
      major
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMyInterpreterEducationsMutationFn = Apollo.MutationFunction<
  UpdateMyInterpreterEducationsMutation,
  UpdateMyInterpreterEducationsMutationVariables
>;

/**
 * __useUpdateMyInterpreterEducationsMutation__
 *
 * To run a mutation, you first call `useUpdateMyInterpreterEducationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyInterpreterEducationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyInterpreterEducationsMutation, { data, loading, error }] = useUpdateMyInterpreterEducationsMutation({
 *   variables: {
 *      educationInputs: // value for 'educationInputs'
 *   },
 * });
 */
export function useUpdateMyInterpreterEducationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyInterpreterEducationsMutation,
    UpdateMyInterpreterEducationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyInterpreterEducationsMutation,
    UpdateMyInterpreterEducationsMutationVariables
  >(UpdateMyInterpreterEducationsDocument, options);
}
export type UpdateMyInterpreterEducationsMutationHookResult = ReturnType<
  typeof useUpdateMyInterpreterEducationsMutation
>;
export type UpdateMyInterpreterEducationsMutationResult =
  Apollo.MutationResult<UpdateMyInterpreterEducationsMutation>;
export type UpdateMyInterpreterEducationsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMyInterpreterEducationsMutation,
    UpdateMyInterpreterEducationsMutationVariables
  >;
export const DeleteMyInterpreterEducationDocument = gql`
  mutation DeleteMyInterpreterEducation($educationId: ID!) {
    deleteMyInterpreterEducation(educationId: $educationId) {
      id
      school
      major
      createdAt
      updatedAt
    }
  }
`;
export type DeleteMyInterpreterEducationMutationFn = Apollo.MutationFunction<
  DeleteMyInterpreterEducationMutation,
  DeleteMyInterpreterEducationMutationVariables
>;

/**
 * __useDeleteMyInterpreterEducationMutation__
 *
 * To run a mutation, you first call `useDeleteMyInterpreterEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyInterpreterEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyInterpreterEducationMutation, { data, loading, error }] = useDeleteMyInterpreterEducationMutation({
 *   variables: {
 *      educationId: // value for 'educationId'
 *   },
 * });
 */
export function useDeleteMyInterpreterEducationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMyInterpreterEducationMutation,
    DeleteMyInterpreterEducationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMyInterpreterEducationMutation,
    DeleteMyInterpreterEducationMutationVariables
  >(DeleteMyInterpreterEducationDocument, options);
}
export type DeleteMyInterpreterEducationMutationHookResult = ReturnType<
  typeof useDeleteMyInterpreterEducationMutation
>;
export type DeleteMyInterpreterEducationMutationResult =
  Apollo.MutationResult<DeleteMyInterpreterEducationMutation>;
export type DeleteMyInterpreterEducationMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteMyInterpreterEducationMutation,
    DeleteMyInterpreterEducationMutationVariables
  >;
export const UpdateMyInterpreterExperiencesDocument = gql`
  mutation UpdateMyInterpreterExperiences(
    $experienceInputs: [ExperienceInput]!
  ) {
    updateMyInterpreterExperiences(experienceInputs: $experienceInputs) {
      id
      place
      job
      duration
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMyInterpreterExperiencesMutationFn = Apollo.MutationFunction<
  UpdateMyInterpreterExperiencesMutation,
  UpdateMyInterpreterExperiencesMutationVariables
>;

/**
 * __useUpdateMyInterpreterExperiencesMutation__
 *
 * To run a mutation, you first call `useUpdateMyInterpreterExperiencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyInterpreterExperiencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyInterpreterExperiencesMutation, { data, loading, error }] = useUpdateMyInterpreterExperiencesMutation({
 *   variables: {
 *      experienceInputs: // value for 'experienceInputs'
 *   },
 * });
 */
export function useUpdateMyInterpreterExperiencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyInterpreterExperiencesMutation,
    UpdateMyInterpreterExperiencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyInterpreterExperiencesMutation,
    UpdateMyInterpreterExperiencesMutationVariables
  >(UpdateMyInterpreterExperiencesDocument, options);
}
export type UpdateMyInterpreterExperiencesMutationHookResult = ReturnType<
  typeof useUpdateMyInterpreterExperiencesMutation
>;
export type UpdateMyInterpreterExperiencesMutationResult =
  Apollo.MutationResult<UpdateMyInterpreterExperiencesMutation>;
export type UpdateMyInterpreterExperiencesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMyInterpreterExperiencesMutation,
    UpdateMyInterpreterExperiencesMutationVariables
  >;
export const DeleteMyInterpreterExperienceDocument = gql`
  mutation DeleteMyInterpreterExperience($experienceId: ID!) {
    deleteMyInterpreterExperience(experienceId: $experienceId) {
      id
      place
      job
      duration
      createdAt
      updatedAt
    }
  }
`;
export type DeleteMyInterpreterExperienceMutationFn = Apollo.MutationFunction<
  DeleteMyInterpreterExperienceMutation,
  DeleteMyInterpreterExperienceMutationVariables
>;

/**
 * __useDeleteMyInterpreterExperienceMutation__
 *
 * To run a mutation, you first call `useDeleteMyInterpreterExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyInterpreterExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyInterpreterExperienceMutation, { data, loading, error }] = useDeleteMyInterpreterExperienceMutation({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useDeleteMyInterpreterExperienceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMyInterpreterExperienceMutation,
    DeleteMyInterpreterExperienceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMyInterpreterExperienceMutation,
    DeleteMyInterpreterExperienceMutationVariables
  >(DeleteMyInterpreterExperienceDocument, options);
}
export type DeleteMyInterpreterExperienceMutationHookResult = ReturnType<
  typeof useDeleteMyInterpreterExperienceMutation
>;
export type DeleteMyInterpreterExperienceMutationResult =
  Apollo.MutationResult<DeleteMyInterpreterExperienceMutation>;
export type DeleteMyInterpreterExperienceMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteMyInterpreterExperienceMutation,
    DeleteMyInterpreterExperienceMutationVariables
  >;
export const ToggleMyOnlineStatusAsInstructorDocument = gql`
  mutation ToggleMyOnlineStatusAsInstructor($status: Boolean!) {
    toggleMyOnlineStatusAsInstructor(status: $status) {
      id
      online
    }
  }
`;
export type ToggleMyOnlineStatusAsInstructorMutationFn =
  Apollo.MutationFunction<
    ToggleMyOnlineStatusAsInstructorMutation,
    ToggleMyOnlineStatusAsInstructorMutationVariables
  >;

/**
 * __useToggleMyOnlineStatusAsInstructorMutation__
 *
 * To run a mutation, you first call `useToggleMyOnlineStatusAsInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleMyOnlineStatusAsInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleMyOnlineStatusAsInstructorMutation, { data, loading, error }] = useToggleMyOnlineStatusAsInstructorMutation({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleMyOnlineStatusAsInstructorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleMyOnlineStatusAsInstructorMutation,
    ToggleMyOnlineStatusAsInstructorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleMyOnlineStatusAsInstructorMutation,
    ToggleMyOnlineStatusAsInstructorMutationVariables
  >(ToggleMyOnlineStatusAsInstructorDocument, options);
}
export type ToggleMyOnlineStatusAsInstructorMutationHookResult = ReturnType<
  typeof useToggleMyOnlineStatusAsInstructorMutation
>;
export type ToggleMyOnlineStatusAsInstructorMutationResult =
  Apollo.MutationResult<ToggleMyOnlineStatusAsInstructorMutation>;
export type ToggleMyOnlineStatusAsInstructorMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleMyOnlineStatusAsInstructorMutation,
    ToggleMyOnlineStatusAsInstructorMutationVariables
  >;
