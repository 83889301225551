import React, { ComponentPropsWithRef } from "react";
import Flex from "./Flex";
import InputLabel from "./InputLabel";
import Text from "./Text";
import theme from "../../../lib/theme";

interface InputProps extends ComponentPropsWithRef<"input"> {
  label?: string | number;
  errorMessage?: string;
}

function Input({ label, errorMessage, ...rest }: InputProps) {
  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      gap={theme.spacing[8]}
    >
      {!!label && <InputLabel label={label} />}
      <input
        style={{
          width: "100%",
          padding: theme.spacing[12],
          border: `1px solid ${
            !!errorMessage ? theme.color.primary1 : theme.color.neutral500
          }`,
          borderRadius: 6,
          backgroundColor: theme.color.neutralWhite,
          ...theme.typography.body2,
        }}
        {...rest}
      />
      {!!errorMessage && (
        <Text typography="label3" color={theme.color.error}>
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
}

export default Input;
