import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SCREENS } from "../../../lib/route/utils/router";
import {
  EducationInput,
  ExperienceInput,
  UserType,
  useDeleteMyInstructorEducationMutation,
  useDeleteMyInstructorExperienceMutation,
  useDeleteMyInterpreterEducationMutation,
  useDeleteMyInterpreterExperienceMutation,
  useGetMyInstructorLazyQuery,
  useGetMyInterpreterLazyQuery,
  useUpdateMyInstructorEducationsMutation,
  useUpdateMyInstructorExperiencesMutation,
  useUpdateMyInstructorMutation,
  useUpdateMyInterpreterEducationsMutation,
  useUpdateMyInterpreterExperiencesMutation,
  useUpdateMyInterpreterMutation,
} from "../../../lib/apollo/graphql/generated";

export interface InstructorProfileState {
  id: string | null;
  introduction: string;
  job: string;
  qualificationNumber: string;
}

export interface InterpreterProfileState {
  id: string | null;
  introduction: string;
  visaNumber: string;
  passportNumber: string;
}

export interface EducationState {
  id: string | null;
  school: string;
  major: string;
}

export interface ExperienceState {
  id: string | null;
  place: string;
  job: string;
  duration: string;
}

const instructorProfileDefaultState: InstructorProfileState = {
  id: null,
  introduction: "",
  job: "",
  qualificationNumber: "",
};

const interpreterProfileDefaultState: InterpreterProfileState = {
  id: null,
  introduction: "",
  visaNumber: "",
  passportNumber: "",
};

const educationDefaultState: EducationState = {
  id: null,
  school: "",
  major: "",
};

const experienceDefaultState: ExperienceState = {
  id: null,
  place: "",
  job: "",
  duration: "",
};

function useAdditionalProfileForm(isCreating: boolean) {
  const navigate = useNavigate();
  const params = useParams();

  const userType = params?.userType;

  const [instructorProfileState, setInstructorProfileState] = useState(
    instructorProfileDefaultState
  );
  const [interpreterProfileState, setInterpreterState] = useState(
    interpreterProfileDefaultState
  );
  const [educationState, setEducationState] = useState([
    { ...educationDefaultState },
  ]);
  const [experienceState, setExperienceState] = useState([
    { ...experienceDefaultState },
  ]);

  const [updating, setUpdating] = useState(false);

  const [
    getMyInstructor,
    {
      loading: instructorLoading,
      data: instructorData,
      refetch: instructorRefetch,
    },
  ] = useGetMyInstructorLazyQuery({
    onCompleted: (data) => {
      if (data?.getMyInstructor) {
        const {
          id,
          introduction,
          job,
          qualificationNumber,
          educations,
          experiences,
        } = data?.getMyInstructor;

        let educationStates = [{ ...educationDefaultState }];
        let experienceStates = [{ ...experienceDefaultState }];

        if (!!educations && educations?.length > 0) {
          educationStates = [...educations]?.map((education) => ({
            id: education?.id || null,
            school: education?.school || "",
            major: education?.major || "",
          }));
        }

        if (!!experiences && experiences?.length > 0) {
          experienceStates = [...experiences]?.map((experience) => ({
            id: experience?.id || null,
            place: experience?.place || "",
            job: experience?.job || "",
            duration: experience?.duration || "",
          }));
        }

        setInstructorProfileState({
          id: id || null,
          introduction: introduction || "",
          job: job || "",
          qualificationNumber: qualificationNumber || "",
        });

        setEducationState(educationStates);

        setExperienceState(experienceStates);
      }
    },
  });

  const [
    getMyInterpreter,
    {
      loading: interpreterLoading,
      data: interpreterData,
      refetch: interpreterRefetch,
    },
  ] = useGetMyInterpreterLazyQuery({
    onCompleted: (data) => {
      if (data?.getMyInterpreter) {
        const {
          id,
          introduction,
          visaNumber,
          passportNumber,
          educations,
          experiences,
        } = data?.getMyInterpreter;

        let educationStates = [{ ...educationDefaultState }];
        let experienceStates = [{ ...experienceDefaultState }];

        if (!!educations && educations?.length > 0) {
          educationStates = [...educations]?.map((education) => ({
            id: education?.id || null,
            school: education?.school || "",
            major: education?.major || "",
          }));
        }

        if (!!experiences && experiences?.length > 0) {
          experienceStates = [...experiences]?.map((experience) => ({
            id: experience?.id || null,
            place: experience?.place || "",
            job: experience?.job || "",
            duration: experience?.duration || "",
          }));
        }

        setInterpreterState({
          id: id || null,
          introduction: introduction || "",
          visaNumber: visaNumber || "",
          passportNumber: passportNumber || "",
        });

        setEducationState(educationStates);

        setExperienceState(experienceStates);
      }
    },
  });

  const [updateMyInstructor] = useUpdateMyInstructorMutation();
  const [updateMyInstructorEducations] =
    useUpdateMyInstructorEducationsMutation();
  const [updateMyInstructorExperiences] =
    useUpdateMyInstructorExperiencesMutation();

  const [deleteMyInstructorEducation] =
    useDeleteMyInstructorEducationMutation();
  const [deleteMyInstructorExperience] =
    useDeleteMyInstructorExperienceMutation();

  const [updateMyInterpreter] = useUpdateMyInterpreterMutation();
  const [updateMyInterpreterEducations] =
    useUpdateMyInterpreterEducationsMutation();
  const [updateMyInterpreterExperiences] =
    useUpdateMyInterpreterExperiencesMutation();

  const [deleteMyInterpreterEducation] =
    useDeleteMyInterpreterEducationMutation();
  const [deleteMyInterpreterExperience] =
    useDeleteMyInterpreterExperienceMutation();

  useEffect(() => {
    if (userType === UserType.Instructor) {
      getMyInstructor();
    } else {
      getMyInterpreter();
    }
  }, [userType]);

  function onInputChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: "profile" | "education" | "experience",
    key: string,
    index?: number
  ) {
    const { value } = e.target;

    switch (type) {
      case "profile": {
        if (userType === UserType.Instructor) {
          setInstructorProfileState((prev) => ({
            ...prev,
            [key]: value,
          }));
        } else {
          setInterpreterState((prev) => ({
            ...prev,
            [key]: value,
          }));
        }

        break;
      }

      case "education": {
        let editedEducations = [...educationState];
        editedEducations[index!][key as keyof EducationState] = value;

        setEducationState(editedEducations);

        break;
      }

      case "experience": {
        let editedExperiences = [...experienceState];
        editedExperiences[index!][key as keyof ExperienceState] = value;

        setExperienceState(editedExperiences);

        break;
      }

      default: {
        break;
      }
    }
  }

  function onJobSelect(job: string) {
    setInstructorProfileState((prev) => ({
      ...prev,
      job: job,
    }));
  }

  function onAddPress(type: "education" | "experience") {
    switch (type) {
      case "education": {
        setEducationState((prev) => [...prev, { ...educationDefaultState }]);

        break;
      }

      case "experience": {
        setExperienceState((prev) => [...prev, { ...experienceDefaultState }]);

        break;
      }

      default: {
        break;
      }
    }
  }

  function onFormDelete(
    type: "education" | "experience",
    index: number,
    id: string | null
  ) {
    switch (type) {
      case "education": {
        if (!id) {
          let editedEducations = [...educationState];

          editedEducations = [
            ...editedEducations.slice(0, index),
            ...editedEducations.slice(index + 1),
          ];

          setEducationState(editedEducations);
        } else {
          if (userType === UserType.Instructor) {
            deleteMyInstructorEducation({
              variables: {
                educationId: id!,
              },
              onCompleted: (data) => {
                if (data?.deleteMyInstructorEducation) {
                  let editedEducations = [...educationState];

                  editedEducations = [
                    ...editedEducations.slice(0, index),
                    ...editedEducations.slice(index + 1),
                  ];

                  setEducationState(editedEducations);
                }
              },
            });
          } else {
            deleteMyInterpreterEducation({
              variables: {
                educationId: id!,
              },
              onCompleted: (data) => {
                if (data?.deleteMyInterpreterEducation) {
                  let editedEducations = [...educationState];

                  editedEducations = [
                    ...editedEducations.slice(0, index),
                    ...editedEducations.slice(index + 1),
                  ];

                  setEducationState(editedEducations);
                }
              },
            });
          }
        }

        break;
      }

      case "experience": {
        if (!id) {
          let editedExperiences = [...experienceState];

          editedExperiences = [
            ...editedExperiences.slice(0, index),
            ...editedExperiences.slice(index + 1),
          ];

          setExperienceState(editedExperiences);
        } else {
          if (userType === UserType.Instructor) {
            deleteMyInstructorExperience({
              variables: {
                experienceId: id!,
              },
              onCompleted: (data) => {
                if (data?.deleteMyInstructorExperience) {
                  let editedExperiences = [...experienceState];

                  editedExperiences = [
                    ...editedExperiences.slice(0, index),
                    ...editedExperiences.slice(index + 1),
                  ];

                  setExperienceState(editedExperiences);
                }
              },
            });
          } else {
            deleteMyInterpreterExperience({
              variables: {
                experienceId: id!,
              },
              onCompleted: (data) => {
                if (data?.deleteMyInterpreterExperience) {
                  let editedExperiences = [...experienceState];

                  editedExperiences = [
                    ...editedExperiences.slice(0, index),
                    ...editedExperiences.slice(index + 1),
                  ];

                  setExperienceState(editedExperiences);
                }
              },
            });
          }
        }

        break;
      }

      default: {
        break;
      }
    }
  }

  async function onSubmit() {
    setUpdating(true);

    if (userType === UserType.Instructor) {
      const { id, job, qualificationNumber, introduction } =
        instructorProfileState;

      await updateMyInstructor({
        variables: {
          instructorInput: {
            id: id || null,
            job: job || null,
            qualificationNumber: qualificationNumber || null,
            introduction: introduction || null,
          },
        },
      });
    } else {
      const { id, introduction, visaNumber, passportNumber } =
        interpreterProfileState;

      await updateMyInterpreter({
        variables: {
          interpreterInput: {
            id: id || null,
            introduction: introduction || null,
            visaNumber: visaNumber || null,
            passportNumber: passportNumber || null,
          },
        },
      });
    }

    if (educationState?.length > 0) {
      const educationInputs: EducationInput[] = [];

      for (let education of educationState) {
        const { id, school, major } = education;

        if (!!school) {
          educationInputs.push({
            id: id || null,
            school,
            major: major || null,
          });
        }
      }

      if (userType === UserType.Instructor) {
        await updateMyInstructorEducations({
          variables: {
            educationInputs,
          },
        });
      } else {
        await updateMyInterpreterEducations({
          variables: {
            educationInputs,
          },
        });
      }
    }

    if (experienceState?.length > 0) {
      const experienceInputs: ExperienceInput[] = [];

      for (let experience of experienceState) {
        const { id, place, job, duration } = experience;

        if (!!place && !!job && duration) {
          experienceInputs.push({
            id: id || null,
            place,
            job,
            duration,
          });
        }
      }

      if (userType === UserType.Instructor) {
        await updateMyInstructorExperiences({
          variables: {
            experienceInputs,
          },
        });
      } else {
        await updateMyInterpreterExperiences({
          variables: {
            experienceInputs,
          },
        });
      }
    }

    setUpdating(false);

    if (isCreating) {
      navigate(SCREENS.HOME);
      window.location.reload();
    }
  }

  return {
    models: {
      loading: instructorLoading || interpreterLoading,
      instructorProfileState,
      interpreterProfileState,
      educationState,
      experienceState,
      updating,
    },
    operations: {
      onInputChange,
      onJobSelect,
      onAddPress,
      onFormDelete,
      onSubmit,
    },
  };
}

export default useAdditionalProfileForm;
