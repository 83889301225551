import React from "react";
import {
  PaymentUnitPriceType,
  useGetMyPaymentHistoryQuery,
  useGetMySessionsQuery,
} from "../../../lib/apollo/graphql/generated";
import Flex from "../../common/components/Flex";
import HomeSectionTitle from "./HomeSectionTitle";
import { useTranslation } from "react-i18next";
import Loading from "../../common/components/Loading";
import theme from "../../../lib/theme";
import MySessionCard from "../../mySession/components/MySessionCard";
import Text from "../../common/components/Text";
import { useAuthContext } from "../../common/provider/AuthProvider";
import dayjs from "dayjs";
import MyPaymentCard from "../../myPayment/components/MyPamentCard";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "../../../lib/route/utils/router";

function HomePaymentSection() {
  const { profile } = useAuthContext();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { loading, data, fetchMore } = useGetMyPaymentHistoryQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      type: profile?.instructor?.id
        ? PaymentUnitPriceType.Instructor
        : PaymentUnitPriceType.Interpreter,
      first: 1000,
    },
  });

  function getPaymentDataByMonth(data: any) {
    if (!data) return null;

    const obj: any = [...data]?.reduce((prev: any, cur: any) => {
      const { createdAt, amount, currency } = cur;

      const year = dayjs(createdAt).year()?.toString();
      const month = (dayjs(createdAt).month() + 1)?.toString();

      if (prev?.[year]) {
        if (prev?.[year]?.[month]) {
          prev[year][month].amount += amount;
          prev[year][month].currency = currency;
          prev[year][month].total += 1;
        } else {
          prev[year] = {
            ...prev[year],
            [month]: {
              amount,
              currency,
              total: 1,
            },
          };
        }
      } else {
        prev = {
          ...prev,
          [year]: {
            [month]: {
              amount,
              currency,
              total: 1,
            },
          },
        };
      }

      return prev;
    }, {});

    return obj;
  }

  const showingData = getPaymentDataByMonth(data?.getMyPaymentHistory?.edges);

  return (
    <Flex flex={1} flexDirection="column">
      <HomeSectionTitle
        title="정산내역"
        onRightButtonPress={() => navigate(SCREENS.PAYMENT_HISTORY)}
      />
      {loading ? (
        <Loading />
      ) : !!showingData && Object.keys(showingData)?.length > 0 ? (
        Object.keys(showingData)?.map((year) => (
          <Flex
            key={year}
            flexDirection="column"
            width="100%"
            gap={theme.spacing[16]}
          >
            {Object.keys(showingData[year])?.map((month: any) => (
              <MyPaymentCard
                key={month}
                month={month}
                {...showingData[year][month]}
              />
            ))}
          </Flex>
        ))
      ) : (
        <>
          <Text typography="heading4" marginBottom={theme.spacing[24]}>
            {t("myPage.paymentNone")}
          </Text>
        </>
      )}
    </Flex>
  );
}

export default HomePaymentSection;
