import React, { useState } from "react";
import { useUpdateSessionReportAsInstructorMutation } from "../../../lib/apollo/graphql/generated";
import { useParams } from "react-router-dom";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import closeIcon from "../../../assets/icon/modal_close.png";
import IconButton from "../../common/components/IconButton";
import Text from "../../common/components/Text";
import { useTranslation } from "react-i18next";
import Textarea from "../../common/components/Textarea";
import Button from "../../common/components/Button";

interface SessionReportProps {
  report?: string | null;
  isHistory?: boolean;
  onClose?: () => void;
}

function SessionReport({
  report: _report,
  isHistory = false,
  onClose,
}: SessionReportProps) {
  const params = useParams();

  const sessionId = params?.sessionId!;

  const { t } = useTranslation();

  const [report, setReport] = useState(_report || "");

  const [updateSessionReportAsInstructor] =
    useUpdateSessionReportAsInstructorMutation();

  function onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = e.target;

    setReport(value);
  }

  function onUpdate() {
    updateSessionReportAsInstructor({
      variables: {
        sessionId,
        report,
      },
    });
  }

  return (
    <Flex
      backgroundColor={theme.color.neutral200}
      padding={`${theme.spacing[16]}px ${theme.spacing[8]}px`}
      border={`1px solid black`}
      height="100%"
      width="100%"
      flex={1}
      flexDirection="column"
      alignItems="center"
    >
      {!isHistory && (
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="flex-end"
          marginBottom={theme.spacing[16]}
        >
          <IconButton
            fullWidth
            variant="text"
            icon={closeIcon}
            size="lg"
            onClick={onClose}
          />
        </Flex>
      )}
      <Text typography="body1Bold" marginBottom={theme.spacing[8]}>
        {isHistory ? t("session.historyReportTitle") : t("session.reportTitle")}
      </Text>
      <Textarea
        value={report}
        placeholder={t("session.reportPlaceholder")}
        style={{
          height: "40vh",
        }}
        disabled={isHistory}
        onChange={onChange}
      />
      {!isHistory && (
        <Flex
          alignItems="center"
          gap={theme.spacing[4]}
          marginTop={theme.spacing[16]}
        >
          <Button text={t("session.edit")} round={false} onClick={onUpdate} />
          <Button
            text={t("session.confirmEditing")}
            round={false}
            onClick={() => {
              onUpdate();
              onClose && onClose();
            }}
          />
        </Flex>
      )}
    </Flex>
  );
}

export default SessionReport;
