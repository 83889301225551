import React from "react";
import { Trans, useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import useHomeContainer from "./HomeContainer.hook";
import theme from "../../../lib/theme";
import Button from "../../common/components/Button";
import Flex from "../../common/components/Flex";
import Layout from "../../common/components/Layout";
import Section from "../../common/components/Section";
import Text from "../../common/components/Text";
import {
  GetMyInstructorDocument,
  useGetMyInstructorQuery,
  useToggleMyOnlineStatusAsInstructorMutation,
} from "../../../lib/apollo/graphql/generated";
import HomeSessionHistory from "../components/HomeSessionHistory";
import { useAuthContext } from "../../common/provider/AuthProvider";
import HomeSessionList from "../components/HomeSessionList";
import HomePaymentSection from "../components/HomePaymentSection";

function HomeContainer() {
  const { t } = useTranslation();

  const { profile } = useAuthContext();

  const {
    models: { loading, data, online, interpreterData },
    operations: { onStatusChange },
  } = useHomeContainer();

  if (loading) return <div />;

  return (
    <Layout>
      <Section>
        <Text
          typography="heading1"
          color={theme.color.neutral700}
          marginBottom={theme.spacing[32]}
        >
          {t("home.homeTitle")}
        </Text>

        <Flex
          width="100%"
          flexDirection="column"
          padding={theme.spacing[16]}
          borderRadius={20}
          backgroundColor={theme.color.secondary3}
          gap={theme.spacing[24]}
          marginBottom={theme.spacing[32]}
        >
          <Text typography="heading3">
            {profile?.name}
            {t("common.sir")}
            {t("home.homeStatusTitle")}
          </Text>

          {profile?.instructor?.id && (
            <Flex
              alignItems="center"
              width="100%"
              justifyContent="space-between"
            >
              <Text typography="heading4" color={theme.color.primary1}>
                <Trans i18nKey="home.homeStatusDescription" />
                <Trans i18nKey={"common." + (online ? "online" : "offline")} />
              </Text>
              <ReactSwitch
                checked={data?.online || false}
                onChange={onStatusChange}
              />
            </Flex>
          )}
        </Flex>

        {profile?.interpreter?.id && <HomeSessionList />}

        <Flex alignItems="flex-start" width="100%" gap={theme.spacing[20]}>
          <HomeSessionHistory />
          <HomePaymentSection />
        </Flex>
      </Section>
    </Layout>
  );
}

export default HomeContainer;
