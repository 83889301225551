import dayjs from "dayjs";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStopwatch, useTimer } from "react-timer-hook";
import theme from "../../../lib/theme";
import { getDigitValue } from "../helpers/getDigitValue";
import { useGetManagementQuery } from "../../../lib/apollo/graphql/generated";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";

interface SessionTimerProps {
  started: boolean;
  extensions?: Array<{
    __typename?: "SessionExtension";
    id: string;
    createdAt: any;
    session: { __typename?: "Session"; id: string };
    userPass?: { __typename?: "UserPass"; id: string } | null;
    userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
  } | null> | null;
  startedAt?: string;
}

const LIMIT = 5;

function SessionTimer({ started, extensions, startedAt }: SessionTimerProps) {
  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  const { data, loading } = useGetManagementQuery();

  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  const target = useMemo(
    () =>
      dayjs(startedAt ? startedAt : dayjs().toDate())
        .add(
          (data?.getManagement?.sessionDuration || LIMIT) *
            (extensions?.length || 1) +
            1,
          "minutes"
        )
        .toDate(),
    [startedAt]
  );

  const {
    seconds: _seconds,
    minutes: _minutes,

    isRunning: _isRunning,

    pause: _pause,
  } = useTimer({ expiryTimestamp: target, autoStart: true });

  function getRunningTime(start: string) {
    const startTime = dayjs(start);

    const passed = dayjs().diff(dayjs(startTime), "milliseconds");
    const minutes = dayjs().diff(dayjs(startTime), "minutes");
    const seconds = dayjs().diff(dayjs(startTime), "seconds") % 60;

    setMin(minutes);
    setSec(seconds);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      getRunningTime(startedAt || dayjs().format("YYYY-MM-DD HH:mm:ss"));
    });

    return () => {
      clearInterval(interval);
    };
  }, [startedAt]);

  return (
    <div
      style={{
        padding: `${theme.spacing[4]}px ${theme.spacing[8]}px`,
        backgroundColor: theme.color.secondary3,
        borderRadius: 12,
        border: `1px solid ${theme.color.primary1}`,
        ...(isMobile ? theme.typography.body3 : theme.typography.body2),
      }}
    >
      {t("session.sessionLeft")} {getDigitValue(min)}:{getDigitValue(sec)} /{" "}
      {getDigitValue(_minutes)}:{getDigitValue(_seconds)}
    </div>
  );
}

export default memo(SessionTimer);
