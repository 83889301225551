import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactModal from "react-modal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./index.css";
import client from "./lib/apollo";
import "./lib/i18n";
import ScreenWidthProvider from "./modules/common/provider/ScreenWidthProvider";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ScreenWidthProvider>
      <ApolloProvider client={client}>
        <App />
        <ToastContainer
          hideProgressBar
          autoClose={2000}
          position="top-center"
        />
      </ApolloProvider>
    </ScreenWidthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
ReactModal.setAppElement("#root");
