import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "./Button";
import Flex from "./Flex";
import Loading from "./Loading";
import Modal from "./Modal";
import Text from "./Text";
import { SCREENS } from "../../../lib/route/utils/router";
import theme from "../../../lib/theme";
import {
  InvitationStatus,
  useGetInvitationQuery,
  useRespondToInvitationMutation,
} from "../../../lib/apollo/graphql/generated";

interface InvitationModalProps {
  invitationId: string;
  onRequestClose: () => void;
}

function InvitationModal({
  invitationId,
  onRequestClose,
}: InvitationModalProps) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { loading, data } = useGetInvitationQuery({
    variables: {
      invitationId,
    },
  });

  const [respondToInvitation] = useRespondToInvitationMutation({
    onCompleted: (data) => {
      onRequestClose();

      if (
        data?.respondToInvitation?.status === InvitationStatus.Accepted &&
        data?.respondToInvitation?.session
      ) {
        navigate(
          SCREENS.SESSION + "/" + data?.respondToInvitation?.session?.id
        );
      }
    },
  });

  function onClose() {
    if (!data?.getInvitation) return;

    respondToInvitation({
      variables: {
        invitationId,
        status: InvitationStatus.Declined,
      },
    });

    onRequestClose();
  }

  function onClick(status: InvitationStatus) {
    respondToInvitation({
      variables: {
        invitationId,
        status,
      },
    });

    toast(
      t(
        status === InvitationStatus.Accepted
          ? "invitation.acceptedInvitation"
          : "invitation.rejectedInvitation"
      )
    );
  }

  return (
    <Modal isOpen={!!invitationId} onRequestClose={onClose}>
      {loading ? (
        <Loading />
      ) : (
        <Flex
          flexDirection="column"
          alignItems="center"
          width="100%"
          gap={theme.spacing[32]}
        >
          <Text
            typography="heading3"
            color={theme.color.neutral700}
            textAlign="center"
          >
            {data?.getInvitation?.sender?.name}
            {t("common.sir")}
            {t("invitation.invitationModalTitle")}
          </Text>
          <Flex
            flexDirection="column"
            alignItems="center"
            width="100%"
            gap={theme.spacing[16]}
          >
            <Button
              text={t("invitation.acceptInvitation")}
              fullWidth
              round={false}
              onClick={() => onClick(InvitationStatus.Accepted)}
            />
            <Button
              text={t("invitation.rejectInvitation")}
              fullWidth
              round={false}
              variant="outline"
              fontColor="neutral900"
              bgColor="neutralWhite"
              onClick={() => onClick(InvitationStatus.Declined)}
            />
          </Flex>
        </Flex>
      )}
    </Modal>
  );
}

export default InvitationModal;
