import React from "react";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import { SessionStatus } from "../../../lib/apollo/graphql/generated";
import { useTranslation } from "react-i18next";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";
import { useNavigate } from "react-router-dom";
import Avatar from "../../common/components/Avatar";
import Text from "../../common/components/Text";
import dayjs from "dayjs";
import Button from "../../common/components/Button";
import { SCREENS } from "../../../lib/route/utils/router";

interface MySessionCardProps {
  session: {
    __typename?: "Session";
    id: string;
    jwt?: string | null;
    password?: string | null;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    status?: SessionStatus | null;
    needInterpreter?: boolean | null;
    createdAt: any;
    updatedAt: any;
    requester: {
      __typename?: "User";
      id: string;
      name?: string | null;
      usuableTickets: number;
      avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    };
    instructor?: {
      __typename?: "Instructor";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    userPass?: {
      __typename?: "UserPass";
      id: string;
      pass: { __typename?: "Pass"; id: string; name: string };
    } | null;
    userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
    users?: Array<{
      __typename?: "SessionUser";
      id: string;
      joinAt: any;
      leaveAt?: any | null;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        instructor?: {
          __typename?: "Instructor";
          id: string;
          job?: string | null;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
        interpreter?: {
          __typename?: "Interpreter";
          id: string;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
      };
    } | null> | null;
  };
  isHome?: boolean;
}

function MySessionCard({ session, isHome = false }: MySessionCardProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const {
    instructor,
    requester,
    userPass,
    userSubscription,
    startAt,
    endAt,
    createdAt,
  } = session;

  if (!startAt) {
    return null;
  }

  return (
    <Flex
      flexDirection={!isMobile ? "row" : "column"}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      padding={theme.spacing[8]}
      gap={theme.spacing[4]}
    >
      <Flex
        flex={1}
        alignItems="center"
        gap={theme.spacing[isMobile ? 8 : 24]}
        flexDirection={isMobile ? "column" : "row"}
        marginBottom={isMobile ? theme.spacing[12] : 0}
      >
        <Avatar
          size={64}
          uri={requester?.avatar?.uri}
          initial={requester?.name?.[0]}
          showOnline={false}
        />
        <Flex
          flexDirection="column"
          flex={1}
          gap={theme.spacing[isMobile ? 8 : 12]}
        >
          <Text typography="body1" color={theme.color.neutral800}>
            {requester?.name} /{" "}
            {userPass
              ? userPass?.pass?.name
              : userSubscription
              ? t("mySession.subscriber")
              : ""}
          </Text>
          {!!startAt && !!endAt && (
            <Text typography="body2" color={theme.color.neutral700}>
              {dayjs(endAt).diff(dayjs(startAt), "minutes")}
              {t("common.minutes")} {t("common.session")}
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex alignItems="center" gap={theme.spacing[12]}>
        <Text
          typography="label2"
          color={theme.color.neutral600}
          padding={`0px ${theme.spacing[16]}px`}
          borderRadius={12}
          border={`1px solid ${theme.color.neutral600}`}
        >
          {dayjs(createdAt).format("YYYY.MM.DD")}
        </Text>
        {!isHome && (
          <Button
            text={t("mySession.viewSession")}
            size="lg"
            onClick={() => navigate(SCREENS.SESSION_HISTORY + "/" + session.id)}
          />
        )}
      </Flex>
    </Flex>
  );
}

export default MySessionCard;
