import React from "react";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";
import Flex from "./Flex";
import InputLabel from "./InputLabel";
import { useGetNationalitiesQuery } from "../../../lib/apollo/graphql/generated";
import theme from "../../../lib/theme";

interface NationalityInputProps {
  label?: string | number;
  value?: SingleValue<{ label: string; value: string }>;
  onChange?: (
    newValue: SingleValue<{
      label: string;
      value: string;
    }>
  ) => void;
}

function NationalityInput({ label, value, onChange }: NationalityInputProps) {
  const { t } = useTranslation();

  const { loading, data } = useGetNationalitiesQuery();

  const defaultOptions = data?.getNationalities?.map((nationality) => ({
    label: (nationality?.emoji || "") + " " + nationality?.name,
    value: nationality?.id!,
  }));

  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      gap={theme.spacing[8]}
    >
      {!!label && <InputLabel label={label} />}
      {!loading && !!defaultOptions && defaultOptions?.length > 0 && (
        <Select
          styles={{
            container: (styles: any) => ({
              ...styles,
              width: "100%",
              ...theme.typography.body2,
            }),
            control: (styles: any) => ({
              ...styles,
              borderColor: theme.color.neutral500,
              borderRadius: 6,
            }),
            valueContainer: (styles: any) => ({
              ...styles,
              padding: theme.spacing[12],
            }),
            input: (styles: any) => ({
              ...styles,
              margin: 0,
              padding: 0,
            }),
          }}
          options={defaultOptions}
          value={value}
          onChange={onChange}
          isSearchable
          isClearable
          placeholder={t("common.nationalityPlaceholder")}
        />
      )}
    </Flex>
  );
}

export default NationalityInput;
