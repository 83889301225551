import { from, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { createClient } from "graphql-ws";
import { getFirebaseIdToken } from "../../firebase";

const uploadLink = createUploadLink({
  uri: "https://" + `${process.env.REACT_APP_API_URL}/graphql`,
  // uri: "http://api-dev.iptime.org:8000/graphql",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: "wss://" + process.env.REACT_APP_API_URL + "/graphql",
    connectionParams: async () => ({
      authorization: await getFirebaseIdToken(),
      role: "INSTRUCTOR",
    }),
  })
);

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  uploadLink
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  } else if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, operation) => !!error,
  },
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const token = await getFirebaseIdToken();
    console.log(token);
    return {
      headers: { ...headers, authorization: token, role: "INSTRUCTOR" },
    };
  } catch (err) {
    console.error(err);
  }
});

export default from([retryLink, errorLink, authLink, link]);
