import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { firebaseSignInWithCustomToken } from "../../../../lib/firebase";
import { SCREENS } from "../../../../lib/route/utils/router";

const KAKAO_AUTH_URL = `https://${process.env.REACT_APP_API_URL}/kakao_instructor`;

const CLIENT_ID = `${process.env.REACT_APP_KAKAO_REST_API_KEY}`;

function KakaoCallbackContainer() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");

  const [accessToken, setAccessToken] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!code) {
      navigate(-1);
    }

    if (accessToken) return;

    (async () => {
      if (code) {
        try {
          const grantType = "authorization_code";
          const REDIRECT_URI = window.location.origin + SCREENS.KAKAO_CALLBACK;

          const url = `https://kauth.kakao.com/oauth/token?grant_type=${grantType}&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&code=${code}`;

          const tokenData = await axios.post(
            url,
            {},
            {
              headers: {
                "Content-type":
                  "application/x-www-form-urlencoded;charset=utf-8",
              },
            }
          );

          if (tokenData?.data?.access_token) {
            setAccessToken(tokenData?.data?.access_token);
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [code, accessToken]);

  useEffect(() => {
    if (!!accessToken) {
      (async () => {
        const config = {
          method: "post",
          url: KAKAO_AUTH_URL,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: { token: accessToken },
        };

        const res = await axios(config);

        const { firebaseCustomToken } = res?.data;

        if (!firebaseCustomToken) return;

        await firebaseSignInWithCustomToken(firebaseCustomToken);

        navigate(-1);
      })();
    }
  }, [accessToken]);

  return <div />;
}

export default KakaoCallbackContainer;
