import { useApolloClient } from "@apollo/client";
import React, { CSSProperties, useMemo } from "react";
import { slide as Menu } from "react-burger-menu";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "./Button";
import Flex from "./Flex";
import IconButton from "./IconButton";
import Label from "./Label";
import useNavigationBar from "./NavigationBar.hook";
import avatarIcon from "../../../assets/icon/avatar_icon.png";
import menuIcon from "../../../assets/icon/menu_fill.png";
import modalCloseIcon from "../../../assets/icon/modal_close.png";
import logo from "../../../assets/image/logo.png";
import { firebaseSignOut } from "../../../lib/firebase";
import { SCREENS } from "../../../lib/route/utils/router";
import theme from "../../../lib/theme";
import { useAuthContext } from "../provider/AuthProvider";
import { useScreenWidthContext } from "../provider/ScreenWidthProvider";
import { useGetManagementQuery } from "../../../lib/apollo/graphql/generated";

function NavigationBar() {
  const { isMobile } = useScreenWidthContext();

  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const { profile } = useAuthContext();

  const {
    models: { showProfileMenu, showMobileMenu },
    operations: { toggleProfileMenu, toggleMobileMenu },
  } = useNavigationBar();

  const { loading, data } = useGetManagementQuery();

  const menu = [
    {
      text: t("navigation.history"),
      current: location?.pathname?.includes(SCREENS.SESSION_HISTORY),

      onClick: () => {
        if (!profile) {
          navigate(SCREENS.SIGN_IN);
        } else {
          navigate(SCREENS.SESSION_HISTORY);
        }
      },
    },
    {
      text: t("navigation.payroll"),
      current: location?.pathname?.includes(SCREENS.PAYMENT_HISTORY),
      onClick: () => {
        if (!profile) {
          navigate(SCREENS.SIGN_IN);
        } else {
          navigate(SCREENS.PAYMENT_HISTORY);
        }
      },
    },
    {
      text: t("navigation.signUpOrSignIn"),
      onClick: () => {
        navigate(SCREENS.SIGN_IN);
      },
    },
  ];

  const profileMenu = [
    {
      text: t("navigation.manageUserProfile"),
      onClick: () => {
        navigate(SCREENS.MY_PAGE + SCREENS.MY_USER_PROFILE);
      },
    },
    {
      text: t("navigation.manageProfile"),
      onClick: () => {
        navigate(SCREENS.MY_PAGE + SCREENS.MY_PROFILE + "/" + profile?.type);
      },
    },
    {
      text: t("navigation.manageBankAccount"),
      onClick: () => {
        navigate(SCREENS.MY_PAGE + SCREENS.BANK_ACCOUNT);
      },
    },
    {
      text: t("navigation.contact"),
      onClick: () => {
        if (!data?.getManagement?.kakaoAddress) return;

        window.open(data?.getManagement?.kakaoAddress, "_blank");
      },
    },
    {
      text: t("navigation.signOut"),
      onClick: async () => {
        navigate(SCREENS.SIGN_IN);
        await firebaseSignOut();
        await client.clearStore();
        window.location.reload();
      },
    },
  ];

  return (
    <>
      <Flex
        width="100%"
        alignItems="center"
        justifyContent="center"
        position="sticky"
        top={0}
        zIndex={100}
        backgroundColor={theme.color.neutralWhite}
        boxShadow={
          isMobile ? "none" : "0px -1px 1px 0px rgba(0, 0, 0, 0.10) inset"
        }
        borderBottom={isMobile ? `1px solid ${theme.color.neutral800}` : "none"}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          maxWidth={theme.appMaxWidth}
          padding={
            isMobile
              ? `${theme.spacing[8]}px ${theme.spacing[16]}px`
              : `0px ${theme.spacing[20]}px`
          }
        >
          <Flex alignItems="center" gap={theme.spacing[12]}>
            <div
              style={{
                cursor: "pointer",
                padding: isMobile
                  ? `${theme.spacing[12]}px ${theme.spacing[12]}px`
                  : theme.spacing[12],
              }}
              onClick={() => {
                navigate(SCREENS.HOME);
              }}
            >
              <img
                src={logo}
                style={{
                  width: isMobile ? 132 : 156,
                  objectFit: "contain",
                }}
              />
            </div>
            {!isMobile &&
              menu.slice(0, 2).map((item) => (
                <Label
                  key={item?.text}
                  text={item?.text}
                  // current={item?.current}
                  size="lg"
                  onClick={item?.onClick}
                />
              ))}
          </Flex>
          <Flex
            alignItems="center"
            gap={isMobile ? theme.spacing[16] : theme.spacing[20]}
          >
            {!!profile && (
              <div
                style={{ cursor: "pointer", width: 40, height: 40 }}
                onClick={toggleProfileMenu}
              >
                <img
                  src={avatarIcon}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            )}
            {!isMobile ? (
              !profile ? (
                menu
                  .slice(2)
                  .map((item) => (
                    <Button
                      key={item?.text}
                      text={item?.text}
                      variant="outline"
                      size="lg"
                      fontColor="neutral700"
                      bgColor="neutralWhite"
                      onClick={item?.onClick}
                    />
                  ))
              ) : null
            ) : (
              <>
                {!profile && (
                  <Button
                    text={menu?.[3]?.text}
                    variant="outline"
                    size="sm"
                    fontColor="neutral700"
                    bgColor="neutralWhite"
                    onClick={menu?.[3]?.onClick}
                  />
                )}
                <div style={{ cursor: "pointer" }} onClick={toggleMobileMenu}>
                  <img src={menuIcon} style={{ width: 22, height: 28 }} />
                </div>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>

      {showProfileMenu && (
        <Menu
          outerContainerId="root"
          right
          isOpen={showProfileMenu}
          styles={{
            bmOverlay: {
              top: "0px",
            },
            bmMenuWrap: {
              top: "0px",
            },
          }}
          width={280}
          onClose={toggleProfileMenu}
        >
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            backgroundColor="white"
          >
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              padding={theme.spacing[16]}
            >
              <IconButton
                fullWidth
                variant="text"
                icon={modalCloseIcon}
                size="lg"
                onClick={toggleProfileMenu}
              />
            </Flex>
            {profileMenu?.map((menu) => (
              <div
                key={menu?.text}
                style={{
                  cursor: "pointer",
                  padding: `${theme.spacing[12]}px ${theme.spacing[16]}px`,
                  ...theme.typography.body1,
                }}
                onClick={menu?.onClick}
              >
                {menu?.text}
              </div>
            ))}
          </Flex>
        </Menu>
      )}

      {showMobileMenu && (
        <Menu
          outerContainerId="root"
          right
          isOpen={showMobileMenu}
          styles={{
            bmOverlay: {
              top: "0px",
            },
            bmMenuWrap: {
              top: "0px",
            },
          }}
          width={280}
          onClose={toggleMobileMenu}
        >
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            backgroundColor="white"
          >
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              padding={theme.spacing[16]}
            >
              <IconButton
                fullWidth
                variant="text"
                icon={modalCloseIcon}
                size="lg"
                onClick={toggleMobileMenu}
              />
            </Flex>
            {menu?.slice(0, 3)?.map((menu) => (
              <div
                key={menu?.text}
                style={{
                  cursor: "pointer",
                  padding: `${theme.spacing[12]}px ${theme.spacing[16]}px`,
                  ...theme.typography.body1,
                }}
                onClick={menu?.onClick}
              >
                {menu?.text}
              </div>
            ))}
          </Flex>
        </Menu>
      )}
    </>
  );
}

export default NavigationBar;
