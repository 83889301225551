import React from "react";
import { useGetMySessionsQuery } from "../../../lib/apollo/graphql/generated";
import Flex from "../../common/components/Flex";
import HomeSectionTitle from "./HomeSectionTitle";
import { useTranslation } from "react-i18next";
import Loading from "../../common/components/Loading";
import theme from "../../../lib/theme";
import MySessionCard from "../../mySession/components/MySessionCard";
import Text from "../../common/components/Text";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "../../../lib/route/utils/router";

function HomeSessionHistory() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { loading, data, fetchMore } = useGetMySessionsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      first: 4,
    },
  });

  return (
    <Flex flex={1} flexDirection="column">
      <HomeSectionTitle
        title={t("home.sessionHistory")}
        onRightButtonPress={() => navigate(SCREENS.SESSION_HISTORY)}
      />
      {loading ? (
        <Loading />
      ) : !!data?.getMySessions?.edges &&
        data?.getMySessions?.edges?.length > 0 ? (
        <Flex
          width="100%"
          gap={theme.spacing[16]}
          flexDirection="column"
          boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.08)"
        >
          {data?.getMySessions?.edges?.slice(0, 5)?.map((session) => (
            <MySessionCard key={session?.id} session={session!} isHome={true} />
          ))}
        </Flex>
      ) : (
        <>
          <Text typography="heading4" marginBottom={theme.spacing[24]}>
            {t("myPage.sessionNone")}
          </Text>
        </>
      )}
    </Flex>
  );
}

export default HomeSessionHistory;
