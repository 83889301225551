import React from "react";
import Layout from "../../common/components/Layout";
import Section from "../../common/components/Section";
import MyPageHeader from "../../myPage/common/components/MyPageHeader";
import { useTranslation } from "react-i18next";
import Divider from "../../common/components/Divider";
import Spacer from "../../common/components/Spacer";
import Loading from "../../common/components/Loading";
import useMySessionContainer from "./MySessionContainer.hook";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import MySessionCard from "../components/MySessionCard";
import FetchMore from "../../common/components/FetchMore";
import Text from "../../common/components/Text";

function MySessionContainer() {
  const { t } = useTranslation();

  const {
    refs: { ref },
    models: { loading, data },
  } = useMySessionContainer();

  return (
    <Layout>
      <Section>
        <MyPageHeader title={t("myPage.sessionTitle")} />
        <Divider full size={1} />
        <Spacer size={24} />

        {loading ? (
          <Loading />
        ) : !!data && data?.length > 0 ? (
          <Flex
            width="100%"
            gap={theme.spacing[16]}
            flexDirection="column"
            boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.08)"
          >
            {data?.map((session) => (
              <MySessionCard key={session?.id} session={session!} />
            ))}
            <FetchMore fetchMoreRef={ref} />
          </Flex>
        ) : (
          <>
            <Text typography="heading4" marginBottom={theme.spacing[24]}>
              {t("myPage.sessionNone")}
            </Text>
          </>
        )}
      </Section>
    </Layout>
  );
}

export default MySessionContainer;
