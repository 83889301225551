import React, { useEffect } from "react";
import { useGetActiveSessionsQuery } from "../../../lib/apollo/graphql/generated";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import Text from "../../common/components/Text";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../common/provider/AuthProvider";
import Grid from "../../common/components/Grid";
import HomeSessionThumbnail from "./HomeSessionThumbnail";

function HomeSessionList() {
  const { profile } = useAuthContext();

  const { t } = useTranslation();

  const { loading, data, startPolling, stopPolling } =
    useGetActiveSessionsQuery({
      variables: {
        first: 1000,
      },
      pollInterval: 5000,
    });

  useEffect(() => {
    startPolling(5000);

    return () => {
      stopPolling();
    };
  }, []);

  function getSessionsByMyLanguage(data: any) {
    if (!data) return [];

    const sessions: any = [];

    const myLanguages =
      profile?.languageOptions?.map((language) => language?.name) || [];

    data?.forEach((session: any) => {
      const sessionLanguages = new Set();

      session?.requester?.languageOptions?.forEach((language: any) => {
        if (!sessionLanguages.has(language?.name)) {
          sessionLanguages.add(language?.name);
        }
      });

      session?.instructor?.languageOptions?.forEach((language: any) => {
        if (!sessionLanguages.has(language?.name)) {
          sessionLanguages.add(language?.name);
        }
      });

      let hasMyLanguage = false;

      myLanguages?.forEach((language: any) => {
        if (sessionLanguages.has(language) && !hasMyLanguage) {
          hasMyLanguage = true;
        }
      });

      if (hasMyLanguage && session?.needInterpreter) {
        sessions.push(session);
      }
    });

    return sessions;
  }

  const showingData = getSessionsByMyLanguage(data?.getActiveSessions?.edges);

  return (
    <Flex
      width="100%"
      flexDirection="column"
      alignItems="center"
      padding={`${theme.spacing[24]}px ${theme.spacing[10]}px`}
      backgroundColor={theme.color.secondary3}
      gap={theme.spacing[20]}
      marginBottom={theme.spacing[32]}
    >
      <Text typography="heading3">{t("home.availableSessionsTitle")}</Text>
      <Text typography="body1">{t("home.availablesessionsDescription")}</Text>
      <Grid
        width="100%"
        gridTemplateColumns="repeat(3, 1fr)"
        gridAutoRows="auto"
      >
        {!!showingData &&
          showingData?.length > 0 &&
          showingData?.map((session: any) => (
            <HomeSessionThumbnail key={session?.id} session={session} />
          ))}
      </Grid>
    </Flex>
  );
}

export default HomeSessionList;
