import { ReactChildrenProp } from "global";
import React, { CSSProperties } from "react";
import Flex from "./Flex";
import theme from "../../../lib/theme";
import { useScreenWidthContext } from "../provider/ScreenWidthProvider";

interface SectionProps extends ReactChildrenProp {
  wrapperStyle?: CSSProperties;
  innerStyle?: CSSProperties;
}

function Section({
  children,
  wrapperStyle = {},
  innerStyle = {},
}: SectionProps) {
  const { isMobile } = useScreenWidthContext();

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width="100%"
      {...wrapperStyle}
    >
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="flex-start"
        maxWidth={theme.appMaxWidth}
        padding={
          isMobile
            ? `${theme.spacing[24]}px ${theme.spacing[16]}px`
            : `${theme.spacing[24]}px ${theme.spacing[10]}px`
        }
        {...innerStyle}
      >
        {children}
      </Flex>
    </Flex>
  );
}

export default Section;
