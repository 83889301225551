import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { EducationState } from "./AdditionalProfileForm.hook";
import Flex from "./Flex";
import IconButton from "./IconButton";
import Input from "./Input";
import closeIcon from "../../../assets/icon/close.png";
import theme from "../../../lib/theme";
import { useScreenWidthContext } from "../provider/ScreenWidthProvider";

interface EducationFormProps {
  education: EducationState;
  showBorderBottom?: boolean;
  deletable?: boolean;
  onInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) => void;
  onDeleteClick?: () => void;
}

function EducationForm({
  education,
  showBorderBottom = true,
  deletable = true,
  onInputChange,
  onDeleteClick,
}: EducationFormProps) {
  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  const { major, school } = education;

  return (
    <>
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="flex-start"
        padding={theme.spacing[8]}
        gap={theme.spacing[24]}
        marginBottom={theme.spacing[24]}
        backgroundColor={deletable ? theme.color.tertiary3 : "white"}
      >
        {!!deletable && (
          <Flex alignItems="center" justifyContent="flex-end" width="100%">
            <IconButton
              disabled={!deletable}
              icon={closeIcon}
              variant="outline"
              size={isMobile ? "sm" : "md"}
              onClick={onDeleteClick}
            />
          </Flex>
        )}
        <Input
          label={t("common.educationSchool")}
          placeholder={t("common.educationSchoolPlaceholder")}
          value={school}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "school")
          }
        />
        <Input
          label={t("common.educationMajor")}
          placeholder={t("common.educationMajorPlaceholder")}
          value={major}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "major")
          }
        />
      </Flex>
      {showBorderBottom && (
        <div
          style={{
            width: "100%",
            height: 1,
            backgroundColor: theme.color.neutral800,
            marginBottom: theme.spacing[24],
          }}
        />
      )}
    </>
  );
}

export default memo(EducationForm);
