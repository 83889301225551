import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "../../common/components/Avatar";
import Button from "../../common/components/Button";
import Flex from "../../common/components/Flex";
import InvitationModal from "../../common/components/InvitationModal";
import Modal from "../../common/components/Modal";
import Text from "../../common/components/Text";
import theme from "../../../lib/theme";
import { useScreenWidthContext } from "../../common/provider/ScreenWidthProvider";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "../../../lib/route/utils/router";
import { useAuthContext } from "../../common/provider/AuthProvider";

interface HomeSessionThumbnailProps {
  session: any;
}

function HomeSessionThumbnail({ session }: HomeSessionThumbnailProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { profile } = useAuthContext();

  const { isMobile } = useScreenWidthContext();

  const [showModal, setShowModal] = useState(false);

  const { id, needInterpreter, requester, instructor, jwt, status } = session;

  const { user, experiences, job } = instructor;

  const lastWorkAt = experiences?.[0]?.place;

  const myLanguages =
    profile?.languageOptions?.map((language) => language?.name) || [];

  const sessionLanguages = new Set();

  const languageOptions = session?.requester?.languageOptions?.forEach(
    (language: any) => {
      if (!sessionLanguages.has(language?.name)) {
        sessionLanguages.add(language?.name);
      }
    }
  );

  session?.instructor?.languageOptions?.forEach((language: any) => {
    if (!sessionLanguages.has(language?.name)) {
      sessionLanguages.add(language?.name);
    }
  });

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="center"
        borderRadius={8}
        paddingTop={theme.spacing[24]}
        paddingBottom={theme.spacing[24]}
        gap={theme.spacing[24]}
        backgroundColor={theme.color.neutralWhite}
        border={`1px solid ${theme.color.neutral800}`}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          gap={theme.spacing[4]}
          padding={theme.spacing[16]}
        >
          <Avatar
            uri={user?.avatar?.uri}
            initial={user?.name?.slice(0, 1)}
            size={120}
            showOnline={false}
          />
          <Text typography="heading3" color={theme.color.neutral700}>
            {user?.name}
          </Text>
          {(job || lastWorkAt) && (
            <Text typography="body1Bold" color={theme.color.neutral300}>
              {job ? t("common." + job) : ""}
              {job ? ", " : ""}
              {lastWorkAt || ""}
            </Text>
          )}
          <Flex alignItems="center" gap={theme.spacing[4]}>
            {Array.from(sessionLanguages)?.map((language: any) => (
              <Text
                key={language}
                color={
                  myLanguages.includes(language)
                    ? theme.color.error
                    : theme.color.neutral900
                }
              >
                {language}
              </Text>
            ))}
          </Flex>
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="center"
          gap={theme.spacing[20]}
        >
          <Button
            variant="outline"
            round={false}
            text={t("home.joinSession")}
            borderColor="neutral900"
            bgColor="tertiary1"
            size={isMobile ? "sm" : "lg"}
            fontColor="neutral900"
            onClick={() => navigate(SCREENS.SESSION + "/" + id)}
          />
        </Flex>
      </Flex>

      {/* {showModal && (
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onRequestClose={() => setShowModal(false)}
        >
          <InvitationModal
            instructorId={id}
            onClose={() => setShowModal(false)}
          />
        </Modal>
      )} */}
    </>
  );
}

export default HomeSessionThumbnail;
