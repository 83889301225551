import React, { useEffect, useMemo } from "react";
import {
  SessionStatus,
  useGetMyActiveSessionQuery,
} from "../../../lib/apollo/graphql/generated";
import { ReactChildrenProp } from "global";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "./AuthProvider";
import { SCREENS } from "../../../lib/route/utils/router";

function SessionProvider({ children }: ReactChildrenProp) {
  const navigate = useNavigate();
  const location = useLocation();

  const { profile } = useAuthContext();

  const { data, loading, error, subscribeToMore } =
    useGetMyActiveSessionQuery();

  useEffect(() => {
    if (data?.getMyActiveSession) {
      const { requester, status } = data?.getMyActiveSession;

      if (
        status === SessionStatus.Started &&
        location?.pathname !== SCREENS.SESSION
      ) {
        navigate(SCREENS.SESSION + "/" + data?.getMyActiveSession?.id);
      }
    }
  }, [data?.getMyActiveSession, location?.pathname]);

  const memoizedChildren = useMemo(() => children, []);

  return <>{memoizedChildren}</>;
}

export default SessionProvider;
