import React, { useEffect } from "react";
import Flex from "../../common/components/Flex";
import Text from "../../common/components/Text";
import { useAuthContext } from "../../common/provider/AuthProvider";
import theme from "../../../lib/theme";
import dayjs from "dayjs";
import Avatar from "../../common/components/Avatar";
import {
  SessionStatus,
  useToggleSessionStatusAsInstructorMutation,
} from "../../../lib/apollo/graphql/generated";
import { useZoomContext } from "../provider/ZoomProvider";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "../../../lib/route/utils/router";

interface SessionChatMessageProps {
  message: any;
}

function SessionChatMessage({ message }: SessionChatMessageProps) {
  const { profile } = useAuthContext();
  const navigate = useNavigate();

  const zoomClient = useZoomContext();

  const { user, body, createdAt } = message;

  const isMyMessage = user?.id === profile?.id;

  const isExtension = !!body && body === "1MIN_BEFORE_SESSION_END";

  const isFinish = !!body && body === "SESSION_FINISHED";

  const [toggleSessionStatus] = useToggleSessionStatusAsInstructorMutation({
    onCompleted: async (data) => {
      if (
        data?.toggleSessionStatusAsInstructor?.status === SessionStatus.Done
      ) {
        await zoomClient.leave();

        navigate(SCREENS.HOME);

        window.location.reload();
      }
    },
  });

  useEffect(() => {
    if (isFinish) {
      toggleSessionStatus({
        variables: {
          sessionId: message?.session?.id,
          status: SessionStatus.Done,
        },
      });
    }
  }, [isFinish]);

  return isExtension ? (
    <div />
  ) : (
    <Flex
      width="100%"
      alignItems="center"
      justifyContent={isMyMessage ? "flex-end" : "flex-start"}
      gap={theme.spacing[8]}
    >
      {!isMyMessage && (
        <Avatar
          uri={user?.avatar?.uri}
          initial={user?.name?.slice(0, 1)}
          size={32}
        />
      )}
      {isMyMessage && (
        <Text typography="label3" color={theme.color.neutral500}>
          {dayjs(createdAt).format("h:mm a")}
        </Text>
      )}
      <Text
        typography="label2"
        borderTopLeftRadius={isMyMessage ? 0 : 20}
        borderTopRightRadius={isMyMessage ? 20 : 0}
        backgroundColor={
          !isMyMessage ? theme.color.neutral300 : theme.color.primary3
        }
        padding={`${theme.spacing[8]}px ${theme.spacing[24]}px`}
      >
        {body}
      </Text>
      {!isMyMessage && (
        <Text typography="label3" color={theme.color.neutral500}>
          {dayjs(createdAt).format("h:mm a")}
        </Text>
      )}
    </Flex>
  );
}

export default SessionChatMessage;
