import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Flex from "./Flex";
import Section from "./Section";
import Text from "./Text";
import facebookBlack from "../../../assets/icon/facebook_black.png";
import facebook from "../../../assets/icon/facebook.png";
import kakao from "../../../assets/icon/icon_kakao.png";
import linkedInBlack from "../../../assets/icon/linked_in_black.png";
import linkedIn from "../../../assets/icon/linked_in.png";
import logoWhite from "../../../assets/image/logo_white.png";
import { SCREENS } from "../../../lib/route/utils/router";
import theme from "../../../lib/theme";
import { useScreenWidthContext } from "../provider/ScreenWidthProvider";
import { useGetManagementQuery } from "../../../lib/apollo/graphql/generated";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useScreenWidthContext();

  const { data, loading } = useGetManagementQuery();

  return (
    <Section
      wrapperStyle={{
        backgroundColor: theme.color.primary1,
        paddingTop: isMobile ? theme.spacing[16] : theme.spacing[24],
        paddingBottom: isMobile ? theme.spacing[48] : theme.spacing[40] * 4,
      }}
      innerStyle={{
        color: theme.color.neutralWhite,
      }}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <div style={{ marginBottom: isMobile ? theme.spacing[24] : 0 }}>
          <img
            style={{ width: isMobile ? 112 : 216, objectFit: "contain" }}
            src={logoWhite}
          />
        </div>

        <Flex
          alignItems="center"
          gap={theme.spacing[24]}
          marginBottom={theme.spacing[24]}
        >
          {data?.getManagement?.kakaoAddress && (
            <Flex
              alignItems="center"
              justifyContent="center"
              width={35}
              height={35}
              borderRadius={35 / 2}
              backgroundColor="transparent"
              cursor="pointer"
              onClick={() =>
                window.open(data?.getManagement?.kakaoAddress!, "_blank")
              }
            >
              <img src={kakao} style={{ width: 40, height: 40 }} />
            </Flex>
          )}
        </Flex>

        <Flex
          width="100%"
          flexDirection="column"
          alignItems="center"
          gap={theme.spacing[12]}
          justifyContent="center"
          marginBottom={theme.spacing[24]}
        >
          <Text typography="label3" color={theme.color.neutralWhite}>
            {data?.getManagement?.companyName || ""}
            {data?.getManagement?.address
              ? " | " + data?.getManagement?.address
              : ""}
          </Text>
          {data?.getManagement?.ceoName && (
            <Text typography="label3" color={theme.color.neutralWhite}>
              {t("footer.ceoName")}
              {data?.getManagement?.ceoName}
            </Text>
          )}
          {data?.getManagement?.registrationNumber && (
            <Text typography="label3" color={theme.color.neutralWhite}>
              {t("footer.businessRegistration")}
              {data?.getManagement?.registrationNumber}
            </Text>
          )}
          {data?.getManagement?.escroRegistration && (
            <Text typography="label3" color={theme.color.neutralWhite}>
              {t("footer.escroRegistration")}
              {data?.getManagement?.escroRegistration}
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex
        width="100%"
        justifyContent="center"
        alignItems="center"
        gap={theme.spacing[24]}
        paddingTop={isMobile ? theme.spacing[12] : 0}
        paddingBottom={isMobile ? theme.spacing[12] : 0}
        marginBottom={isMobile ? theme.spacing[16] : 0}
      >
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            window?.open(
              "https://www.notion.so/2023-12-20-9e8e008f43814a1e892108fd8edb8fc0?pvs=4",
              "_blank"
            );
          }}
        >
          <Text typography="label2" color={theme.color.fourth4}>
            Terms
          </Text>
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            window?.open(
              "https://www.notion.so/VMS-2023-12-20-32bec2a2aa314863b22dbe63db483224?pvs=4",
              "_blank"
            );
          }}
        >
          <Text typography="label2" color={theme.color.fourth4}>
            Privacy
          </Text>
        </div>
      </Flex>
    </Section>
  );
}

export default Footer;
