import { useInView } from "react-intersection-observer";
import { useGetMySessionsQuery } from "../../../lib/apollo/graphql/generated";
import { useState } from "react";

function useMySessionContainer() {
  const { ref } = useInView({
    threshold: 0.2,
    onChange: onEndReached,
  });

  const [fetchingMore, setFetchingMore] = useState(false);

  const { loading, data, fetchMore } = useGetMySessionsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      first: 12,
    },
  });

  function onEndReached(inView: boolean) {
    if (inView && !fetchingMore && data?.getMySessions?.pageInfo?.hasNextPage) {
      setFetchingMore(true);

      fetchMore({
        variables: {
          first: 12,
          after: data?.getMySessions?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  return {
    refs: {
      ref,
    },
    models: {
      loading,
      data: data?.getMySessions?.edges,
    },
  };
}

export default useMySessionContainer;
