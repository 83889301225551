import { InMemoryCache } from "@apollo/client";
import customOffsetLimitPagination from "./customOffsetLimitPagination";
import customRelayStylePagination from "./customRelayStylePagination";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getSessionMessages: customRelayStylePagination(["sessionId"]),

        getMySessions: customRelayStylePagination(),
      },
    },
  },
});

export const setDefaultCache = (cache: any) => {
  // console.info("set default cache:", cache);
};

setDefaultCache(cache);

export default cache;
