import React, { ComponentPropsWithRef } from "react";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";
import Flex from "./Flex";
import InputLabel from "./InputLabel";
import Text from "./Text";
import theme from "../../../lib/theme";
import { PHONE_CODE } from "../../../utils/constants";

interface PhoneInputProps extends ComponentPropsWithRef<"input"> {
  label?: string | number;
  errorMessage?: string;
  phoneCode?: SingleValue<{ label: string; value: string }>;
  onPhoneCodeChange?: (
    newValue: SingleValue<{
      label: string;
      value: string;
    }>
  ) => void;
}

function PhoneInput({
  label,
  errorMessage,
  phoneCode,
  onPhoneCodeChange,
  ...rest
}: PhoneInputProps) {
  const { t } = useTranslation();

  const phoneCodeOptions = PHONE_CODE?.map((code) => ({
    label: code.country + `(${code.code})`,
    value: code.code,
  }));

  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      gap={theme.spacing[8]}
    >
      {!!label && <InputLabel label={label} />}
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="flex-start"
        gap={theme.spacing[8]}
      >
        <Select
          styles={{
            container: (styles: any) => ({
              ...styles,
              width: "80%",
              ...theme.typography.body2,
            }),
            control: (styles: any) => ({
              ...styles,
              borderColor: theme.color.neutral500,
              borderRadius: 6,
            }),
            valueContainer: (styles: any) => ({
              ...styles,
              padding: theme.spacing[12],
            }),
            input: (styles: any) => ({
              ...styles,
              margin: 0,
              padding: 0,
            }),
          }}
          options={phoneCodeOptions}
          value={phoneCode}
          onChange={onPhoneCodeChange}
          isSearchable
          isClearable
          placeholder={t("common.phoneCodePlaceholder")}
        />
        <input
          style={{
            width: "100%",
            padding: theme.spacing[12],
            border: `1px solid ${
              !!errorMessage ? theme.color.primary1 : theme.color.neutral500
            }`,
            borderRadius: 6,
            backgroundColor: theme.color.neutralWhite,
            ...theme.typography.body2,
          }}
          {...rest}
        />
      </Flex>
      {!!errorMessage && (
        <Text typography="label3" color={theme.color.error}>
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
}

export default PhoneInput;
