import React, { useState } from "react";
import Layout from "../../common/components/Layout";
import Section from "../../common/components/Section";
import MyPageHeader from "../../myPage/common/components/MyPageHeader";
import { useTranslation } from "react-i18next";
import Divider from "../../common/components/Divider";
import Spacer from "../../common/components/Spacer";
import { useInView } from "react-intersection-observer";
import {
  PaymentUnitPriceType,
  useGetMyPaymentHistoryQuery,
} from "../../../lib/apollo/graphql/generated";
import dayjs from "dayjs";
import Loading from "../../common/components/Loading";
import Text from "../../common/components/Text";
import theme from "../../../lib/theme";
import { useAuthContext } from "../../common/provider/AuthProvider";
import Flex from "../../common/components/Flex";
import MyPaymentCard from "../components/MyPamentCard";

function MyPaymentContainer() {
  const { t } = useTranslation();

  const { profile } = useAuthContext();

  const [fetchingMore, setFetchingMore] = useState(false);

  const { ref } = useInView({
    threshold: 0.2,
    onChange: onEndReached,
  });

  const { loading, data, fetchMore } = useGetMyPaymentHistoryQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      type: profile?.instructor?.id
        ? PaymentUnitPriceType.Instructor
        : PaymentUnitPriceType.Interpreter,
      first: 1000,
    },
  });

  function onEndReached(inView: boolean) {
    if (
      inView &&
      !fetchingMore &&
      data?.getMyPaymentHistory?.pageInfo?.hasNextPage
    ) {
      setFetchingMore(true);

      fetchMore({
        variables: {
          first: 12,
          after: data?.getMyPaymentHistory?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  function getPaymentDataByMonth(data: any) {
    if (!data) return null;

    const obj: any = [...data]?.reduce((prev: any, cur: any) => {
      const { createdAt, amount, currency } = cur;

      const year = dayjs(createdAt).year()?.toString();
      const month = (dayjs(createdAt).month() + 1)?.toString();

      if (prev?.[year]) {
        if (prev?.[year]?.[month]) {
          prev[year][month].amount += amount;
          prev[year][month].currency = currency;
          prev[year][month].total += 1;
        } else {
          prev[year] = {
            ...prev[year],
            [month]: {
              amount,
              currency,
              total: 1,
            },
          };
        }
      } else {
        prev = {
          ...prev,
          [year]: {
            [month]: {
              amount,
              currency,
              total: 1,
            },
          },
        };
      }

      return prev;
    }, {});

    return obj;
  }

  const showingData = getPaymentDataByMonth(data?.getMyPaymentHistory?.edges);

  return (
    <Layout>
      <Section>
        <MyPageHeader title={t("myPage.paymentHistoryTitle")} />

        <Divider full size={1} />
        <Spacer size={24} />

        {loading ? (
          <Loading />
        ) : !!showingData && Object.keys(showingData)?.length > 0 ? (
          Object.keys(showingData)?.map((year) => (
            <Flex
              key={year}
              flexDirection="column"
              width="100%"
              gap={theme.spacing[16]}
            >
              {Object.keys(showingData[year])?.map((month: any) => (
                <MyPaymentCard
                  key={month}
                  month={month}
                  {...showingData[year][month]}
                />
              ))}
            </Flex>
          ))
        ) : (
          <>
            <Text typography="heading4" marginBottom={theme.spacing[24]}>
              {t("myPage.sessionNone")}
            </Text>
          </>
        )}
      </Section>
    </Layout>
  );
}

export default MyPaymentContainer;
