import React, { useEffect, useRef } from "react";
import { createBrowserRouter, Outlet, useNavigate } from "react-router-dom";
import SignInContainer from "../../../modules/auth/signIn/container/SignInContainer";
import SignUpIntroContainer from "../../../modules/auth/signUp/signUpIntro/container/SignUpIntroContainer";
import SignUpStep1Container from "../../../modules/auth/signUp/signUpStep1/container/SignUpStep1Container";
import SignUpStep2Container from "../../../modules/auth/signUp/signUpStep2/container/SignUpStep2Container";
import SignUpStep3Container from "../../../modules/auth/signUp/signUpStep3/container/SignUpStep3Container";
import AuthProvider from "../../../modules/common/provider/AuthProvider";
import HomeContainer from "../../../modules/home/container/HomeContainer";
import SessionContainer from "../../../modules/session/container/SessionContainer";
import MyUserProfileContainer from "../../../modules/myPage/myUserProfile/container/MyUserProfileContainer";
import MyProfileContainer from "../../../modules/myPage/myProfile/container/MyProfileContainer";
import MySessionContainer from "../../../modules/mySession/container/MySessionContainer";
import SessionHistoryContainer from "../../../modules/sessionHistory/container/SessionHistoryContainer";
import BankAccountContainer from "../../../modules/myPage/bankAccount/container/BankAccountContainer";
import MyPaymentContainer from "../../../modules/myPayment/container/MyPaymentContainer";
import KakaoCallbackContainer from "../../../modules/auth/kakao/container/KakaoCallbackContainer";

export const SCREENS = {
  HOME: "/",
  SIGN_IN: "/sign_in",
  SIGN_UP: "/sign_up",

  MY_PAGE: "/my_page",
  MY_USER_PROFILE: "/user",
  MY_PROFILE: "/profile",
  BANK_ACCOUNT: "/bank_account",

  SESSION: "/session",

  SESSION_HISTORY: "/session_history",

  PAYMENT_HISTORY: "/payment_history",

  POLICY: "/policy",
  TERMS: "/terms",

  KAKAO_CALLBACK: "/kakao_callback",
};

export const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    children: [
      {
        path: SCREENS.HOME,
        element: <HomeContainer />,
      },

      {
        path: SCREENS.SIGN_IN,
        element: <SignInContainer />,
      },
      {
        path: SCREENS.SIGN_UP,
        element: <Outlet />,
        children: [
          {
            path: SCREENS.SIGN_UP + "/intro",
            element: <SignUpIntroContainer />,
          },
          {
            path: SCREENS.SIGN_UP + "/step1",
            element: <SignUpStep1Container />,
          },
          {
            path: SCREENS.SIGN_UP + "/step2",
            element: <SignUpStep2Container />,
          },
          {
            path: SCREENS.SIGN_UP + "/:userType" + "/step3",
            element: <SignUpStep3Container />,
          },
        ],
      },

      {
        path: SCREENS.MY_PAGE,
        element: <Outlet />,
        children: [
          {
            path: SCREENS.MY_PAGE + SCREENS.MY_USER_PROFILE,
            element: <MyUserProfileContainer />,
          },
          {
            path: SCREENS.MY_PAGE + SCREENS.MY_PROFILE + "/:userType",
            element: <MyProfileContainer />,
          },
          {
            path: SCREENS.MY_PAGE + SCREENS.BANK_ACCOUNT,
            element: <BankAccountContainer />,
          },
        ],
      },

      {
        path: SCREENS.SESSION + "/:sessionId",
        element: <SessionContainer />,
      },

      {
        path: SCREENS.SESSION_HISTORY,
        element: <MySessionContainer />,
      },

      {
        path: SCREENS.SESSION_HISTORY + "/:sessionId",
        element: <SessionHistoryContainer />,
      },

      {
        path: SCREENS.PAYMENT_HISTORY,
        element: <MyPaymentContainer />,
      },

      {
        path: SCREENS.KAKAO_CALLBACK,
        element: <KakaoCallbackContainer />,
      },

      {
        path: SCREENS.TERMS,
        element: <Policy isTerm={true} />,
      },

      {
        path: SCREENS.POLICY,
        element: <Policy />,
      },
    ],
  },
]);

interface PolicyProps {
  isTerm?: boolean;
}

function Policy({ isTerm = false }: PolicyProps) {
  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref?.current) {
      ref?.current?.click();
    }
  }, [ref?.current]);

  function onClick() {
    if (isTerm) {
      window.open(
        "https://www.notion.so/2023-12-20-9e8e008f43814a1e892108fd8edb8fc0?pvs=4",
        "_blank"
      );
    } else {
      window.open(
        "https://www.notion.so/VMS-2023-12-20-32bec2a2aa314863b22dbe63db483224?pvs=4",
        "_blank"
      );
    }

    navigate(-1);
  }

  return <div ref={ref} onClick={onClick} />;
}
