import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Accordion from "./Accordion";
import useAdditionalProfileForm from "./AdditionalProfileForm.hook";
import Button from "./Button";
import EducationForm from "./EducationForm";
import ExperienceForm from "./ExperienceForm";
import Flex from "./Flex";
import Input from "./Input";
import InputLabel from "./InputLabel";
import Loading from "./Loading";
import Textarea from "./Textarea";
import plusIcon from "../../../assets/icon/plus_blue.png";
import { UserType } from "../../../lib/apollo/graphql/generated";
import theme from "../../../lib/theme";
import { INSTRUCTOR_JOB } from "../../../utils/constants";
import { useScreenWidthContext } from "../provider/ScreenWidthProvider";

interface ExperienceProfileFormProps {
  isCreating?: boolean;
}

function ExperienceProfileForm({
  isCreating = false,
}: ExperienceProfileFormProps) {
  const navigate = useNavigate();
  const params = useParams();

  const userType = params?.userType;

  const { t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  const {
    models: {
      loading,
      instructorProfileState,
      interpreterProfileState,
      educationState,
      experienceState,
      updating,
    },
    operations: {
      onInputChange,
      onJobSelect,
      onAddPress,
      onFormDelete,
      onSubmit,
    },
  } = useAdditionalProfileForm(isCreating);

  const {
    job,
    qualificationNumber,
    introduction: _introduction,
  } = instructorProfileState;
  const { introduction, visaNumber, passportNumber } = interpreterProfileState;

  const memoizedJobSelector = useMemo(
    () => (
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="flex-start"
        gap={theme.spacing[8]}
      >
        <InputLabel label={t("common.selectInstructorJob")} />
        <Flex alignItems="center" gap={theme.spacing[12]}>
          <Button
            round={false}
            text={t("common.pharmacy")}
            size="sm"
            variant={job === INSTRUCTOR_JOB.PHARMACY ? "contained" : "outline"}
            fontColor={
              job === INSTRUCTOR_JOB.PHARMACY ? "neutralWhite" : "neutral700"
            }
            borderColor={
              job === INSTRUCTOR_JOB.PHARMACY ? "neutralWhite" : "neutral700"
            }
            bgColor={
              job === INSTRUCTOR_JOB.PHARMACY ? "primary1" : "neutralWhite"
            }
            onClick={() => onJobSelect(INSTRUCTOR_JOB.PHARMACY)}
          />
          <Button
            round={false}
            text={t("common.nurse")}
            size="sm"
            variant={job === INSTRUCTOR_JOB.NURSE ? "contained" : "outline"}
            fontColor={
              job === INSTRUCTOR_JOB.NURSE ? "neutralWhite" : "neutral700"
            }
            borderColor={
              job === INSTRUCTOR_JOB.NURSE ? "neutralWhite" : "neutral700"
            }
            bgColor={job === INSTRUCTOR_JOB.NURSE ? "primary1" : "neutralWhite"}
            onClick={() => onJobSelect(INSTRUCTOR_JOB.NURSE)}
          />
          <Button
            round={false}
            text={t("common.doctor")}
            size="sm"
            variant={job === INSTRUCTOR_JOB.DOCTOR ? "contained" : "outline"}
            fontColor={
              job === INSTRUCTOR_JOB.DOCTOR ? "neutralWhite" : "neutral700"
            }
            borderColor={
              job === INSTRUCTOR_JOB.DOCTOR ? "neutralWhite" : "neutral700"
            }
            bgColor={
              job === INSTRUCTOR_JOB.DOCTOR ? "primary1" : "neutralWhite"
            }
            onClick={() => onJobSelect(INSTRUCTOR_JOB.DOCTOR)}
          />
        </Flex>
      </Flex>
    ),
    [job]
  );

  const memoizedQualificationNumberInput = useMemo(
    () => (
      <Input
        label={t("common.qualificationNumber")}
        placeholder={t("common.qualificationNumberPlaceholder")}
        value={qualificationNumber}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "profile", "qualificationNumber")
        }
      />
    ),
    [qualificationNumber]
  );

  const memoizedInstuctorIntroductionInput = useMemo(
    () => (
      <Textarea
        label={t("common.introductionLabel")}
        summary={t("common.introductionSubLabel")}
        value={_introduction}
        maxLength={100}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          onInputChange(e, "profile", "introduction")
        }
      />
    ),
    [_introduction]
  );

  const memoizedInterpreterIntroductionInput = useMemo(
    () => (
      <Textarea
        label={t("common.introductionLabel")}
        summary={t("common.introductionSubLabel")}
        value={introduction}
        maxLength={100}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          onInputChange(e, "profile", "introduction")
        }
      />
    ),
    [introduction]
  );

  const memoizedVisaNumberInput = useMemo(
    () => (
      <Input
        label={t("common.visaNumber")}
        placeholder={t("common.visaNumberPlaceholder")}
        value={visaNumber}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "profile", "visaNumber")
        }
      />
    ),
    [visaNumber]
  );

  const memoizedPassportNumberInput = useMemo(
    () => (
      <Input
        label={t("common.passportNumber")}
        placeholder={t("common.passportNumberPlaceholder")}
        value={passportNumber}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "profile", "passportNumber")
        }
      />
    ),
    [passportNumber]
  );

  const isEducationAddable =
    !!educationState?.[educationState?.length - 1]?.school;

  const isExperienceAddable =
    !!experienceState?.[experienceState?.length - 1]?.place &&
    !!experienceState?.[experienceState?.length - 1]?.job &&
    !!experienceState?.[experienceState?.length - 1]?.duration;

  if (loading) return <Loading />;

  return (
    <>
      {/* PERSONAL  */}
      <Accordion title={t("common.profile")} defaultState={true}>
        <Flex width="100%" alignItems="center" flexDirection="column">
          <Flex
            flexDirection="column"
            width="100%"
            alignItems="flex-start"
            padding={theme.spacing[8]}
            gap={theme.spacing[24]}
            marginBottom={theme.spacing[24]}
            backgroundColor={"white"}
          >
            {userType === UserType.Instructor ? (
              <>
                {memoizedJobSelector}
                {memoizedQualificationNumberInput}
                {memoizedInstuctorIntroductionInput}
              </>
            ) : (
              <>
                {memoizedInterpreterIntroductionInput}
                {memoizedVisaNumberInput}
                {memoizedPassportNumberInput}
              </>
            )}
          </Flex>
        </Flex>
      </Accordion>

      {/* EXPERIENCE  */}
      <Accordion title={t("common.experienceTitle")} defaultState={true}>
        <Flex width="100%" alignItems="center" flexDirection="column">
          {experienceState?.map((experience, index) => {
            const { id, place, job, duration } = experience;

            const showBorderBottom =
              experienceState?.length > 0 &&
              index !== experienceState?.length - 1;

            const deletable =
              showBorderBottom && !!place && !!job && !!duration;

            return (
              <ExperienceForm
                key={"experience" + index}
                experience={experience}
                deletable={deletable}
                showBorderBottom={showBorderBottom}
                onInputChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                  key: string
                ) => onInputChange(e, "experience", key, index)}
                onDeleteClick={() => onFormDelete("experience", index, id)}
              />
            );
          })}
          <Button
            text={t("common.addExperience")}
            variant="outline"
            borderColor="primary1"
            size="lg"
            fontColor="primary1"
            bgColor="neutralWhite"
            leftIcon={plusIcon}
            onClick={() => {
              if (isExperienceAddable) {
                onAddPress("experience");
              }
            }}
          />
        </Flex>
      </Accordion>

      {/* EDUCATION  */}
      <Accordion title={t("common.educationTitle")} defaultState={true}>
        <Flex width="100%" alignItems="center" flexDirection="column">
          {educationState?.map((education, index) => {
            const { id, school } = education;

            const showBorderBottom =
              educationState?.length > 0 &&
              index !== educationState?.length - 1;

            const deletable = showBorderBottom && !!school;

            return (
              <EducationForm
                key={"education" + index}
                education={education}
                deletable={deletable}
                showBorderBottom={showBorderBottom}
                onInputChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                  key: string
                ) => onInputChange(e, "education", key, index)}
                onDeleteClick={() => onFormDelete("education", index, id)}
              />
            );
          })}
          <Button
            text={t("common.addEducation")}
            variant="outline"
            borderColor="primary1"
            size="lg"
            fontColor="primary1"
            bgColor="neutralWhite"
            leftIcon={plusIcon}
            onClick={() => {
              if (isEducationAddable) {
                onAddPress("education");
              }
            }}
          />
        </Flex>
      </Accordion>

      <Flex
        alignItems="center"
        justifyContent="center"
        width="100%"
        marginTop={isMobile ? 32 : isCreating ? 48 : 24}
        gap={theme.spacing[24]}
      >
        {isCreating && (
          <Button
            variant="outline"
            text="이전"
            fontColor="neutralWhite"
            size={isMobile ? "md" : "lg"}
            onClick={() => navigate(-1)}
          />
        )}
        <Button
          disabled={updating}
          text={
            updating
              ? t("common.saving")
              : isCreating
              ? t("common.next")
              : t("common.save")
          }
          size={isMobile ? "md" : "lg"}
          onClick={onSubmit}
        />
      </Flex>
    </>
  );
}

export default ExperienceProfileForm;
