import { useNavigate } from "react-router-dom";
import { SCREENS } from "../../../lib/route/utils/router";
import {
  useGetMyInstructorLazyQuery,
  useGetMyInstructorQuery,
  useGetMyInterpreterLazyQuery,
  useToggleMyOnlineStatusAsInstructorMutation,
} from "../../../lib/apollo/graphql/generated";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../common/provider/AuthProvider";

function useHomeContainer() {
  const navigate = useNavigate();

  const { profile } = useAuthContext();

  const [online, setOnline] = useState(false);

  const [toggleMyOnlineStatusAsInstructor] =
    useToggleMyOnlineStatusAsInstructorMutation({
      onCompleted: (data) => {
        if (data?.toggleMyOnlineStatusAsInstructor) {
          setOnline(data?.toggleMyOnlineStatusAsInstructor?.online);
        }
      },
    });

  const [getMyInstructor, { loading, data }] = useGetMyInstructorLazyQuery({
    onError: (err) => {
      console.log(err);
      navigate(SCREENS.SIGN_IN, { replace: true });
    },
  });

  const [
    getMyInterpreter,
    { loading: interpreterLoading, data: interpreterData },
  ] = useGetMyInterpreterLazyQuery({
    onError: (err) => {
      console.log(err);
      navigate(SCREENS.SIGN_IN, { replace: true });
    },
  });

  useEffect(() => {
    if (profile?.instructor?.id) {
      getMyInstructor();
      toggleMyOnlineStatusAsInstructor({
        variables: {
          status: online,
        },
      });
    } else if (profile?.interpreter?.id) {
      getMyInterpreter();
    } else {
      navigate(SCREENS.SIGN_IN, { replace: true });
    }

    return () => {
      if (profile?.instructor?.id && online) {
        toggleMyOnlineStatusAsInstructor({
          variables: {
            status: false,
          },
        });
      }
    };
  }, [profile?.instructor, profile?.interpreter, online]);

  function onStatusChange(checked: boolean) {
    if (!data?.getMyInstructor) return;

    toggleMyOnlineStatusAsInstructor({
      variables: {
        status: checked,
      },
    });
  }

  return {
    models: {
      loading,
      data: data?.getMyInstructor,
      interpreterData: interpreterData?.getMyInterpreter,
      online,
    },
    operations: {
      onStatusChange,
    },
  };
}

export default useHomeContainer;
