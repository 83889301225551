import React from "react";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import Text from "../../common/components/Text";

interface MyPaymentCardProps {
  month: string;
  amount: number;
  total: number;
}

function MyPaymentCard({ month, amount, total }: MyPaymentCardProps) {
  return (
    <Flex
      flexDirection="column"
      width="100%"
      borderRadius={6}
      padding={theme.spacing[16]}
      gap={theme.spacing[4]}
      border={`1px solid black`}
    >
      <Text typography="heading4">{month}월</Text>
      <Text typography="body1" color={theme.color.neutral900}>
        월 상담 총 {total}건
      </Text>
      <Text typography="body2" color={theme.color.neutral700}>
        {amount}원
      </Text>
    </Flex>
  );
}

export default MyPaymentCard;
