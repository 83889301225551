import React from "react";
import Text from "./Text";
import theme from "../../../lib/theme";

interface InputLabelProps {
  label?: string | number;
}

function InputLabel({ label }: InputLabelProps) {
  return (
    <Text typography="body1Bold" color={theme.color.neutral800}>
      {label}
    </Text>
  );
}

export default InputLabel;
